import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import type { RootState } from 'Types/state.types';
import { Action } from './ActionPanel';
import { Form } from 'Common/forms';
import Button from 'Common/Button/Button';
import SesamModal from 'Common/SesamModal/SesamModal';

import './PopupAction.css';

type PopupActionProps = PropsWithChildren<{
  name: string;
  enabled: boolean;
  onClosed: () => void;
  onActionClick: () => void;
  useListItem: boolean;
  darkModeActive: boolean;
}>;

/*
 * Action that pops up as a modal dialog and can have children.
 */
export function PopupAction({
  name,
  enabled,
  onClosed,
  onActionClick,
  useListItem,
  children,
  darkModeActive,
}: PopupActionProps) {
  const [showingLastUpdate, setShowingLastUpdate] = useState(false);

  function onOpen() {
    setShowingLastUpdate(true);
  }

  function onClose() {
    setShowingLastUpdate(false);
    if (onClosed) {
      onClosed();
    }
  }

  function onOperation(event: Event) {
    event.preventDefault();
    onClose();
    onActionClick();
  }

  return (
    <>
      <Action
        name={`${name}…`}
        onActionClick={onOpen}
        enabled={enabled}
        useListItem={useListItem}
      />
      <SesamModal
        isOpen={showingLastUpdate}
        onRequestClose={onClose}
        contentLabel={name}
        className="simple-dialog"
        darkModeActive={darkModeActive}
      >
        <div className="popupaction">
          <h3>{name}</h3>
          <Form onSubmit={onOperation}>
            <div className="inputs">{children}</div>
            <Button type="submit" theme="primary">
              {name}
            </Button>
          </Form>
        </div>
      </SesamModal>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  darkModeActive: state.theme.dark,
});

export default connect(mapStateToProps)(PopupAction);
