import { makeStyles } from '@material-ui/core/styles';

export const useChangelogStyle = makeStyles((theme) => ({
  changeTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  changeDate: { color: theme.palette.grey[700] },
  changesIcon: {
    width: '32px',
    height: '32px',
  },
  divider: {
    marginBottom: '10px',
  },
  lvl: {
    display: 'flex',
    marginTop: '15px',
    alignItems: 'center',
  },
  lvlCircle: {
    width: '10px',
    height: '10px',
    borderRadius: 50,
    backgroundColor: theme.palette.grey[300],
    marginRight: '3px',
  },
  lvlCircleFilled: {
    backgroundColor: theme.palette.primary.main,
  },
  lvlLabel: {
    color: theme.palette.grey[700],
    marginLeft: '7px',
  },
  okButton: { marginLeft: '15px' },
  tags: {
    marginBottom: '10px',
  },
  tag: {
    marginRight: '5px',
  },
  changeContent: {},
}));
