import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from 'Common/SesamTypography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import PopulatedIcon from '@material-ui/icons/SystemUpdateAlt';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import get from 'lodash/get';
import moment from 'moment';
import { useSelector } from 'react-redux';
import type { RootState } from 'Types/state.types';
import PipeIcon from '../../images/icons/pipe.svg';
import SystemIcon from '../../images/icons/system.svg';
import {
  getDownstreamLookups,
  getDownstreamPipes,
  getPipeQueueSize,
  getSinkDatasetId,
  getUpstreamLookups,
  getUpstreamPipes,
} from 'Internals/pipes';
import { withSeparators } from 'Internals/utils';
import { subIdSelector } from 'Redux/selectors';
import type { PipeMap, Pipe, UpstreamMap, PipeConfig } from 'Types/pipes.types';
import type { System, SystemConfig } from 'Types/system.types';
import { DownstreamMap, LookupMap } from 'Types/common.types';
import Box from 'Common/Box/Box';
import SesamLink from 'Common/Links/SesamLink';

interface GraphEdgeInfoPanelProps {
  edge: string;
  main: Pipe | System;
  nodeMode?: boolean;
  populated?: boolean;
  allDatasets: Array<{}>;
  hidden: boolean;
  setHidden: () => void;
}

const COMPLETENESS_THRESHOLD = 48;

const useStyle = makeStyles((theme) => {
  return {
    container: {
      height: 'auto',
      position: 'absolute',
      right: 10,
      top: 10,
    },
    infoItem: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '9px',
    },
    infoItemIcon: {
      marginRight: '10px',
    },
    infoItemIconInfo: {
      color: theme.palette.info.main,
    },
    infoItemIconWarning: {
      color: theme.palette.secondary.main,
    },
    infoList: {
      padding: '7px',
    },
    node: {
      alignItems: 'center',
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
    },
    nodeIcon: {
      '& svg': {
        width: '24px',
        height: '24px',
      },
      marginRight: '10px',
    },
    closeIcon: {
      color: theme.palette.text.primary,
      boxShadow: `0px 0px 1px 0px ${theme.palette.text.primary}`,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0px 0px 3px 0px ${theme.palette.text.primary}`,
      },
    },
  };
});

/**
 * Find the type of a config
 */
function getConfigType(config: PipeConfig | SystemConfig) {
  const typeFromConfig = get(config, 'type');
  try {
    if (typeFromConfig === 'pipe') {
      return 'pipe';
    } else if (typeFromConfig.split(':')[0] === 'system') {
      return 'system';
    }
  } catch (_err) {
    return 'unknown';
  }
}

function getConfigIcon(config: PipeConfig | SystemConfig) {
  const type = getConfigType(config);
  if (type === 'pipe') {
    return <PipeIcon />;
  } else {
    return <SystemIcon />;
  }
}

/**
 * Find the relationship between a pipe and a pipe, or a system and a pipe.
 */
function getRelationship(
  main: Pipe | System,
  connected: Pipe | System,
  pipes: PipeMap,
  downstreams: DownstreamMap,
  upstreams: UpstreamMap,
  lookups: LookupMap
) {
  const mainType = getConfigType(get(main, ['config', 'original']));
  const connectedType = getConfigType(get(connected, ['config', 'original']));

  if (mainType === 'pipe' && connectedType === 'pipe') {
    const mainUpstreamPipes = getUpstreamPipes(main as Pipe, pipes, upstreams, false);
    if (Array.isArray(mainUpstreamPipes) && mainUpstreamPipes.length > 0) {
      const mainUpstreamPipeIds = mainUpstreamPipes.map((pipe: Pipe) => pipe._id);

      if (mainUpstreamPipeIds.includes(connected._id)) {
        return 'directUpstream';
      }
    }

    const mainDownstreamPipes = getDownstreamPipes(main as Pipe, pipes, downstreams, false);
    if (Array.isArray(mainDownstreamPipes) && mainDownstreamPipes.length > 0) {
      const mainDownstreamPipeIds = mainDownstreamPipes.map((pipe: Pipe) => pipe._id);

      if (mainDownstreamPipeIds.includes(connected._id)) {
        return 'directDownstream';
      }
    }

    const mainUpstreamLookups = getUpstreamLookups(main as Pipe, pipes, upstreams);
    if (Array.isArray(mainUpstreamLookups) && mainUpstreamLookups.length > 0) {
      const mainUpstreamLookupIds = mainUpstreamLookups.map((pipe: Pipe) => pipe._id);

      if (mainUpstreamLookupIds.includes(connected._id)) {
        return 'indirectUpstream';
      }
    }

    const mainDownstreamLookups = getDownstreamLookups(main as Pipe, pipes, lookups);
    if (Array.isArray(mainDownstreamLookups) && mainDownstreamLookups.length > 0) {
      const mainDownstreamLookupIds = mainDownstreamLookups.map((pipe: Pipe) => pipe._id);

      if (mainDownstreamLookupIds.includes(connected._id)) {
        return 'indirectDownstream';
      }
    }
  } else if (mainType === 'system' && connectedType === 'pipe') {
    const connectedSourceSystem = get(connected, ['config', 'original', 'source', 'system']);
    const connectedSinkSystem = get(connected, ['config', 'original', 'sink', 'system']);

    if (connectedSourceSystem === main._id) {
      return 'directDownstream';
    } else if (connectedSinkSystem === main._id) {
      return 'directUpstream';
    }
  } else if (mainType === 'pipe' && connectedType === 'system') {
    const mainSourceSystem = get(main, ['config', 'original', 'source', 'system']);
    const mainSinkSystem = get(main, ['config', 'original', 'sink', 'system']);

    if (mainSourceSystem === connected._id) {
      return 'directUpstream';
    } else if (mainSinkSystem === connected._id) {
      return 'directDownstream';
    }
  }
}

function isCompletenessBelowThreshold(completeness, hours) {
  const completenessThreshold = (Date.now() - 1000 * 60 * 60 * hours) * 1000;
  if (typeof completeness === 'number' && completeness < completenessThreshold) {
    return true;
  }

  return false;
}

const GraphEdgeInfoPanel: React.FC<GraphEdgeInfoPanelProps> = (props) => {
  const { edge, main, nodeMode, hidden, setHidden } = props;

  const classes = useStyle();

  let nodeId = '';
  if (edge.length > 0) {
    const edgeSplit = edge.split(':');
    const len = edgeSplit.length;
    nodeId = edgeSplit.slice(1, len - 2).join(':');
  }

  const pipe = useSelector((state: RootState) => state.pipes[nodeId]);
  const system = useSelector((state: RootState) => state.systems[nodeId]);

  const datasets = useSelector((state: RootState) => state.datasets);
  const downstreams = useSelector((state: RootState) => state.downstreams);
  const lookups = useSelector((state: RootState) => state.lookups);
  const pipes = useSelector((state: RootState) => state.pipes);
  const subId = useSelector(subIdSelector);
  const upstreams = useSelector((state: RootState) => state.upstreams);
  const relationship = getRelationship(
    main,
    pipe || system,
    pipes,
    downstreams,
    upstreams,
    lookups
  );

  let from;
  let to;
  if (relationship === 'directDownstream' || relationship === 'indirectDownstream') {
    from = main;
    to = pipe || system;
  } else {
    from = pipe || system;
    to = main;
  }

  let populated = true;
  const toSinkDatasetId = getSinkDatasetId(to);
  const toSinkDataset = datasets[toSinkDatasetId];
  if (toSinkDataset !== undefined) {
    populated = get(toSinkDataset, 'runtime.populated');
  }

  const fromType = getConfigType(get(from, ['config', 'original']));
  const toType = getConfigType(get(to, ['config', 'original']));

  const fromIcon = getConfigIcon(get(from, ['config', 'original']));
  const toIcon = getConfigIcon(get(to, ['config', 'original']));

  let queueSize;
  if (relationship === 'directUpstream' || relationship === 'directDownstream') {
    queueSize = getPipeQueueSize(to as Pipe, from as Pipe);
  } else if (relationship === 'indirectUpstream' || relationship === 'indirectDownstream') {
    queueSize = getPipeQueueSize(to as Pipe, from as Pipe, true);
  }

  let fromCompleteness;
  const fromSinkDatasetId = getSinkDatasetId(from);
  const fromSinkDataset = datasets[fromSinkDatasetId];
  if (fromSinkDataset !== undefined) {
    fromCompleteness = get(fromSinkDataset, ['runtime', 'completeness']);
  }

  const info = [];

  if (queueSize !== undefined && typeof queueSize === 'number' && queueSize > 0) {
    info.push(
      <div className={classes.infoItem}>
        <div className={clsx([classes.infoItemIcon, classes.infoItemIconWarning])}>
          <WarningIcon />
        </div>
        <div>
          <Typography variant="subtitle2">Queue size</Typography>
          {withSeparators(queueSize)}
        </div>
      </div>
    );
  }

  if (
    fromCompleteness !== null &&
    fromCompleteness !== undefined &&
    typeof fromCompleteness === 'number'
  ) {
    const completenessRelative = moment(fromCompleteness / 1000).fromNow();
    const completenessTotal = moment(fromCompleteness / 1000).format('do MMM YYYY - HH:mm:ss');
    const completenessBelowThreshold = isCompletenessBelowThreshold(
      fromCompleteness,
      COMPLETENESS_THRESHOLD
    );
    info.push(
      <div className={classes.infoItem}>
        <div
          className={clsx([
            classes.infoItemIcon,
            completenessBelowThreshold === true && classes.infoItemIconWarning,
            completenessBelowThreshold === false && classes.infoItemIconInfo,
          ])}
        >
          {completenessBelowThreshold ? <WarningIcon /> : <InfoIcon />}
        </div>
        <div>
          <Tooltip title={completenessTotal}>
            <div>
              <Typography variant="subtitle2">Completeness</Typography>
              <SesamLink
                to={`/subscription/${subId}/pipes/pipe/${from._id}/output?jtype=updated&jval=${
                  fromCompleteness / 1000
                }`}
              >
                {completenessRelative}
              </SesamLink>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }

  if (hidden) return null;

  if (nodeMode) {
    const tokens = edge.split(':');
    const offset = tokens.length === 4 ? 3 : 1;
    const selectedNode = tokens[tokens.length - offset];
    return (
      <Box className={classes.container}>
        <Box.Header>
          <Box.Title>Info</Box.Title>
          <CloseIcon onClick={() => setHidden(true)} className={classes.closeIcon} />
        </Box.Header>
        <Box.Content white>
          <div>
            <div className={classes.node}>
              <div className={classes.nodeIcon}>{fromIcon}</div>
              <div>
                <Typography>{selectedNode}</Typography>
              </div>
            </div>
            {info.length > 0 && (
              <>
                <Divider />
                <div className={classes.infoList}>{info}</div>
              </>
            )}
          </div>
        </Box.Content>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box.Header>
        <Box.Title>Info</Box.Title>
        <CloseIcon onClick={() => setHidden(true)} className={classes.closeIcon} />
      </Box.Header>
      <Box.Content white>
        <div>
          <div className={classes.node}>
            <div className={classes.nodeIcon}>{fromIcon}</div>
            <div>
              <Typography variant="subtitle2">{`From ${fromType}`}</Typography>
              <Typography>{from._id}</Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.node}>
            <div className={classes.nodeIcon}>{toIcon}</div>
            <div>
              <Typography variant="subtitle2">{`To ${toType}`}</Typography>
              <Typography>{to._id}</Typography>
            </div>
          </div>
          {populated === false && (
            <>
              <Divider />
              <div className={classes.node}>
                <div className={clsx([classes.nodeIcon, classes.infoItemIconWarning])}>
                  <PopulatedIcon />
                </div>
                <div>
                  <Typography variant="subtitle2">Unpopulated</Typography>
                </div>
              </div>
            </>
          )}
          {info.length > 0 && (
            <>
              <Divider />
              <div className={classes.infoList}>{info}</div>
            </>
          )}
        </div>
      </Box.Content>
    </Box>
  );
};

export default GraphEdgeInfoPanel;
