import React from 'react';
import EntitySkeleton from './EntitySkeleton';
import { CustomTheme } from 'Types/common.types';

export const EntitySkeletons: React.FC<{
  isLoading: boolean;
  isLoadingMore: boolean;
  theme: CustomTheme;
}> = ({ isLoading, isLoadingMore, theme }) => (
  <>
    <div
      className="dataset-inspector-browser__list-skeleton"
      style={{ backgroundColor: theme.palette.background.light }}
    >
      {isLoading &&
        [1, 2, 3, 4, 5].map((num) => (
          <li
            key={num}
            style={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <EntitySkeleton />
          </li>
        ))}
    </div>
    {isLoadingMore && (
      <li style={{ backgroundColor: theme.palette.background.light }}>
        <EntitySkeleton />
      </li>
    )}
  </>
);
