import React, { Fragment } from 'react';
import clsx from 'clsx';
import datejs from 'dayjs';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';

import type { LogChange } from 'Types/common.types';
import Typography from 'Common/SesamTypography';
import HeaderButton from 'Components/header-button';
import { DialogContent, Divider } from '@material-ui/core';
import { TestID } from 'src/testID';

type ClassesNameMap = ClassNameMap<
  | 'changeTitle'
  | 'changeDate'
  | 'changesIcon'
  | 'divider'
  | 'lvl'
  | 'lvlCircle'
  | 'lvlCircleFilled'
  | 'lvlLabel'
  | 'okButton'
  | 'tags'
  | 'tag'
  | 'changeContent'
>;

const ChangeLogLevel: React.FC<{ progress: string; classes: ClassesNameMap }> = ({
  progress,
  classes,
}) => {
  let lvl;
  switch (progress) {
    case 'Experimental':
      lvl = 0;
      break;
    case 'Feature':
      lvl = 1;
  }

  return (
    <>
      <div className={clsx([classes.lvlCircle, classes.lvlCircleFilled])} />
      <div
        className={clsx([classes.lvlCircle, progress === 'Feature' && classes.lvlCircleFilled])}
      />
      <Typography className={classes.lvlLabel} variant="subtitle2">
        {progress}
      </Typography>
    </>
  );
};

const ChangeLogTags: React.FC<{ tags: string[]; classes: ClassesNameMap }> = ({
  tags,
  classes,
}) => (
  <>
    {tags.map((tag: string) => (
      <Chip size="small" key={`${tag}`} className={classes.tag} label={tag} />
    ))}
  </>
);

export const ChangeLog: React.FC<{
  change: LogChange;
  dateTime: string;
  classes: ClassesNameMap;
  testid?: string;
}> = ({ change, dateTime, classes, testid = TestID.ChangelogEntry }) => (
  <div data-testid={testid}>
    <div className={classes.changeTitle}>
      <Typography variant="h6">{change.title}</Typography>
      <Typography className={classes.changeDate} variant="subtitle2">
        {dateTime}
      </Typography>
    </div>
    <div className={classes.lvl}>
      <ChangeLogLevel progress={change.lvl} classes={classes} />
    </div>
    <div className={classes.changeContent} dangerouslySetInnerHTML={{ __html: change.content }} />
    <div className={classes.tags}>
      <ChangeLogTags tags={change.tags} classes={classes} />
    </div>
  </div>
);

export const ChangeLogButton: React.FC<{
  darkModeActive: boolean;
  classes: ClassesNameMap;
  showBadge: boolean;
  onClick: () => void;
  buttonTestid?: string;
  badgeTestid?: string;
}> = ({
  darkModeActive,
  classes,
  showBadge,
  onClick,
  buttonTestid = TestID.ChangelogButton,
  badgeTestid = TestID.ChangelogBadgeIndicator,
}) => (
  <HeaderButton darkModeActive={darkModeActive} testid={buttonTestid}>
    <button className="btn-link" onClick={onClick} title="See changelog…">
      <Badge
        overlap="circular"
        color="error"
        variant="dot"
        invisible={!showBadge}
        data-testid={badgeTestid}
      >
        <NewReleasesOutlinedIcon className={classes.changesIcon} />
      </Badge>
    </button>
  </HeaderButton>
);

export const ChangeLogDialogContent: React.FC<{
  changelogs: LogChange[];
  classes: ClassesNameMap;
}> = ({ changelogs, classes }) => (
  <DialogContent>
    {changelogs.map((change: LogChange, i) => (
      <Fragment key={change.id}>
        <ChangeLog
          change={change}
          dateTime={datejs(change.timestamp).format('D MMM YYYY')}
          classes={classes}
        />
        {i < changelogs.length - 1 && <Divider className={classes.divider} />}
      </Fragment>
    ))}
  </DialogContent>
);
