import React, { useState, useEffect } from 'react';
import values from 'lodash/values';
import moment from 'moment';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { Typography } from '@material-ui/core';
import { unixTimestampToDate } from 'Internals/datetime';
import SesamTextField from 'Components/common/SesamTextField/SesamTextField';

import ExpansionPanel from '../../../components/expansion-panel';
import PromiseButton from '../../../components/promise-button';
import KeyValue from '../../../components/key-value';
import type { SubscriptionJWT } from 'Types/subscription.types';
import type { Theme } from 'Types/common.types';
import Button from 'Components/common/Button/Button';
import SesamAutocomplete from 'Components/common/SesamAutocomplete/SesamAutocomplete';
import { Members } from 'Types/common.types';
import { updateJWT } from 'Utils/jwt.utils';

type JwtItemProps = {
  jwt: SubscriptionJWT;
  onDelete: (jwt: SubscriptionJWT) => void;
  onEdit: (jwt: SubscriptionJWT) => void;
  theme: Theme;
  members: Members;
};

const isExpired = (expiryDate: Date) => {
  const now = new Date();
  if (expiryDate < now) return 'expired';
  const future = now.setDate(now.getDate() + 14);
  if (expiryDate < future) return 'expiring';
  return false;
};

const getThemeColor = (expiryStatus: string | boolean, theme: Theme) => {
  if (expiryStatus === 'expired') return theme.palette.text.danger;
  if (expiryStatus === 'expiring') return theme.palette.warning.yellow;
  return theme.palette.text.primary;
};

const getTooltipText = (jwt: SubscriptionJWT, expiryStatus: string | boolean) => {
  let tooltip = `Id: ${jwt.id}`;
  if (expiryStatus === 'expired') {
    tooltip += ' (expired)';
  } else if (expiryStatus === 'expiring') {
    tooltip += ' (expiring soon)';
  }
  return tooltip;
};

const getExpiryStatus = (jwt: SubscriptionJWT) => {
  const expiryDate = unixTimestampToDate(jwt.payload.exp);
  const expiryStatus = isExpired(expiryDate);
  return expiryStatus;
};

type JwtDetails = {
  Description: string;
  Roles: string;
  Expiry: JSX.Element;
  Id: string;
  Owner?: string;
  'Client Id'?: string;
  'Refresh interval'?: string;
  'Created by'?: string;
  'Last modified by'?: string;
};

const getDetailsFromJwtObject = (jwt: SubscriptionJWT, theme: Theme): JwtDetails => {
  const expiryDate = unixTimestampToDate(jwt.payload.exp);
  const expiryFormatted = moment(expiryDate).format('MMMM Do YYYY');
  const expiryStatus = isExpired(expiryDate);
  const color = getThemeColor(expiryStatus, theme);
  const createdBy = get(jwt, 'audit.created_by.name');
  const createdByEmail = get(jwt, 'audit.created_by.email');
  const lastModifiedBy = get(jwt, 'audit.last_modified_by.name');
  const lastModifiedByEmail = get(jwt, 'audit.last_modified_by.email');

  const details: JwtDetails = {
    Description: jwt.description || '(none)',
    Roles: values(jwt.payload.principals).join(', '),
    Expiry: <Typography style={{ color }}>{expiryFormatted}</Typography>,
    Id: jwt.id,
  };
  if (jwt?.owner) {
    details['Owner'] = jwt.owner;
  }
  if (jwt.client_id) {
    details['Client Id'] = jwt.client_id;
  }
  if (jwt.refresh_interval) {
    details['Refresh interval'] = jwt.refresh_interval.toString();
  }
  if (jwt.refresh_interval) {
    details['Refresh interval'] = jwt.refresh_interval.toString();
  }
  if (createdBy && createdByEmail) {
    details['Created by'] = `${createdBy} (${createdByEmail})`;
  }
  if (lastModifiedBy && lastModifiedByEmail) {
    details['Last modified by'] = `${lastModifiedBy} (${lastModifiedByEmail})`;
  }

  return details;
};

const getMembersEmailArray = (members: Members) => {
  return members.map((member) => member['user']['email']) ?? [];
};

export const JwtItem: React.FC<JwtItemProps> = (props) => {
  const { jwt, onDelete, onEdit, theme, members } = props;

  const details = getDetailsFromJwtObject(jwt, theme);
  const membersEmailArray = getMembersEmailArray(members);

  const jwtDescription = jwt.description;
  const jwtOwner = jwt.owner;
  const jwtName = jwt.name;

  const [state, setState] = useState({
    description: jwtDescription,
    owner: jwtOwner,
    name: jwtName,
  });
  const [editMode, setEditMode] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const expiryStatus = getExpiryStatus(jwt);
  const tooltip = getTooltipText(jwt, expiryStatus);

  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [field]: event.target.value });
  };

  const handleOwnerChange = (owner: string) => {
    setState({ ...state, owner: owner });
  };

  const handleEdit = () => {
    if (editMode) {
      const clonedJwt = cloneDeep(jwt);
      clonedJwt.description = state.description;
      clonedJwt.owner = updateJWT(props.members, state.owner);
      clonedJwt.name = state.name;
      onEdit(clonedJwt);
    }

    setEditMode(!editMode);
  };

  useEffect(() => {
    if (
      state.description !== jwtDescription ||
      state.owner !== jwtOwner ||
      state.name !== jwtName
    ) {
      setHasChanged(true);
    }
  }, [state]);

  return (
    <ExpansionPanel title={jwt.name} tooltip={tooltip} warning={expiryStatus}>
      {editMode && (
        <>
          <SesamTextField
            id="jwt-name"
            label="Name"
            onChange={handleFieldChange('name')}
            value={state.name}
            margin="normal"
            variant="standard"
          />
          <SesamTextField
            id="jwt-description"
            label="Description"
            onChange={handleFieldChange('description')}
            value={state.description}
            margin="normal"
            variant="standard"
          />
          <SesamAutocomplete
            options={membersEmailArray}
            getOptionLabel={(o) => o}
            value={state.owner}
            onChange={(ev, val) => handleOwnerChange(val)}
            size="small"
            renderInput={(params) => {
              return <SesamTextField {...params} size="small" label="Owner" margin="normal" />;
            }}
          />
        </>
      )}

      {!editMode && <KeyValue list={details} />}

      <div className="toolbar toolbar--right">
        {editMode && (
          <>
            <PromiseButton
              onClick={() => handleEdit()}
              pending="Saving…"
              theme="primary"
              disabled={!hasChanged}
            >
              Save
            </PromiseButton>
            <Button onClick={() => setEditMode(false)} theme="default">
              Cancel
            </Button>
          </>
        )}
        {!editMode && (
          <>
            <Button onClick={() => setEditMode(true)} theme="primary">
              Edit
            </Button>
            <PromiseButton onClick={() => onDelete(jwt)} pending="Deleting…" theme="danger">
              Delete…
            </PromiseButton>
          </>
        )}
      </div>
    </ExpansionPanel>
  );
};
