import { ChangeLogLevel, ChangeLogTags } from '../types/enums';
import { LogChange } from 'Types/common.types';

export const changelog: LogChange[] = [
  {
    id: '1',
    timestamp: 1603058400000,
    title: 'Change log',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>We have added a change log to Management Studio. This will make it easier for us to communicate improvements to existing features, or new features that might else go under the radar. To see the change log, click the new icon in the right side of the app bar. </p><p>New changes will automatically display when you log in. Though you are able to opt out of automatically seeing new changes, we suggest you keep the default. We will make sure it doesn&#39;t feel like spam.</p>',
  },
  {
    id: '2',
    timestamp: 1604927377000,
    title: 'Getting to internal system datasets/pipes/systems',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>Internal system datasets/pipes/systems are now <i>not</i> fetched by default.</p><p>If you still want to get to them, go to one of the lists and type "system" in the Origin facet. This will refresh the list with the internal system components.</p>',
  },
  {
    id: '3',
    timestamp: 1605707860909,
    title: 'Incomplete pipes',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>We have added &quot;Incomplete&quot; as a column in the pipes list. If a pipe is incomplete it means that the pipe&#39;s sink dataset is not read from anywhere, and therefore stops the data flow. &quot;Incomplete&quot; is also visible in the status box on the pipe dashboard, or in the status bar on top of the execution log.</p>',
  },
  {
    id: '4',
    timestamp: 1612264581447,
    title: 'Improvements to the editor',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>We added new syntax highlighting to the config editor which highlights function names and comments in DTL. We also added the option to Ctrl/Cmd+Click pipe names and system names in the editor to quickly navigate to them.</p>',
  },
  {
    id: '5',
    timestamp: 1617040179696,
    title: 'Graph view improvements',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>We added some improvements in the Graph view for Pipes and Systems. When hovering/clicking on connections, a sidebar appears with information about the queues and <a href="https://docs.sesam.io/configuration.html#completeness">completeness</a>. The edge of the graph turns orange if there are entities in a queue or the completeness value is older than 48 hours.</p>',
  },
  {
    id: '6',
    timestamp: 1618506918690,
    title: 'Dark mode (beta)',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>We added a dark mode option to the Management Studio. You can find the toggle in the user menu in the upper right corner. The feature is still a bit experimental, so let us know if you find any issues.</p>',
  },
  {
    id: '7',
    timestamp: 1629208705000,
    title: 'Announcing the Developer environment',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>A developer environment has a fixed price and serves a single developer, or a CI test environment. These do not have support for backup, VPN, or SLA and can not host test, staging or production environments. The development environment is cloud-only and restricted. We strongly encourage following test-driven development practices by using the Sesam Client.</p><p>If you currently are on a Small subscription and is using it for development, then you might consider replacing it with a Developer edition as the cost is considerably lower.</p>',
  },
  {
    id: '8',
    timestamp: 1632413741000,
    title: 'The "Datasets" page has been removed',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>A dataset is managed by a pipe and considered a part the pipe. All the details about a dataset have therefore been moved to the pipe page of the pipe that writes to the dataset (under Output). Internal datasets can be found under "Datahub" > "Internal datasets".</p>',
  },
  {
    id: '9',
    timestamp: 1637318787000,
    title: 'Added new "Discard retry queue" pipe operation',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>This operation will make the next pipe run ignore any previous write error retries by writing a special "pump-discard-retries" entity to the pipes execution log. This operation can only be done on non-running pipes.</p>',
  },
  {
    id: '10',
    timestamp: 1697189084000,
    title: 'Added a new code style option to change the font size of the editors',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>This feature is accessible from the "Editor options" menu on config tab of pipes and it will allow you to change the font size of all config viewers and editors.</p>',
  },
  {
    id: '11',
    timestamp: 1740732235750,
    title: 'Repost Dead Letters',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>Added the ability to repost single or all Dead Letter entities from the Dead Letters dataset. This will repost the original entities upstream.</p>',
  },
  {
    id: '12',
    timestamp: 1740732235755,
    title: 'Added JWT Owner filed',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content:
      '<p>Introduced the JWT Owner field, allowing better tracking and management of JWT entries by associating them with a specific owner. This enhancement improves clarity and accountability in JWT configurations.</p>',
  },
  {
    id: '13',
    timestamp: 1740732235760,
    title: 'Edit JWT',
    lvl: ChangeLogLevel.FEATURE,
    tags: [ChangeLogTags.UI],
    content: `<p>Enhanced JWT entry management by allowing users to modify the Name, Description, and Owner fields. This update provides better control and customization of JWT configurations, in addition to the existing ability to add and delete JWT entries.</p>`,
  },
];
