import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';

import EditPipeNotificationRuleForm from './EditPipeNotificationRuleForm';
import NewPipeNotificationRuleForm from './NewPipeNotificationRuleForm';
import PipeNotificationActions from 'Redux/thunks/pipe-notifications';
import SubActions from 'Redux/thunks/subscriptions';
import { LoadingPanel } from 'Common/LoadingPanel';
import { PipeNotificationRuleTypeInfo } from '../../types/notifications';
import type { RootState } from 'Types/state.types';
import SesamLink from 'Common/Links/SesamLink';

interface PipeNotificationsProps {
  registerRefresh: (fn: () => void) => void;
  unregisterRefresh: (fn: () => void) => void;
  params: { pipeID: string; subId: string };
}

// TODO: Delete unused components and stylesheets
// TODO: Alerts generated

const PipeNotifications: React.FC<PipeNotificationsProps> = (props) => {
  const { params, registerRefresh, unregisterRefresh } = props;
  const { pipeID: pipeId, subId } = params;
  const [availableRuleTypes, setAvailableRuleTypes] = useState<PipeNotificationRuleTypeInfo[]>([]);
  const [selectedRuleIdx, setSelectedRuleIdx] = useState<number>(0);
  const [rulesLoaded, setRulesLoaded] = useState<boolean>(false);
  const rules = useSelector((state: RootState) => state.pipeNotifications[pipeId]);
  const subData = useSelector((state: RootState) =>
    state.subscriptions.find((s) => s.id === state.subscription.id)
  );
  const monitoring = get(subData, 'products.monitoring');
  const dispatch = useDispatch();
  const loadAvailableNotificationRuleTypes = (subId: string, pipeId: string) =>
    dispatch(PipeNotificationActions.loadAvailableNotificationRuleTypes(subId, pipeId));
  const loadAvailableRoles = (subId: string) => dispatch(SubActions.loadAvailableRoles(subId));
  const loadMembers = (subId: string) => dispatch(SubActions.loadMembers(subId));
  const loadRules = () => dispatch(PipeNotificationActions.load(subId, pipeId));

  const loadNotificationData = useCallback(() => {
    loadMembers(subId);
    loadAvailableRoles(subId);
    loadAvailableNotificationRuleTypes(subId, pipeId).then(
      (ruleTypes: PipeNotificationRuleTypeInfo[]) => {
        setAvailableRuleTypes(ruleTypes);
      }
    );
    loadRules().then(() => {
      setRulesLoaded(true);
    });
  }, [subId, pipeId]);

  useEffect(() => {
    loadNotificationData();
  }, [loadNotificationData]);

  useEffect(() => {
    registerRefresh(loadNotificationData);
    return () => {
      unregisterRefresh(loadNotificationData);
    };
  });

  let selectedRule;
  if (rules !== undefined && rules.length > 0 && selectedRuleIdx !== undefined) {
    selectedRule = rules[selectedRuleIdx];
  }

  return (
    <main className="scrollArea">
      <div className="row">
        <div className="col gr-secondary">
          <h2 className="heading-section">Add notification rule</h2>
          {!monitoring && (
            <p>
              Notification rules require Enterprise Monitoring. This will incur an additional cost
              of $25 for pipe per month. Data collection starts only after you add the first rule.
              <br />
              <SesamLink to={`/subscription/${subId}/settings-subscription/products`}>
                Enable Metrics and monitoring feature to lower your costs
              </SesamLink>
            </p>
          )}
          <NewPipeNotificationRuleForm
            availableRuleTypes={availableRuleTypes}
            pipeId={pipeId}
            subId={subId}
          />
        </div>
        <div className="col gr-primary">
          <h2 className="heading-section">Existing notification rules</h2>
          {!rulesLoaded && <LoadingPanel size="small" />}
          {((rules && rules.length === 0) || (rules === undefined && rulesLoaded)) && (
            <p>There are no notification rules on this pipe.</p>
          )}
          {rules &&
            rules.map((rule) => {
              return (
                <div key={rule.id}>
                  <EditPipeNotificationRuleForm rule={rule} pipeId={pipeId} subId={subId} />
                </div>
              );
            })}
        </div>
      </div>
    </main>
  );
};

export default PipeNotifications;
