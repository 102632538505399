import { connect, useSelector } from 'react-redux';
import { generateTableColumnsFromEntities } from 'Internals/table';
import { getSourceDatasetIds } from 'Internals/pipes';
import { sfetchJson } from 'Internals/sfetch';
import { registerKey, unregisterKey } from 'Internals/global-shortcuts';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import produce from 'immer';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Add from '@material-ui/icons/Add';
import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';
import DataViewTable from './table/DataViewTable';
import EditorActions from 'Redux/thunks/editor';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import HopsWizard from './wizards/hops/HopsWizard';
import IconButton from '@material-ui/core/IconButton';
import PipeActions from 'Redux/thunks/pipes';
import Undo from '@material-ui/icons/Undo';

import TrashIcon from '../../images/icons/trash.svg';

import FormulaBar from './components/FormulaBar';

import './DataViewStyle.css';
import { getEntities } from '../../api/entities';

function getTransform(obj) {
  return get(obj, getPathToTransform(obj), {
    type: 'dtl',
    rules: {
      default: [],
    },
  });
}

function getPathToTransform(config) {
  return isArray(config.transform) ? ['transform', '0'] : ['transform'];
}

function DataView(props) {
  const { onConfigChanged, onSave, pipe, pipeConfig, previewPipe, subUrl, token, validateConfig } =
    props;

  const [configErrors, setConfigErrors] = useState([]);
  const [configValid, setConfigValid] = useState(false);
  const [currentStep, setCurrentStep] = useState('');
  const [currentStepErrorMsg, setCurrentStepErrorMsg] = useState('');
  const [sourceEntities, setSourceEntities] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [previewConfig, setPreviewConfig] = useState({});
  const [previewedEntities, setPreviewedEntities] = useState([]);
  const [previousStepPreviewConfig, setPreviousStepPreviewConfig] = useState({});
  const [previousStepPreviewedEntities, setPreviousStepPreviewedEntities] = useState([]);
  const [wizard, setWizard] = useState(null);

  const history = useRef([]);

  const currentStepReset = useCallback(() => {
    if (selectedStep !== null) {
      const transforms = get(
        pipeConfig,
        [...getPathToTransform(pipeConfig), 'rules', 'default'],
        []
      );

      const steps = [['source'], ...transforms];

      if (selectedStep >= steps.length) {
        setSelectedStep(steps.length - 1);
      } else {
        setCurrentStep(JSON.stringify(steps[selectedStep]));
      }
    }
  }, [pipeConfig, selectedStep]);

  const getCopiedColumns = (transforms) =>
    transforms.reduce((acc, transform) => {
      if (transform[0] === 'copy') {
        if (transform[1] !== '*') {
          if (!acc.includes(transform[1])) {
            acc.push(transform[1]);
          }
        }
      }
      return acc;
    }, []);

  const getDataTypes = (schema, data) =>
    schema.reduce((acc, curr) => {
      function addDataTypesToHeader(id) {
        for (let i = 0; i < data.length; i++) {
          if (!acc[id]) {
            acc[id] = typeof data[i][id];
          } else {
            if (typeof data[i][id] !== acc[id]) {
              acc[id] = 'mix';
              break;
            }
          }
        }
      }

      if (curr.columns) {
        curr.columns.forEach((col) => addDataTypesToHeader(col.id));
      } else if (curr.id) {
        addDataTypesToHeader(curr.id);
      }

      return acc;
    }, {});

  const getPreviewConfig = useCallback(
    (step) =>
      produce(pipeConfig, (draft) => {
        const transform = getTransform(draft);

        if (transform.type === 'dtl') {
          if (transform.rules.default.slice(0, step).length > 0) {
            transform.rules.default = transform.rules.default.slice(0, step);
          } else {
            transform.rules.default = [['copy', '*']];
          }
        }
      }),
    [pipeConfig]
  );

  const handleColumnCopy = (id) => {
    transformAdd(['copy', id], false);
  };

  const handleColumnRemove = (id) => {
    transformAdd(['remove', id], true);
  };

  const handleCurrentStepReset = () => {
    currentStepReset();
  };

  const handleCurrentStepSave = () => {
    transformUpdate();
  };

  const handleCurrentStepUpdate = (val) => {
    setCurrentStep(val);
  };

  const handleStepAdd = (transform) => {
    transformAdd(transform, true);
  };

  const handleStepRemove = (id) => {
    transformRemove(id);
  };

  const previewReset = useCallback(
    (updateConfig = true) => {
      if (updateConfig) {
        onConfigChanged(pipeConfig);
      }

      if (selectedStep === null) {
        const transforms = get(
          pipeConfig,
          [...getPathToTransform(pipeConfig), 'rules', 'default'],
          []
        );
        setSelectedStep(transforms.length);
      }
    },
    [onConfigChanged, pipeConfig]
  );

  const transformAdd = (transformToAdd, updateSelectedStep = false) => {
    onConfigChanged(
      produce(pipeConfig, (draft) => {
        const transform = getTransform(draft);

        if (transform.rules.default) {
          transform.rules.default.splice(selectedStep, 0, transformToAdd);
        }

        draft.transform = transform;
      })
    );

    if (updateSelectedStep) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const transformUpdate = () => {
    onConfigChanged(
      produce(pipeConfig, (draft) => {
        const transform = getTransform(draft);

        if (transform.rules.default) {
          transform.rules.default[selectedStep - 1] = JSON.parse(currentStep);
        }

        draft.transform = transform;
      })
    );
  };

  const transformRemove = (id) => {
    onConfigChanged(
      produce(pipeConfig, (draft) => {
        const transform = getTransform(draft);
        transform.rules.default.splice(id - 1, 1);
      })
    );

    if (selectedStep >= id) {
      setSelectedStep(selectedStep - 1);
    }
  };

  const undo = () => {
    if (history.current.length > 1) {
      history.current.pop();
      onConfigChanged(history.current[history.current.length - 1]);
    }
  };

  useEffect(() => {
    if (
      JSON.stringify(pipeConfig) !== JSON.stringify(history.current[history.current.length - 1])
    ) {
      history.current.push(pipeConfig);
    }
  }, [pipeConfig]);

  useEffect(() => {
    previewReset(false);
  }, [previewReset]);

  useEffect(() => {
    let isSubscribed = true;

    const sourceDatasetIds = getSourceDatasetIds(pipe);
    const sourceDatasetId = sourceDatasetIds.length > 0 ? sourceDatasetIds[0] : [0]; // FIXME: ???

    const urlParams = {
      datasetId: sourceDatasetId,
      limit: 30,
      reverse: true,
      deleted: false,
      history: false,
    };
    const apiConf = {
      subUrl: props.subUrl,
      token: props.token,
    };

    getEntities(apiConf, urlParams).then((sourceEntities) => {
      if (isSubscribed) {
        setSourceEntities(sourceEntities);
      }
    });

    return () => (isSubscribed = false);
  }, [pipe, subUrl, token]);

  useEffect(() => {
    setPreviewConfig(getPreviewConfig(selectedStep));
    setPreviousStepPreviewConfig(getPreviewConfig(selectedStep - 1));

    setConfigValid(false);
  }, [getPreviewConfig, selectedStep]);

  useEffect(() => {
    /**
     * Adds a previewed entity to a previewed entities array at the correct index
     * @param {object} pe -> Previewed entity
     * @param {array} pes -> Previewed entities
     * @param {collection} idt -> id table
     */
    function updatePreviewedEntities(pe, pes, idt) {
      const pesCopy = [...pes];
      const peUpdated = pe['source']['_updated'];
      const idxLookup = find(idt, (i) => i['entityId'] === peUpdated);
      pesCopy[idxLookup['idx']] = pe;
      return pesCopy;
    }

    let isSubscribed = true;

    if (configValid) {
      if (sourceEntities.length > 0 && Object.keys(previewConfig).length > 0) {
        setPreviewedEntities(sourceEntities.map(() => ({})));
        setPreviousStepPreviewedEntities([]);

        const idTable = [];
        sourceEntities.forEach((curr, idx) => {
          idTable.push({ idx: idx, entityId: curr['_updated'] });
        });

        sourceEntities.forEach((entity) => {
          if (isSubscribed) {
            previewPipe(previewConfig, entity)
              .then((previewedEntity) => {
                if (isSubscribed) {
                  setPreviewedEntities((previewedEntities) =>
                    updatePreviewedEntities(previewedEntity, previewedEntities, idTable)
                  );

                  previewPipe(previousStepPreviewConfig, entity).then((previewedEntity) => {
                    if (isSubscribed) {
                      setPreviousStepPreviewedEntities((previewedEntities) =>
                        updatePreviewedEntities(previewedEntity, previewedEntities, idTable)
                      );
                    }
                  });
                }
              })
              .catch((e) => {});
          }
        });
      }
    }

    return () => (isSubscribed = false);
  }, [configValid, previewConfig, previewPipe, previousStepPreviewConfig, sourceEntities]);

  useEffect(() => {
    validateConfig(previewConfig).then((e) => {
      setConfigErrors(e['config-errors']);
      setConfigValid(e['is-valid-config']);
    });
  }, [previewConfig, validateConfig]);

  useEffect(() => {
    currentStepReset();
  }, [currentStepReset]);

  useEffect(() => {
    try {
      JSON.parse(currentStep);
      setCurrentStepErrorMsg('');
    } catch (e) {
      setCurrentStepErrorMsg(e.message);
    }
  }, [currentStep]);

  useEffect(() => {
    registerKey('Escape', currentStepReset, {}, true, true);
    registerKey('s', () => onSave(), { ctrlKey: true }, true, true);
    registerKey('z', () => undo(), { ctrlKey: true }, true, true);

    return () => {
      unregisterKey('Escape');
      unregisterKey('s', { ctrlKey: true });
      unregisterKey('z', { ctrlKey: true });
    };
  }, [currentStepReset, onSave, undo]);

  const transforms = get(pipeConfig, [...getPathToTransform(pipeConfig), 'rules', 'default'], []);
  const steps = [['source'], ...transforms];

  const currentStepData = useMemo(
    () =>
      previewedEntities
        .map((previewedEntity) => {
          if (Object.keys(previewedEntity).length > 0) {
            const previewSink = get(previewedEntity, 'sink', []);
            return previewSink;
          } else return previewedEntity;
        })
        .flat(),
    [previewedEntities]
  );

  const previousStepData = useMemo(
    () =>
      previousStepPreviewedEntities
        .map((previewedEntity) => {
          if (previewedEntity) {
            const previewSink = get(previewedEntity, 'sink', []);
            return previewSink;
          } else return previewedEntity;
        })
        .flat(),
    [previousStepPreviewedEntities]
  );

  const columns = useMemo(
    () => generateTableColumnsFromEntities(currentStepData, true, false),
    [currentStepData]
  );

  const copiedColumns = getCopiedColumns(transforms);
  const dataTypes = getDataTypes(columns, currentStepData);

  return (
    <ErrorBoundary>
      <div className="data-view">
        <FormulaBar
          onSave={handleCurrentStepSave}
          onReset={handleCurrentStepReset}
          onUpdate={handleCurrentStepUpdate}
          error={currentStepErrorMsg}
          value={currentStep}
        />
        <div className="data-view__preview">
          <Box>
            <Box.Header>
              <Box.Title>Preview</Box.Title>
              <Box.Actions>
                <Button
                  className="data-view__preview-action"
                  onClick={() =>
                    setWizard(
                      <HopsWizard
                        sourceId={pipe['_id']}
                        onClose={() => setWizard(null)}
                        onFinish={(transform) => {
                          handleStepAdd(transform);
                          setWizard(null);
                        }}
                      />
                    )
                  }
                  size="small"
                >
                  Add data…
                </Button>
              </Box.Actions>
            </Box.Header>
            <Box.Content white>
              <div className="data-view__table">
                <DataViewTable
                  configErrors={configErrors}
                  columns={columns}
                  copiedColumns={copiedColumns}
                  data={currentStepData}
                  dataFromPreviousStep={previousStepData}
                  dataTypes={dataTypes}
                  isSourceSelected={selectedStep === 0}
                  onColumnCopy={handleColumnCopy}
                  onColumnRemove={handleColumnRemove}
                />
              </div>
            </Box.Content>
          </Box>
          <div className="data-view__steps">
            <Box>
              <Box.Header>
                <Box.Title>Transforms</Box.Title>
              </Box.Header>
              <Box.Content white>
                {steps.map((step, i) => (
                  <div
                    className={`data-view__step ${
                      i === selectedStep ? 'data-view__step--active' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedStep(i);
                    }}
                    key={i}
                  >
                    <span>{step[0]}</span>
                    {step[0] !== 'source' && (
                      <span
                        className="data-view__step-delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleStepRemove(i);
                        }}
                      >
                        <TrashIcon />
                      </span>
                    )}
                  </div>
                ))}
                <div className="data-view__step_add-step">
                  <IconButton onClick={() => handleStepAdd([''])} size="small">
                    <Add />
                  </IconButton>
                  <IconButton onClick={() => undo()} size="small">
                    <Undo />
                  </IconButton>
                </div>
              </Box.Content>
            </Box>
          </div>
        </div>
      </div>
      {wizard}
    </ErrorBoundary>
  );
}

DataView.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  isValidConfig: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onConfigChanged: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  pipe: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  pipeConfig: PropTypes.shape({}),
  previewPipe: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  shortMessage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  validateConfig: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  previewPipe: (pipe, entity, useTrace) =>
    dispatch(PipeActions.previewPipe(pipe, entity, useTrace)),
  validateConfig: (config) => dispatch(EditorActions.validateConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataView);
