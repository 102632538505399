import { store } from 'Internals/store';
import { Dispatch, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { apiConfSelector } from 'Redux/selectors';
import { getNamespaceFromEntity } from 'Internals/repostDeadEntities.utils';
import { setErrorMessagesAction, setModalInfoAction } from 'Redux/thunks/postEntitiesModal.thunks';
import { apologise } from 'Redux/thunks/apology';

import EntityAPI from '../api/entities';
import PipesAPI from '../api/pipes';

import type { Entities, Entity } from 'Types/entities.types';
import { Pipes } from 'Types/pipes.types';

type RepostDeadEntitiesArgs = {
  datasetId: string;
  targetDatasetId: string;
  pipes: Pipes;
  dispatch: Dispatch<{ type: string; payload: string | Entities }>;
};

type AppDispatch = typeof store.dispatch;

export const useRepostDeadEntities = (args: RepostDeadEntitiesArgs) => {
  const { datasetId = '', targetDatasetId, pipes, dispatch } = args;
  const apiConfBase = useSelector(apiConfSelector);
  const storeDispatch = useDispatch<AppDispatch>();

  const apiConf = useMemo(() => {
    return {
      ...apiConfBase,
      dataset: encodeURIComponent(datasetId),
    };
  }, [apiConfBase, datasetId]);

  return async (postSingleEntity: boolean, selectedEntity: Entity) => {
    const latestEntities =
      postSingleEntity === true
        ? [selectedEntity]
        : await EntityAPI.fetchEntities(apiConf, {
            deleted: false,
            history: false,
          });

    if (!latestEntities?.length) {
      apologise('No source entities found!');
      return;
    }

    // use the first dead letter entity to get its pipe's effective config
    const pipe = await PipesAPI.get(apiConf, latestEntities[0]?.pipe);
    const upstreamDatasetNamespace = pipe?.config?.effective?.source?.dataset;
    const removeNamespaces = pipe?.config?.effective?.remove_namespaces;

    // fetch last original entity using the upstream dataset namespace
    const lastEntity = await EntityAPI.fetchEntities(
      {
        ...apiConfBase,
        dataset: encodeURIComponent(upstreamDatasetNamespace),
      },
      {
        reverse: true,
        limit: 1,
        deleted: false,
        history: false,
      }
    ).catch((error) => {
      storeDispatch(apologise(error));
      console.error(error);
    });

    if (!lastEntity) {
      return;
    }

    let currentNamespace = '';

    if (lastEntity?.length && lastEntity.length > 0) {
      currentNamespace = getNamespaceFromEntity({
        entity: lastEntity[0],
        removeNamespaces,
      });
    }

    if (latestEntities?.length) {
      const originalEntities = latestEntities.map((entity) => {
        const id = entity.entity._id;
        const namespace = pipes[entity?.pipe]?.config?.effective?.source?.dataset;
        const sourceCompleteId = `${pipes[entity?.pipe]?.config?.effective?.source?.dataset}:${
          entity?.entity?._id
        }`;

        return {
          _id: entity._id,
          pipe: pipes[entity?.pipe],
          id,
          namespace,
          completeId: currentNamespace
            ? `${currentNamespace}:${entity?.entity?._id}`
            : sourceCompleteId,
        };
      });

      // 2. load original entities
      const promises: Promise<typeof EntityAPI.getEntity>[] = [];
      const errors: string[] = [];

      originalEntities?.forEach(async (entity: any) => {
        promises.push(
          EntityAPI.getEntity(
            {
              ...apiConfBase,
              dataset: encodeURIComponent(entity.namespace),
            },
            entity.completeId
          ).catch((err) => {
            const errorMessage =
              typeof err === 'string'
                ? err
                : `Failed to fetch ${entity.namespace} entity id: ${entity.completeId}`;
            errors.push(errorMessage);
            console.error(errorMessage, err);
          })
        );
      });

      if (originalEntities?.length > 0 && targetDatasetId) {
        dispatch({
          type: 'setPostTargetDatasetId',
          payload: targetDatasetId,
        });

        const enhancedEntities: Entities = [];

        Promise.all(promises).then((entities: any) => {
          entities.forEach((entity: any) => {
            if (entity?._id) {
              enhancedEntities.push(entity);
            }
          });

          if (errors.length) {
            storeDispatch(setErrorMessagesAction(errors));
          }

          if (enhancedEntities && enhancedEntities.length > 0) {
            dispatch({ type: 'postMultipleEntities', payload: enhancedEntities });
          } else {
            storeDispatch(apologise('No source entities found!'));
          }
        });
      }
    }

    storeDispatch(
      setModalInfoAction({
        title: 'Repost multiple entities upstream',
        subTitle: `Reposting following entities to ${currentNamespace}`,
      })
    );
  };
};
