import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import qs from 'qs';
import { SomeObject, Optional } from 'Types/common.types';

export function makeFilteredObjectsGetter(items: SomeObject[], filters: SomeObject) {
  return items.filter((item) => {
    const filterKeys = Object.keys(filters);
    return filterKeys.every((key) => {
      const filter = filters[key];

      let value = get(item, key);
      const selected = filter.selected;
      const search = filter.search;

      let hasSearch = false;
      let hasSelected = false;

      if (isEmpty(search) && isEmpty(selected)) {
        return true;
      }

      if (Array.isArray(value)) {
        if (isEmpty(value)) {
          return false;
        }

        /* Objects with { name, value } allowed */
        value = value.map((val) => (isObject(val) ? val.name : val));

        if (!isEmpty(search)) {
          hasSearch = value.some((val) => search.some((x) => createSearchRegex(x + '*').test(val)));
        }

        if (!isEmpty(selected)) {
          hasSelected = value.some((val) => selected.includes(val));
        }

        return hasSearch || hasSelected;
      }

      if (!isEmpty(search)) {
        hasSearch = search.some((search) => createSearchRegex(search + '*').test(value));
      }

      if (!isEmpty(selected)) {
        hasSelected = selected.includes(value);
      }

      return hasSearch || hasSelected;
    });
  });
}

export function createSearchRegex(filterValue: Optional<string>) {
  if (filterValue === undefined || filterValue === null || filterValue === '') {
    // no filtering
    return new RegExp(`.*`);
  }
  const prefix = filterValue.startsWith('*') ? '.*' : '^';
  const postfix = filterValue.endsWith('*') ? '.*' : '$';
  return new RegExp(`${prefix}${escapeRegExp(filterValue.replace(/\*/g, ''))}${postfix}`);
}

export function getQueryStringFromFilter(filter: SomeObject, filterLabelMapping: SomeObject) {
  const labeledFilter: SomeObject = {};
  for (const [k, v] of Object.entries(filterLabelMapping)) {
    labeledFilter[v] = filter[k];
  }
  const queryString = qs.stringify(labeledFilter, {
    addQueryPrefix: true,
    allowDots: true,
    indices: false,
  });

  return queryString;
}

export function getFilterFromQueryString(queryString: string, filterLabelMapping: SomeObject) {
  const parsed = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    allowDots: true,
    parseArrays: true,
  });

  const unlabeledFilter: SomeObject = {};
  for (const [k, v] of Object.entries(filterLabelMapping)) {
    if (parsed[v]) unlabeledFilter[k] = parsed[v];
  }

  for (const key of Object.keys(unlabeledFilter)) {
    if (unlabeledFilter[key].selected && !Array.isArray(unlabeledFilter[key].selected)) {
      unlabeledFilter[key].selected = [unlabeledFilter[key].selected];
    }
    if (unlabeledFilter[key].search && !Array.isArray(unlabeledFilter[key].search)) {
      unlabeledFilter[key].search = [unlabeledFilter[key].search];
    }
  }

  return unlabeledFilter;
}

export function validateFilter(filter: Optional<SomeObject>) {
  if (isNull(filter) || !isObject(filter)) return false;
  return Object.keys(filter).every((key) => {
    if (isNull(filter[key]) || !isObject(filter[key])) return false;
    return true;
  });
}
