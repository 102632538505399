import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingPanel } from 'Common/LoadingPanel';
import { sortObject, sortArray } from 'Internals/sort';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import JsonEditor from 'Common/JsonEditor/JsonEditor';
import JsonPanel from 'Common/JsonPanel';
import { getSourceDatasetIdsFromPipeConfig } from 'Internals/pipes';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MoreVert from '@material-ui/icons/MoreVert';
import SesamIconButtonPopover from 'Common/SesamIconButtonPopover/SesamIconButtonPopover';

import './style.css';
import MergeSourceNavigator from '../merge-source-navigator/MergeSourceNavigator';
import NotAvailable from '../not-available';
import useToggle from '../../hooks/useToggle';
import usePostEntities from '../../hooks/usePostEntities';
import PostEntityModal from '../dataset-inspector/PostEntityModal';
import EditorPanelDivider from '../editor/EditorPanelDivider';
import DatasetNavigator from '../dataset-navigator/DatasetNavigator';
import EntityNavigator from '../entity-navigator/EntityNavigator';
import Feedback from '../feedback';

// const SUBSET_REGEX = new RegExp(/sesam:subset:(.*)/, 'i');

const PipePreviewPanel = (props) => {
  const {
    pipeObjectConfig,
    pipeId,
    subset,
    subUrl,
    token,
    sampleData,
    sourceData,
    revertCount,
    previewErrors,
    outputReady,
    outputStale,
    onHandleEntityFetched,
    onSourceChanged,
    onRun,
    onDebug,
    startingRun,
    transformed,
    show,
    output,
    outputInProgress,
    trace,
  } = props;

  const [showTransformed, toggleShowTransformed] = useToggle(false);
  const sinkDatasetId = get(pipeObjectConfig, 'effective.sink.dataset');
  const sourceDatasetIds = useMemo(
    () => getSourceDatasetIdsFromPipeConfig(pipeObjectConfig),
    [pipeObjectConfig]
  );

  const {
    sendPost,
    postReset,
    setPost,
    post,
    setPostValid,
    postJson,
    state: postState,
  } = usePostEntities(sinkDatasetId, subUrl, token);

  const sourceType = get(pipeObjectConfig, ['effective', 'source', 'type']);
  const url = get(pipeObjectConfig, ['effective', 'source', 'url'], '');

  const sourceDataset = useMemo(() => {
    if (sourceType === 'dataset') {
      if (sourceDatasetIds.length > 1 || sourceDatasetIds.length === 0) {
        return null;
      } else {
        return sourceDatasetIds[0];
      }
    } else if (sourceType === 'binary') {
      const splitUrl = url.split('/');

      if (splitUrl.includes('datasets')) {
        return splitUrl[splitUrl.indexOf('datasets') + 1];
      } else {
        return splitUrl[0];
      }
    }
  }, [sourceDatasetIds, url, sourceType]);

  const sourceKey = revertCount;
  const hasPreviewErrors = !!previewErrors;

  const source = sourceData && !isEmpty(sourceData) ? sourceData : sampleData;

  if (!show) {
    return null;
  }

  return (
    <div className="editor__panel editor__panel--extra">
      <h3 className="head">Preview</h3>
      {sourceDataset && (
        <div className="editor__panel-top-bar">
          <DatasetNavigator
            datasetId={sourceDataset}
            onEntityFetched={onHandleEntityFetched}
            subset={subset}
          />
        </div>
      )}
      {!sourceDataset && sourceType === 'merge' && (
        <div className="editor__panel-top-bar">
          <MergeSourceNavigator
            pipeId={pipeId}
            pipeConfig={pipeObjectConfig}
            sinkDatasetId={sinkDatasetId}
            sourceDatasetIds={sourceDatasetIds}
            onEntityFetched={onHandleEntityFetched}
          />
        </div>
      )}
      {!sourceDataset && sourceType !== 'merge' && (
        <div className="editor__panel-top-bar">
          <EntityNavigator
            pipeId={pipeId}
            pipeConfig={pipeObjectConfig}
            sinkDatasetId={sinkDatasetId}
            sourceDatasetIds={sourceDatasetIds}
            onEntityFetched={onHandleEntityFetched}
            subUrl={subUrl}
            token={token}
          />
        </div>
      )}
      <div className="body">
        <div className="stack">
          <div className="stack__item stack__item--1x">
            {!outputReady && <LoadingPanel size="medium" loadingMessage="Running..." />}
            {!hasPreviewErrors && outputReady && (
              <JsonEditor
                key={sourceKey}
                onChange={onSourceChanged}
                onRun={onRun}
                rawJson={sortObject(source)}
                simpleFormat
              />
            )}
          </div>
          <div className="stack__item stack__item--1x">
            <EditorPanelDivider style={{ position: 'relative', height: '40px' }}>
              <ArrowDownward style={{ position: 'absolute', left: '50%' }} />
              <div
                style={{
                  position: 'absolute',
                  right: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: '7.5px',
                }}
              >
                <IconButton onClick={() => onRun()} aria-label="Re-run" size={'small'}>
                  <RefreshIcon />
                </IconButton>
                <SesamIconButtonPopover
                  IconButtonProps={{ size: 'small' }}
                  icon={<MoreVert />}
                  render={(toggle) => (
                    <List>
                      <ListItem
                        button
                        onClick={() => {
                          toggle();
                          onDebug(trace);
                        }}
                      >
                        Show DTL debugger
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          toggle();
                          toggleShowTransformed();
                        }}
                      >
                        {`${showTransformed ? 'Hide' : 'Show'} transformed`}
                      </ListItem>
                      {sinkDatasetId && (
                        <ListItem
                          button
                          onClick={() => {
                            const toPost = sortArray(transformed);
                            if (toPost.length === 0) {
                              post(sortArray(transformed));
                            } else {
                              post(sortArray(transformed)[0]);
                            }
                            toggle();
                          }}
                        >
                          Post to sink
                        </ListItem>
                      )}
                    </List>
                  )}
                />
              </div>
            </EditorPanelDivider>
            {hasPreviewErrors && (
              <div className="editor__panel-error">
                <NotAvailable title="Preview not available" message={previewErrors} />
              </div>
            )}
            {!hasPreviewErrors && !outputReady && (
              <LoadingPanel
                size="medium"
                loadingMessage={startingRun ? 'Running…' : 'Running… (indexing; please wait)'}
              />
            )}
            {!hasPreviewErrors && outputReady && showTransformed && (
              <JsonPanel rawJson={sortArray(transformed)} filter={false} alwaysUpdate />
            )}
            {!hasPreviewErrors && outputReady && !showTransformed && (
              <JsonPanel rawJson={sortArray(output)} filter={false} alwaysUpdate />
            )}
            {!hasPreviewErrors && outputInProgress && (
              <Feedback>Result not yet complete; please wait…</Feedback>
            )}
            {!hasPreviewErrors && outputStale && (
              <Feedback type="warning">Out-of-date; re-run to update</Feedback>
            )}
          </div>
        </div>
      </div>
      <PostEntityModal
        onClose={() => postReset()}
        onPostChange={setPost}
        onPostValidation={setPostValid}
        rawJson={postJson}
        sendPost={sendPost}
        showPost={postState.showPost}
        postMultiple={postState.postMultiple}
        isPostDisabled={postState.isPostDisabled}
        postValidationError={postState.postValidationError}
      />
    </div>
  );
};

PipePreviewPanel.propTypes = {
  onHandleEntityFetched: PropTypes.func.isRequired,
  onRevertSource: PropTypes.func,
  onRun: PropTypes.func.isRequired,
  onSourceChanged: PropTypes.func.isRequired,
  output: PropTypes.any,
  outputInProgress: PropTypes.bool,
  outputReady: PropTypes.bool,
  outputStale: PropTypes.bool,
  pipe: PropTypes.any,
  pipeId: PropTypes.string,
  pipeObjectConfig: PropTypes.shape({}),
  subset: PropTypes.shape([]),
  previewErrors: PropTypes.any,
  revertCount: PropTypes.number,
  sampleData: PropTypes.any,
  show: PropTypes.bool,
  sourceData: PropTypes.any,
  startingRun: PropTypes.bool,
  trace: PropTypes.any,
  transformed: PropTypes.any,
  onDebug: PropTypes.func,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

export default connect(mapStateToProps)(PipePreviewPanel);
