import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withTheme } from '@material-ui/core/styles';
import { getStrippedName } from './JsonEditor/JsonEditor';
import { useSubId } from '../../hooks/sesam';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { searchIsActiveSelector } from 'Redux/selectors';
import { withDynamicImports } from 'Components/withDynamicImports.hoc';
import './JsonPanel.css';

const CODESTYLE_KEY = 'sesam-editor-codestyle';

function isNi(str) {
  return str.startsWith('~:');
}

function isNamespacedProperty(str) {
  return str.includes(':');
}

function hasId(str) {
  try {
    const tokens = str.split(':');
    const key = tokens[0].trim().replaceAll('"', '');
    return key === '_id';
  } catch (e) {
    return false;
  }
}

function filterSystemAttrs(value) {
  const result = {};
  // TODO rewrite with filter (thanks eslint!)
  for (const attr in value) {
    if (!attr.startsWith('_')) {
      result[attr] = value[attr];
    }
  }
  return result;
}

function tracebackNewlineReplacer(key, value) {
  if (key === 'traceback') {
    if (typeof value === 'string') return value.split('\n');
  }

  return value;
}

function formatWithTracebacks(value) {
  return JSON.stringify(value, tracebackNewlineReplacer, 2);
}

function format(value) {
  return JSON.stringify(value, null, 2);
}

const getFontSize = () => {
  const codeStyleOverrides = JSON.parse(localStorage?.getItem(CODESTYLE_KEY) || '{}');
  return codeStyleOverrides?.font_size ?? 12;
};

/*
 * Panel that prettyprints JSON data
 */
function JsonPanel({
  rawJson,
  filter,
  wordWrap,
  imports,
  fitToContent,
  router,
  theme,
  formatTraceback,
}) {
  const editorRef = React.useRef();
  const containerRef = React.useRef();
  const subId = useSubId();
  const hasSearch = useSelector(searchIsActiveSelector);

  React.useLayoutEffect(() => {
    // create editor on mount, destroy on unmount
    editorRef.current = imports.brace.edit(containerRef.current);

    let Range = imports.brace.acequire('ace/range').Range;
    if (theme.palette.type === 'dark') {
      editorRef.current.setTheme('ace/theme/dracula');
    }
    editorRef.current.getSession().setMode('ace/mode/json');
    editorRef.current.getSession().setUseWrapMode(wordWrap);
    const options = {
      enableLinking: true,
      showPrintMargin: false,
      fontSize: getFontSize(),
    };
    if (fitToContent) {
      options.maxLines = Infinity;
    }
    editorRef.current.setOptions(options);
    editorRef.current.$blockScrolling = Infinity;
    editorRef.current.setReadOnly(true);

    editorRef.current.on('linkHover', (data, { session }) => {
      if (editorRef.current.markerId) session.removeMarker(editorRef.current.markerId);
      if (editorRef.current.markerLink) editorRef.current.markerLink = undefined;

      const tokenValue = get(data, 'token.value');
      const tokenType = get(data, 'token.type');
      const line = session.getLine(data.position.row);

      const range = new Range(
        data.position.row,
        data.token.start + 1,
        data.position.row,
        data.token.start + data.token.value.length - 1
      );

      const strippedValue = getStrippedName(tokenValue);

      if (isNi(strippedValue)) {
        if (hasSearch) {
          const markerId = session.addMarker(range, 'linkHighlight', 'text', true);
          editorRef.current.markerId = markerId;
          const niWithoutPrefix = strippedValue.slice(2);
          editorRef.current.markerLink = `/subscription/${subId}/browse/ni/${encodeURIComponent(
            niWithoutPrefix
          )}`;
        }
      } else if (tokenType === 'variable' && isNamespacedProperty(strippedValue)) {
        const markerId = session.addMarker(range, 'linkHighlight', 'text', true);
        editorRef.current.markerId = markerId;
        editorRef.current.markerLink = `/subscription/${subId}/browse/properties/${encodeURIComponent(
          strippedValue
        )}/overview`;
      } else if (hasId(line)) {
        const markerId = session.addMarker(range, 'linkHighlight', 'text', true);
        editorRef.current.markerId = markerId;
        editorRef.current.markerLink = `/subscription/${subId}/browse/ni/${encodeURIComponent(
          strippedValue
        )}`;
      }
    });
    editorRef.current.on('linkClick', () => {
      if (editorRef.current.markerLink) {
        router.push(editorRef.current.markerLink);
      }
    });

    return () => {
      editorRef.current.destroy();
    };
  }, []);

  React.useEffect(() => {
    // preserve cursor position
    const cursorPosition = editorRef.current.getCursorPosition();
    // preserve selected range
    const selection = editorRef.current.getSelection().getRange();
    const filterValue = (value) => (filter ? filterSystemAttrs(value) : value);
    if (formatTraceback) {
      editorRef.current.setValue(formatWithTracebacks(filterValue(rawJson)), -1);
    } else {
      editorRef.current.setValue(format(filterValue(rawJson)), -1);
    }
    editorRef.current.getSession().setUseWrapMode(wordWrap);
    editorRef.current.gotoLine(cursorPosition.row + 1, cursorPosition.column, false);
    editorRef.current.getSelection().setSelectionRange(selection);
  }, [rawJson, filter, wordWrap, formatTraceback]);

  React.useEffect(() => {
    if (editorRef.current) editorRef.current.resize();
  });

  React.useEffect(() => {
    if (theme.palette.type === 'dark') {
      editorRef.current.setTheme('ace/theme/dracula');
    } else {
      editorRef.current.setTheme(undefined);
    }
  }, [theme.palette.type]);

  return (
    <div className="jsonpanel">
      <div ref={containerRef} />
    </div>
  );
}

JsonPanel.propTypes = {
  rawJson: PropTypes.any,
  filter: PropTypes.bool,
  alwaysUpdate: PropTypes.bool,
  wordWrap: PropTypes.bool,
  fitToContent: PropTypes.bool,
  theme: PropTypes.shape({
    palette: PropTypes.shape({ type: PropTypes.string }),
  }),
};

JsonPanel.defaultProps = {
  alwaysUpdate: false,
  filter: false,
  wordWrap: false,
  fitToContent: false,
  rawJson: {},
};

const lazyImports = {
  brace: () => import('brace'),
  braceModeJson: () => import('brace/mode/json'),
  braceThemeDracula: () => import('brace/theme/dracula'),
  braceExtSearchbox: () => import('brace/ext/searchbox'),
  braceExtLinking: () => import('brace/ext/linking'),
};

export default compose(
  withDynamicImports(lazyImports),
  withRouter,
  withTheme
)(JsonPanel);
