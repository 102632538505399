import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';
import Facet from './SearchFacet';
import { SearchFacet } from 'Types/common.types';

interface SearchFacetListProps {
  activeFacets: SearchFacet[];
  facets: SearchFacet[];
  name: string;
  onClearAggregation: () => void;
  onFacetToggle: (facet: SearchFacet) => void;
}

const useStyle = makeStyles((theme) => ({
  box: { marginBottom: '25px' },
  icon: {
    position: 'relative',
    bottom: '2px',
    right: '2px',
    '&:hover, &:active': {
      color: theme.palette.primary.active,
      cursor: 'pointer',
    },
  },
}));

const SearchFacetList: React.FC<SearchFacetListProps> = ({
  activeFacets,
  facets,
  name,
  onClearAggregation,
  onFacetToggle,
}) => {
  const classes = useStyle();
  const nonEmptyFacets = facets.filter((f: SearchFacet) => f.count !== 0);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Box className={classes.box}>
      <Box.Header>
        <Box.Title>
          {collapsed && (
            <ExpandMoreIcon onClick={() => setCollapsed(false)} className={classes.icon} />
          )}
          {!collapsed && (
            <ExpandLessIcon onClick={() => setCollapsed(true)} className={classes.icon} />
          )}
          {capitalize(name)} ({nonEmptyFacets.length})
        </Box.Title>
        <Box.Actions>
          <Button size="small" onClick={() => onClearAggregation(name)}>
            Clear
          </Button>
        </Box.Actions>
      </Box.Header>
      <Box.Content white padding>
        {!collapsed &&
          nonEmptyFacets.map((facet: SearchFacet) => (
            <Facet
              key={facet.name}
              activeFacets={activeFacets}
              facet={facet}
              onFacetToggle={onFacetToggle}
            />
          ))}
      </Box.Content>
    </Box>
  );
};

export default SearchFacetList;
