import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import DashboardIcon from '../images/icons/dashboard.svg';
import MagnifyIcon from '../images/icons/magnify.svg';
import DatahubObjectsPalette, {
  DatahubObjectsPaletteId,
} from '../components/datahub-objects-palette';
import DatahubThunks from 'Redux/thunks/datahub';
import DatasetActions from 'Redux/thunks/datasets';
import Flows from 'Internals/flows';
import GraphIcon from '../images/icons/graph.svg';
import Layout from '../layout/full-sidebar';
import PipeActions from 'Redux/thunks/pipes';
import { CustomConnectionsThunk } from 'Redux/thunks/customConnectionURL.thunks';
import OverviewIcon from '../images/icons/overview.svg';
import PipeIcon from '../images/icons/pipe.svg';
import SettingsDatabrowserIcon from '../images/icons/settings-databrowser.svg';
import SettingsDatahubIcon from '../images/icons/settings-datahub.svg';
import SettingsSubscriptionIcon from '../images/icons/settings-subscription.svg';
import SubActions, { makeRoleCheckerForCurrentSub } from 'Redux/thunks/subscriptions';
import { SubscriptionAvatar } from '../components/subscription-avatar/SubscriptionAvatar';
import SystemActions from 'Redux/thunks/systems';
import SystemIcon from '../images/icons/system.svg';
import { commandPaletteToggled } from 'Redux/thunks/global';
import { findSub, findDefaultUrl } from 'Redux/thunks/subscriptions';
import { getDatabrowserURL } from '../subscription/pages/settings-databrowser/index';
import { registerKey, unregisterKey } from 'Internals/global-shortcuts';
import type { RootState } from 'Types/state.types';
import { SomeObject } from 'Types/common.types';
import ConnectionFailGuard from '../subscription/components/connected-guard/ConnectionFailGuard';
import useDelayedEffect from 'Hooks/useDelayedEffect';
import { useTryConnectViaURL } from 'Hooks/useTryConnectViaURL';

interface SubscriptionPageProps {
  params: SomeObject;
  router: SomeObject;
}

const CONNECTION_FAILED_TIMEOUT = 5000; // ms

export const SubscriptionContext = createContext({ subId: '' });

const SubscriptionPage: React.FC<SubscriptionPageProps> = (props) => {
  const { children, params, router } = props;
  const { subId } = params;

  useTryConnectViaURL(subId, router?.location?.query?.connection_url);

  const connected = useSelector((state: RootState) => state.subscription.connected);
  const datahubInfo = useSelector((state: RootState) => state.subscription.info);
  const showDatahubObjectsPalette = useSelector(
    (state: RootState) => state.globals.commandPaletteShown === DatahubObjectsPaletteId
  );
  const subIdFromProps = useSelector((state: RootState) => state.subscription.id);
  const sub = useSelector((state: RootState) =>
    findSub(state.subscriptions)(state.subscription.id)
  );

  const roleChecker = useSelector((state: RootState) => makeRoleCheckerForCurrentSub(state));
  const url = useSelector((state: RootState) => state.subscription.url);
  const customConnectionURL = useSelector((state: RootState) => state.customConnectionURLs[subId]);

  const dispatch = useDispatch();

  const closeCommandPalette = () => dispatch(commandPaletteToggled(undefined));
  const toggleDatahubObjectsPalette = () =>
    dispatch(commandPaletteToggled(DatahubObjectsPaletteId));

  useEffect(() => {
    dispatch(CustomConnectionsThunk.setAllCustomConnections());
  }, [CustomConnectionsThunk.setAllCustomConnections, dispatch]);

  useEffect(() => {
    if (sub === undefined && subIdFromProps !== '') {
      // Current user has left, or deleted the subscription.
      router.push('/dashboard');
    }
  }, [router, sub, subIdFromProps]);

  useEffect(() => {
    function toggleObjectsPalette(ev: React.ChangeEvent) {
      ev.preventDefault();
      toggleDatahubObjectsPalette();
    }

    registerKey(' ', toggleObjectsPalette, { ctrlKey: true });

    return () => {
      unregisterKey(' ', { ctrlKey: true });
    };
  });

  const provisioningStatus = useMemo(() => {
    if (sub !== undefined) {
      return sub.provisioning_status;
    } else {
      return undefined;
    }
  }, [sub]);

  const loadAllObjects = useCallback(() => {
    return Promise.all([
      dispatch(DatasetActions.loadAll()),
      dispatch(PipeActions.loadAll()),
      dispatch(SystemActions.loadAll()),
    ]);
  }, []);

  useEffect(() => {
    if (connected === true && sub !== undefined) {
      loadAllObjects();
    }
  }, [connected, loadAllObjects, provisioningStatus, sub]);

  useEffect(() => {
    async function connectSub() {
      await dispatch(SubActions.loadAll());
      await dispatch(SubActions.connect(subId));
      await dispatch(DatahubThunks.getInfo());
      await loadAllObjects();
    }

    if (subId !== undefined && connected === false) {
      connectSub();
    }
  }, [connected, loadAllObjects, subId]);

  useEffect(() => {
    function disconnect() {
      dispatch(SubActions.disconnect(true));
      dispatch(DatahubThunks.clearInfo());
      Flows.clear();
    }

    router.setRouteLeaveHook(router.routes[1], disconnect);
  }, []);

  const [showConnectionFailPanel, setShowConnectionFailPanel] = useState(false);

  useDelayedEffect(
    () => {}, // noop as we don't need to do anything here
    () => setShowConnectionFailPanel(true),
    CONNECTION_FAILED_TIMEOUT,
    sub
  );

  let content = null;
  if (showConnectionFailPanel) {
    content = <ConnectionFailGuard />;
  } else if (sub !== undefined) {
    content = children;
  }

  let topElement = null;

  const buildDate = get(datahubInfo, ['status', 'build-date']);
  const isOutdated = useMemo(() => {
    if (buildDate) {
      const diff = new Date() - new Date(buildDate);
      const diffInDays = Math.round(diff / (1000 * 60 * 60 * 24));
      return diffInDays > 20;
    }
  }, [buildDate]);

  if (sub) {
    topElement = (
      <SubscriptionAvatar
        connectionUrl={url}
        customConnectionURL={customConnectionURL}
        name={sub.name}
        subId={sub.id}
        isDefaultUrl={findDefaultUrl(sub) === url}
        nowrap
        shouldDisplayRestoreLinkButton={true}
      />
    );
  }

  const settingsSubMenu = [
    {
      to: `/subscription/${subId}/settings-datahub`,
      title: 'Datahub',
      icon: <SettingsDatahubIcon />,
      disabled: !connected,
    },
    {
      to: `/subscription/${subId}/settings-subscription`,
      title: 'Subscription',
      icon: <SettingsSubscriptionIcon />,
    },
  ];

  if (sub) {
    const databrowserListFromProducts = sub.products.databrowser;
    if (databrowserListFromProducts) {
      for (let i = 0; i < databrowserListFromProducts.length; i += 1) {
        const databrowserFromProducts = databrowserListFromProducts[i];
        const databrowserURL = getDatabrowserURL(sub, databrowserFromProducts.id);
        const disabled = !databrowserURL;
        settingsSubMenu.push({
          to: `/subscription/${subId}/settings-databrowser/${databrowserFromProducts.id}`,
          title: databrowserFromProducts.name || databrowserFromProducts.id,
          icon: <SettingsDatabrowserIcon />,
          disabled,
        });
      }
    }
  }

  settingsSubMenu.label = 'Settings';

  const gdprMenuItem = {
    to: `/subscription/${subId}/gdpr`,
    title: 'GDPR',
    icon: <DashboardIcon />,
    disabled: !connected,
  };

  const browseItem = {
    to: `/subscription/${subId}/browse`,
    title: 'Browse',
    icon: <MagnifyIcon />,
    disabled: !connected,
  };

  const navLinks = [
    {
      to: `/subscription/${subId}/overview`,
      title: 'Overview',
      icon: <OverviewIcon />,
      disabled: !connected,
    },
    {
      to: `/subscription/${subId}/pipes`,
      title: 'Pipes',
      icon: <PipeIcon />,
      disabled: !connected,
    },
    {
      to: `/subscription/${subId}/systems`,
      title: 'Systems',
      icon: <SystemIcon />,
      disabled: !connected,
    },
    {
      to: `/subscription/${subId}/flows`,
      title: 'Flows',
      icon: <GraphIcon />,
      disabled: !connected,
      new: true,
    },
  ];

  // TODO: get rid of the 'indexOf' check once we have added the "gdpr_platform"
  // property to all relevant subscriptions.
  if (sub && (sub.name.toLowerCase().indexOf('gdpr') !== -1 || sub.products.gdpr_platform)) {
    if (roleChecker('group:Admin') || roleChecker('group:GDPR_operations')) {
      navLinks.push(gdprMenuItem);
    }
  }

  navLinks.splice(1, 0, browseItem);
  navLinks.push(settingsSubMenu);

  return (
    <SubscriptionContext.Provider value={subId}>
      <Layout navLinks={navLinks} topElement={topElement} location={location}>
        {content}
        {showDatahubObjectsPalette && (
          <DatahubObjectsPalette onRequestClose={closeCommandPalette} />
        )}
      </Layout>
    </SubscriptionContext.Provider>
  );
};

const Wrapped = withRouter(SubscriptionPage);

export { Wrapped as SubscriptionPage };
