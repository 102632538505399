import get from 'lodash/get';
import produce from 'immer';
import { createReducer } from '@reduxjs/toolkit';

import { isDurable, isGlobal, isTenant } from 'Internals/pipes';
import type { Pipe, PipeResponse, PipeMap } from 'Types/pipes.types';
import { disconnected } from '../thunks/subscriptions';
import {
  pipeAdded,
  pipeUpdated,
  pipeRuntimeUpdated,
  pipeLoaded,
  pipesLoadAllFinished,
  pipesLoadAllFailed,
  pipeRemoved,
  actionStatusSet,
  pipePartiallyLoaded,
} from '../thunks/pipes';

// -----------------------------------------------------------------------------

const isInternalPipeRegex = /^(system:|configure_node:|load_nodeconfig:|merge_nodeconfig:)/;

function getDirection(p) {
  // direction already in pipe metadata (from multinode script)
  const metadataDirection = get(p, 'config.original.metadata.direction');
  if (metadataDirection) return metadataDirection;

  // otherwise we use the old way (not multinode)
  const datasetSink = get(p, 'config.effective.sink.type') === 'dataset';
  const datasetSource = [
    'dataset',
    'merge',
    'union_datasets',
    'diff_datasets',
    'merge_datasets',
  ].includes(get(p, 'config.effective.source.type'));
  if (datasetSink && datasetSource) {
    return 'internal';
  }
  if (datasetSink) {
    return 'inbound';
  }
  if (datasetSource) {
    return 'outbound';
  }
  return 'direct';
}

export function decoratePipe(rawPipe: PipeResponse) {
  return <Pipe>produce(rawPipe, (pipe) => {
    const id = pipe._id;
    pipe.direction = getDirection(pipe);
    pipe.link = encodeURIComponent(id);
    pipe.origin = pipe.runtime.origin;
    if (pipe.origin == null) {
      // fallback until all nodes expose this
      pipe.origin = isInternalPipeRegex.test(id) ? 'system' : 'user';
    }
    pipe.isInternal = pipe.origin !== 'user';
    pipe.name = get(rawPipe, 'config.original.name', id); // sesam 2 doesn't have `original` section
    pipe.actionStatus = { status: null, errors: [] };
    pipe['config-warnings'] = get(rawPipe, 'runtime["config-errors"]', []).filter(
      (i) => i.level !== 'info'
    );
    pipe.pumpMode = get(rawPipe, 'config.effective.pump.mode', 'scheduled');
    pipe.tags = [];
    const userTags = get(rawPipe, 'config.original.metadata.tags');
    if (userTags) {
      if (Array.isArray(userTags)) {
        pipe.tags = pipe.tags.concat(userTags);
      } else {
        pipe.tags.push(userTags);
      }
    }
    pipe.global = isGlobal(rawPipe) ? 'Yes' : 'No';
    pipe.durable = isDurable(rawPipe) ? 'Yes' : 'No';
    pipe.tenant = isTenant(rawPipe) ? isTenant(rawPipe) : 'Not Tenant';
    pipe.lastRunStatus = pipe.runtime.success ? 'Succeeded' : 'Failed';
    if (rawPipe.config.effective.metadata && rawPipe.config.effective.metadata['$config-group']) {
      pipe.configGroup = rawPipe.config.effective.metadata['$config-group'];
    } else {
      pipe.configGroup = 'Default';
    }

    let transforms = get(pipe, 'config.effective.transform.transforms', []);
    if (transforms && !transforms.length) {
      transforms = [{ ...transforms }];
    }
    for (const transform of transforms) {
      const type = get(transform, 'type');
      const system = get(transform, 'system');
      if (type === 'rest') {
        pipe.transforms = system;
      }
    }
  });
}

type State = PipeMap;
const initialState: State = {};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(pipeAdded, (state, action) => {
      state[action.payload._id] = decoratePipe(action.payload);
    })
    .addCase(pipeUpdated, (state, action) => {
      state[action.payload.pipe._id] = decoratePipe(action.payload.pipe);
    })
    .addCase(pipeRuntimeUpdated, (state, action) => {
      if (state[action.payload.id]) {
        state[action.payload.id].runtime = action.payload.runtime;
      }
    })
    .addCase(pipeLoaded, (state, action) => {
      state[action.payload.pipe._id] = decoratePipe(action.payload.pipe);
    })
    .addCase(pipePartiallyLoaded, (state, action) => {
      let types = [];
      if (action.payload.pipe.config) types.push('config');
      if (action.payload.pipe.runtime) types.push('runtime');
      if (action.payload.pipe.storage) types.push('storage');
      if (action.payload.pipe.graph) types.push('graph');
      for (const type of types) {
        state[action.payload.id][type] = action.payload.pipe[type];
      }
    })
    .addCase(pipesLoadAllFinished, (state, action) => {
      let newState: State = {};
      for (let i = 0; i < action.payload.length; i++) {
        newState[action.payload[i]._id] = decoratePipe(action.payload[i]);
      }
      return newState;
    })
    .addCase(pipesLoadAllFailed, () => initialState)
    .addCase(pipeRemoved, (state, action) => {
      delete state[action.payload.id];
    })
    .addCase(actionStatusSet, (state, action) => {
      // TODO: What is "action status" for? How is it different from `runtime.status`?
      if (state[action.payload.id]) {
        state[action.payload.id].actionStatus.status = action.payload.state;
      }
    })
);

export default reducer;
