import React from 'react';
import * as yup from 'yup';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { Formik, Form } from 'formik';
import Typography from 'Common/SesamTypography';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Button from 'Common/Button/Button';
import SesamRadioGroupField from 'Common/SesamRadioGroupField/SesamRadioGroupField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { useSelector } from 'react-redux';
import type { RootState } from 'Types/state.types';
import { TestID } from '../../../testID';
import { PaymentMethodInfo, PaymentMethodValues } from 'Types/paymentMethod.types';

type PaymentMethodEditorProps = {
  buttonText: string;
  onSave: (update: {}) => void;
  showError: (msg: string | null) => void;
  stripe: {
    createToken: (account: { name: string }) => {
      token: {
        card: { brand: string; last4: number; name: string };
        id: string;
      };
    };
  };
  update: boolean;
  value: PaymentMethodInfo;
  isPortalAdmin: boolean;
};

const useStyle = makeStyles((theme) => {
  return {
    cardHelperText: {
      marginLeft: '14px',
      marginTop: '-25px',
    },
    cardInput: {
      border: '1px solid #c4c4c4',
      height: '40px',
      padding: '10px 14px',
      borderRadius: '4px',
      marginBottom: '29px',
    },
    cardInputError: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    radioButtons: {
      marginBottom: '16px',
    },
    submitButton: {
      float: 'right',
    },
  };
});

const PaymentMethodEditor: React.FC<PaymentMethodEditorProps> = ({
  buttonText,
  onSave,
  showError,
  update,
  value,
  isPortalAdmin,
}) => {
  const darkModeActive = useSelector((state: RootState) => state.theme.dark);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyle();

  const validationSchema = yup.object().shape({
    billingInfoAddress1: yup.string().when('type', {
      is: 'invoice',
      then: yup.string().required('Required - This field is required'),
    }),
    billingInfoName: yup.string().when('type', {
      is: 'invoice',
      then: yup.string().required('Required - This field is required'),
    }),
    billingInfoPhone: yup.number('Required - Must be a number').when('type', {
      is: 'invoice',
      then: yup.number().required('Required - This field is required'),
    }),
    card: yup.boolean().when(['type'], {
      is: (type: 'invoice' | 'card') => type === 'card' && !update,
      then: yup.boolean().oneOf([true], 'Required - This field is required'),
    }),
    name: yup.string().required('Required - This field is required'),
    type: yup.string().oneOf(['invoice', 'card']),
  });

  const radioButtons = [{ value: 'invoice', label: 'Invoice' }];
  if (isPortalAdmin) {
    radioButtons.push({ value: 'card', label: 'Card' });
  }

  return (
    <Formik
      initialValues={{
        billingInfoAddress1: value?.billing_info?.address1 || '',
        billingInfoAddress2: value?.billing_info?.address2 || '',
        billingInfoAddress3: value?.billing_info?.address3 || '',
        billingInfoCardholderName: '',
        billingInfoName: value?.billing_info?.name || '',
        billingInfoOrgNr: value?.billing_info?.orgnr || '',
        billingInfoPhone: value?.billing_info?.phone || '',
        card: false,
        description: value?.description || '',
        name: value?.name || '',
        type: value?.type || 'invoice',
      }}
      isInitialValid={false}
      onSubmit={(values: PaymentMethodValues) => {
        async function createCardPaymentMethod(_values: PaymentMethodValues) {
          const cardElement = elements?.getElement(CardElement);
          let result;
          try {
            result = await stripe?.createToken(cardElement);
          } catch (e) {
            console.log('stripe error', e);
          }

          if (result && result.error && showError) {
            const msg = get(result, 'error.message', null);
            showError(msg);
          } else if (result && result.token) {
            const _update = {
              billing_info: {
                brand: result.token.card.brand,
                last4: result.token.card.last4,
                name: result.token.card.name,
              },
              card_token: result.token.id,
              description: _values.description,
              name: _values.name,
              type: 'card',
            };

            onSave(_update);
          }
        }

        function createInvoicePaymentMethod(values: PaymentMethodValues) {
          const infoToUpdate: PaymentMethodInfo = {
            name: values.name,
            description: values.description,
            billing_info: {
              orgnr: values.billingInfoOrgNr,
              name: values.billingInfoName,
              phone: values.billingInfoPhone,
              address1: values.billingInfoAddress1,
              address2: values.billingInfoAddress2,
              address3: values.billingInfoAddress3,
            },
            type: values.type,
          };

          onSave(infoToUpdate);
        }

        if (values.type === 'card' && update) {
          const updatedObj = cloneDeep(value);
          updatedObj.name = values.name;
          updatedObj.description = values.description;
          onSave(updatedObj);
        } else {
          if (values.type === 'card' && !update) {
            createCardPaymentMethod(values);
          } else {
            createInvoicePaymentMethod(values);
          }
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <Form>
            <Typography variant="h6">Payment method details</Typography>
            <SesamTextField
              error={Boolean(errors.name)}
              helperText={
                errors.name || 'Required - A name that helps you identify the payment method'
              }
              id="name"
              placeholder="Company/Project"
              inputProps={{ 'data-selenium': 'payment-name', autoFocus: true }}
              label="Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              margin="normal"
              inputTestid={TestID.PaymentMethodInputName}
            />
            <SesamTextField
              helperText=" "
              id="description"
              label="Description"
              onChange={handleChange}
              value={values.description}
              margin="normal"
              inputTestid={TestID.PaymentMethodInputDescription}
            />
            {!update && (
              <div>
                <Typography variant="h6">Payment type</Typography>
                <SesamRadioGroupField
                  className={classes.radioButtons}
                  name="type"
                  onChange={handleChange}
                  radios={radioButtons}
                  row
                  value={values.type}
                  margin="normal"
                  data-testid={TestID.PaymentMethodTypeRadioGroup}
                />
              </div>
            )}
            {values.type === 'invoice' && (
              <div>
                <Typography variant="h6">Invoice details</Typography>
                <SesamTextField
                  helperText=" "
                  id="billingInfoOrgNr"
                  label="Company number"
                  onChange={handleChange}
                  value={values.billingInfoOrgNr}
                  margin="normal"
                  inputTestid={TestID.PaymentMethodInputCompanyNumber}
                />
                <SesamTextField
                  error={touched.billingInfoName && Boolean(errors.billingInfoName)}
                  helperText={
                    (touched.billingInfoName && errors.billingInfoName) ||
                    'Required - The name of the person responsible for the invoice'
                  }
                  id="billingInfoName"
                  inputProps={{ 'data-selenium': 'payment-responsible-name' }}
                  label="Name of responsible"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.billingInfoName}
                  margin="normal"
                  inputTestid={TestID.PaymentMethodInputNameOfResponsible}
                />
                <SesamTextField
                  error={touched.billingInfoPhone && Boolean(errors.billingInfoPhone)}
                  helperText={
                    (touched.billingInfoPhone && errors.billingInfoPhone) ||
                    'Required - A phone number where the person responsible can be reached'
                  }
                  id="billingInfoPhone"
                  inputProps={{
                    'data-selenium': 'payment-phone',
                  }}
                  label="Phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.billingInfoPhone}
                  margin="normal"
                  inputTestid={TestID.PaymentMethodInputPhoneNumber}
                />
                <SesamTextField
                  error={touched.billingInfoAddress1 && Boolean(errors.billingInfoAddress1)}
                  helperText={
                    (touched.billingInfoAddress1 && errors.billingInfoAddress1) ||
                    'Required - The address where to send the invoice'
                  }
                  id="billingInfoAddress1"
                  inputProps={{ 'data-selenium': 'payment-address1' }}
                  label="Address line 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.billingInfoAddress1}
                  margin="normal"
                  inputTestid={TestID.PaymentMethodInputAddress1}
                />
                <SesamTextField
                  helperText=" "
                  id="billingInfoAddress2"
                  label="Address line 2"
                  onChange={handleChange}
                  value={values.billingInfoAddress2}
                  margin="normal"
                />
                <SesamTextField
                  helperText=" "
                  id="billingInfoAddress3"
                  label="Address line 3"
                  onChange={handleChange}
                  value={values.billingInfoAddress3}
                  margin="normal"
                />
              </div>
            )}
            {values.type === 'card' && !update && (
              <div>
                <Typography variant="h6">Card details</Typography>
                <SesamTextField
                  helperText=" "
                  id="billingInfoCardholderName"
                  label="Cardholders name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.billingInfoCardholderName}
                  margin="normal"
                />
                <CardElement
                  className={classes.cardInput}
                  onBlur={() => {
                    setFieldTouched('card', true);
                  }}
                  onChange={(ev: {
                    complete: boolean;
                    empty: boolean;
                    error: {
                      message: string;
                    };
                  }) => {
                    if (ev.complete) {
                      setFieldValue('card', true);
                    } else {
                      setFieldValue('card', false);
                    }
                  }}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        fontWeight: '400',
                        fontFamily: 'Barlow, sans-serif',
                        color: darkModeActive ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
                        '::placeholder': {
                          color: darkModeActive
                            ? 'rgba(255, 255, 255, 0.7)'
                            : 'rgba(0, 0, 0, 0.54)',
                        },
                      },
                    },
                    hidePostalCode: true,
                  }}
                />
                <FormHelperText
                  className={classes.cardHelperText}
                  error={touched.card && Boolean(errors.card)}
                >
                  {(touched.card && errors.card) || 'Required - Card information'}
                </FormHelperText>
              </div>
            )}
            <Button
              data-selenium="payment-submit"
              disabled={isSubmitting || !isValid}
              className={classes.submitButton}
              theme="primary"
              type="submit"
              data-testid={TestID.PaymentMethodFormAddButton}
            >
              {buttonText}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentMethodEditor;
