import SesamLink from 'Common/Links/SesamLink';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import get from 'lodash/get';

import Button from 'Common/Button/Button';
import CallToActionCard from '../../components/call-to-action-card';
import CardListDataTable from '../../components/card-list-data-table';
import Identicon from '../../components/identicon/Identicon';
import Layout from '../../layout/full-sidebar';
import Page from '../../components/page';
import PageHeader, { PageHeaderTitle } from '../../components/page-header';
import SubActions from 'Redux/thunks/subscriptions';
import SubscriptionButtons from '../../components/subscription-buttons';
import { SubscriptionCard } from '../../components/subscription-card/SubscriptionCard';
import { findDefaultUrl } from 'Redux/thunks/subscriptions';
import { toastAdded } from 'Redux/thunks/global';
import type { Subscription } from 'Types/subscription.types';
import type { License } from 'Types/subscription.types';
import type { RootState } from 'Types/state.types';
import type { SomeObject } from 'Types/common.types';
import { CustomConnectionsThunk } from 'Redux/thunks/customConnectionURL.thunks';
import { TestID } from '../../testID';

export const DashboardPage = () => {
  function createdSorter(col: SomeObject, row1: SomeObject, row2: SomeObject) {
    const r1 = get(row1, ['sub', 'created']);
    const r2 = get(row2, ['sub', 'created']);

    return new Date(r1) - new Date(r2);
  }

  function getDescription(dataItem: SomeObject) {
    return dataItem.description || (dataItem.sub && dataItem.sub.description);
  }

  function getIcon(dataItem: SomeObject) {
    return dataItem.sub && <Identicon input={dataItem.sub.name} size={24} />;
  }

  function getLicenseExpiry(dataItem: SomeObject) {
    if (dataItem.licenses) {
      return dataItem.licenses.find((license: License) => license['is-active-license']).original
        .expires;
    }
    return '';
  }

  function getSubActions(dataItem: SomeObject) {
    return dataItem.actions || <SubscriptionButtons sub={dataItem.sub} />;
  }

  function getSubName(dataItem: SomeObject) {
    if (dataItem.sub && dataItem.sub.id) {
      return (
        <SesamLink
          title={findDefaultUrl(dataItem.sub) + '\n' + dataItem.sub.name}
          to={`/subscription/${dataItem.sub.id}`}
        >
          {dataItem.sub.name}
        </SesamLink>
      );
    }
    return dataItem.name;
  }

  function subSorter(_col, row1, row2) {
    const name1 = row1.sub ? row1.sub.name : row1.name;
    const name2 = row2.sub ? row2.sub.name : row2.name;

    if (name1 > name2) {
      return 1;
    } else if (name1 < name2) {
      return -1;
    }

    return 0;
  }

  const [emailVerificationNotified, setEmailVerificationNotified] = useState(false);

  const subscriptions = useSelector((state: RootState) => state.subscriptions);
  const userSupportedOperations = useSelector((state: RootState) => state.user.supportedOperations);
  const lastCreateTrialRequest = useSelector(
    (state: RootState) => state.user.lastCreateTrialRequest
  );
  const customConnectionURLs = useSelector((state: RootState) => state.customConnectionURLs);
  const emailVerified = useSelector((state: RootState) => state.user.emailVerified);

  const dispatch = useDispatch();
  const reloadSubs = () => dispatch(SubActions.loadAll());
  const isItestMode = useSelector((state: RootState) => state.globals.status.is_itest_mode);
  const cancelRequestTrial = () => dispatch(SubActions.cancelRequestTrial());
  const dismissDeniedRequestTrial = () => dispatch(SubActions.dismissDeniedRequestTrial());
  const addToast = (message: string) => dispatch(toastAdded({ message, type: 'warning' }));

  useEffect(() => {
    dispatch(CustomConnectionsThunk.setAllCustomConnections());
  }, [CustomConnectionsThunk.setAllCustomConnections, dispatch]);

  useEffect(() => {
    reloadSubs();
  }, [reloadSubs]);

  useEffect(() => {
    if (emailVerified === false && !emailVerificationNotified && !isItestMode) {
      addToast(
        'Your email is not verified. We have sent a verification email to your email address. To resend the verification email, go to your user profile'
      );
      setEmailVerificationNotified(true);
    }
  }, [addToast, emailVerified, emailVerificationNotified, isItestMode]);

  const pinnedItems = useMemo(() => {
    const userCanRequestTrialSubscription =
      userSupportedOperations.request_trial_subscription &&
      userSupportedOperations.request_trial_subscription.enabled;

    const pinned = [
      {
        id: 'new-sub',
        cardRenderer: CallToActionCard,
        name: 'New subscription',
        tagline: 'On-cloud or on-premise',
        description:
          'Create a fully-configurable, ongoing Sesam subscription. You need a verified payment method.',
        actions: [
          <SesamLink
            data-selenium="create-subscription"
            key="create-sub"
            to="/create-subscription"
            buttonLink={true}
          >
            <Button theme="primary">New subscription</Button>
          </SesamLink>,
        ],
        asideActions: [
          <SesamLink key="payment" to="/user/payment-methods">
            Payment methods
          </SesamLink>,
        ],
      },
    ];

    if (userCanRequestTrialSubscription) {
      pinned.push({
        id: 'new-request',
        cardRenderer: CallToActionCard,
        name: 'Request private trial',
        tagline: 'Cloud only',
        description:
          'Request a Sesam trial subscription, free for a month. Upgrade whenever you want.',
        actions: [
          <SesamLink key="request-trial" to="/request-trial" buttonLink={true}>
            <Button theme="primary">Request private trial</Button>
          </SesamLink>,
        ],
      });
    }

    if (
      lastCreateTrialRequest &&
      lastCreateTrialRequest.status === 'pending' &&
      !userCanRequestTrialSubscription
    ) {
      pinned.push({
        id: 'pending-request',
        cardRenderer: CallToActionCard,
        name: 'Pending private trial request',
        tagline: 'Cloud only',
        description: `You made a trial subscription request at
          ${moment(lastCreateTrialRequest.created_time).format('MMMM Do YYYY, h:mm:ss a')}.
          When the request is granted your trial subscription will appear here.`,
        actions: [
          <Button
            data-selenium="request-trial"
            key="cancel-request"
            type="submit"
            onClick={cancelRequestTrial}
            theme="danger"
          >
            Cancel request
          </Button>,
        ],
      });
    }

    if (
      lastCreateTrialRequest &&
      lastCreateTrialRequest.status === 'denied' &&
      !lastCreateTrialRequest.dismissed_from_gui &&
      !userCanRequestTrialSubscription
    ) {
      pinned.push({
        id: 'denied-request',
        cardRenderer: CallToActionCard,
        name: 'Denied private trial request',
        tagline: 'Cloud only',
        description: `Your trial subscription request was denied at
          ${moment(lastCreateTrialRequest.responded_time).format('MMMM Do YYYY, h:mm:ss a')}.
          ${lastCreateTrialRequest.response}`,
        actions: [
          <Button
            data-selenium="request-trial"
            key="dismiss-denial"
            onClick={dismissDeniedRequestTrial}
            title="Click this button to stop displaying this message on the dashboard."
            type="submit"
          >
            Dismiss
          </Button>,
        ],
      });
    }

    return pinned;
  }, [
    cancelRequestTrial,
    dismissDeniedRequestTrial,
    lastCreateTrialRequest,
    userSupportedOperations.request_trial_subscription,
  ]);

  const data = pinnedItems.concat(
    subscriptions.map((sub: Subscription) => {
      return {
        cardRenderer: SubscriptionCard,
        id: sub.id,
        sub,
        customConnectionURL: customConnectionURLs[sub.id],
        testid: `${TestID.DashboardSubscriptionCard}-${sub.id}`,
      };
    })
  );

  return (
    <Layout>
      <Page>
        <PageHeader>
          <PageHeaderTitle>Dashboard</PageHeaderTitle>
        </PageHeader>
        <main className="scrollArea">
          <CardListDataTable
            cols={[
              { header: '', type: 'icon', data: getIcon, sorter: null },
              {
                header: 'Subscription',
                data: getSubName,
                sorter: subSorter,
                type: 'medium-text',
              },
              {
                header: 'Description',
                data: getDescription,
                type: 'long-text',
              },
              {
                header: 'Actions',
                type: 'actions',
                data: getSubActions,
                sorter: null,
              },
            ]}
            data={data}
            dataKey="id"
            fields={[
              {
                header: 'Created date',
                data: 'created',
                sorter: createdSorter,
              },
              { header: 'License expiry', data: getLicenseExpiry },
            ]}
            id="dashboard"
            pinnedIds={pinnedItems.map((pin) => pin.id)}
            sortable
            defaultSortByKey="Subscription"
          />
        </main>
      </Page>
    </Layout>
  );
};
