import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import { User } from 'Types/common.types';
import type { RootState } from 'Types/state.types';
import ConfirmDeleteAccount from '../components/confirm-delete-account';
import ProfileForm from './profile-form';
import RequestVerifyEmailForm from './request-verify-email-form';
import Actions from 'Redux/thunks/user-profile';
import UserAuthProviders from './UserAuthProviders';
import HighlightText from 'Common/Text/HighlightText';
import DangerText from 'Common/Text/DangerText';
import SesamLink from 'Common/Links/SesamLink';

const useStyle = makeStyles((theme) => {
  return {
    danger: {
      color: theme.palette.text.danger,
    },
  };
});

const SESAM_PORTAL_AUTH_PROVIDER_ID = 'sesam-portal-auth0';
const SESAM_PORTAL_INTERNAL_PROVIDER_ID = 'sesam-portal-internal';

function hasMultipleProviderIds(user: User) {
  if (!user) return false;
  const providerIds = user.providerIds;
  if (!providerIds || providerIds.length === 1) return false;
  else return true;
}

function hasInternalProvider(user: User) {
  if (!user || !user.providerIds || !Array.isArray(user.providerIds)) return false;
  if (user.providerIds.includes(SESAM_PORTAL_INTERNAL_PROVIDER_ID)) return true;
  if (user.providerIds.includes(SESAM_PORTAL_AUTH_PROVIDER_ID)) return true;
  return false;
}

type Props = {
  router: {
    push: (route: string) => void;
  };
};

const Profile: React.FC<Props> = (props) => {
  const { router } = props;
  const styles = useStyle();

  const dispatch = useDispatch();
  const onDelete = (userId: string) => dispatch(Actions.deleteAccount(userId));

  const user = useSelector((state: RootState) => {
    return state.user;
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const confirmOnDelete = () => {
    setShowDeleteConfirm(true);
  };

  const cancelOnDelete = () => {
    setShowDeleteConfirm(false);
  };

  const approveOnDelete = () => {
    return onDelete(user.user_id)
      .then(() => setShowDeleteConfirm(false))
      .then(() => router.push('/'));
  };

  return (
    <main className="scrollArea">
      <div className="row">
        <div className="col gr-primary">
          <h2 className="heading-section">User profile</h2>
          <ProfileForm />
          <RequestVerifyEmailForm />
          {hasInternalProvider(user) && (
            <div style={{ marginTop: '30px' }}>
              <SesamLink to={`/user/profile/change-password`} buttonLink={true}>
                <Button theme="default">Change password</Button>
              </SesamLink>
            </div>
          )}
          {hasMultipleProviderIds(user) && <UserAuthProviders />}
        </div>
        <div className="col gr-secondary">
          <h2 className={`heading-section ${styles.danger}`}>Danger area</h2>
          <Form component="div" standout>
            <h3 className="heading-component">
              <DangerText>Delete account</DangerText>
            </h3>
            <p>This will delete your user account.</p>
            {!user.supportedOperations.delete_account.enabled && (
              <HighlightText>{user.supportedOperations.delete_account['reason-msg']}</HighlightText>
            )}
            <FormActions>
              <Button
                disabled={!user.supportedOperations.delete_account.enabled}
                onClick={confirmOnDelete}
                theme="danger"
              >
                Delete user account…
              </Button>
            </FormActions>

            {showDeleteConfirm && (
              <ConfirmDeleteAccount
                onCancel={cancelOnDelete}
                onApprove={approveOnDelete}
                user={user}
              />
            )}
          </Form>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = (dispatch: (action: {}) => void) => ({
  onDelete: (userId: string) => dispatch(Actions.deleteAccount(userId)),
});
export default connect(null, mapDispatchToProps)(Profile);
