import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import { Optional, SomeObject } from 'Types/common.types';
import { createReducer } from '@reduxjs/toolkit';
import { subSwitched } from '../thunks/subscriptions';
import {
  datatypeAdded,
  datatypeDeleted,
  datatypeUpdated,
  datatypeUpdatedOrAdded,
  datatypesLoaded,
  datatypesSetSince,
  policyAdded,
  policyDeleted,
  policyUpdated,
  policyUpdatedOrAdded,
  policiesLoaded,
  policiesSetSince,
  purposeAdded,
  purposeDeleted,
  purposeUpdatedOrAdded,
  purposesLoaded,
  purposesSetSince,
  purposeUpdated,
  datatypesUpdated,
} from '../thunks/gdpr';

type GDPRState = {
  purposes: {
    since: Optional<number>;
    data: SomeObject[];
  };
  datatypes: {
    since: Optional<number>;
    data: SomeObject[];
  };
  policies: {
    since: Optional<number>;
    data: SomeObject[];
  };
};

const initialState: GDPRState = {
  purposes: {
    since: null,
    data: [],
  },
  datatypes: {
    since: null,
    data: [],
  },
  policies: {
    since: null,
    data: [],
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(subSwitched, () => {
      return initialState;
    })
    // Data types
    .addCase(datatypeAdded, (state, action) => {
      state.datatypes.data.push(action.payload);
    })
    .addCase(datatypeDeleted, (state, action) => {
      remove(state.datatypes.data, (datatype) => datatype['data-type-id'] === action.payload);
    })
    .addCase(datatypeUpdated, (state, action) => {
      const existingDatatypes = state.datatypes.data;
      const incomingDatatype = action.payload;
      const idx = findIndex(
        existingDatatypes,
        (existingDatatype) => incomingDatatype['data-type-id'] === existingDatatype['data-type-id']
      );
      state.datatypes.data[idx] = incomingDatatype;
    })
    .addCase(datatypesUpdated, (state, action) => {
      const existingDatatypes = state.datatypes.data;
      const incomingDatatypes = action.payload;
      incomingDatatypes.forEach((incomingDatatype) => {
        const idx = findIndex(
          existingDatatypes,
          (existingDatatype) =>
            incomingDatatype['data-type-id'] === existingDatatype['data-type-id']
        );
        state.datatypes.data[idx] = incomingDatatype;
      });
    })
    .addCase(datatypeUpdatedOrAdded, (state, action) => {
      const existingDatatypes = state.datatypes.data;
      const incomingDatatype = action.payload;
      const idx = findIndex(
        existingDatatypes,
        (existingDatatype) => incomingDatatype['data-type-id'] === existingDatatype['data-type-id']
      );
      if (idx === -1) {
        state.datatypes.data.push(incomingDatatype);
      } else {
        state.datatypes.data[idx] = incomingDatatype;
      }
    })
    .addCase(datatypesLoaded, (state, action) => {
      state.datatypes.data = action.payload;
    })
    .addCase(datatypesSetSince, (state, action) => {
      state.datatypes.since = action.payload;
    })
    //Policies
    .addCase(policyAdded, (state, action) => {
      state.policies.data.push(action.payload);
    })
    .addCase(policyDeleted, (state, action) => {
      remove(state.policies.data, (policy) => policy['policy-id'] === action.payload);
    })
    .addCase(policyUpdated, (state, action) => {
      const existingPolicies = state.policies.data;
      const incomingPolicy = action.payload;
      const idx = findIndex(
        existingPolicies,
        (existingPolicy) => incomingPolicy['policy-id'] === existingPolicy['policy-id']
      );
      state.policies.data[idx] = incomingPolicy;
    })
    .addCase(policyUpdatedOrAdded, (state, action) => {
      const existingPolicies = state.policies.data;
      const incomingPolicy = action.payload;
      const idx = findIndex(
        existingPolicies,
        (existingPolicy) => incomingPolicy['policy-id'] === existingPolicy['policy-id']
      );
      if (idx === -1) {
        state.policies.data.push(incomingPolicy);
      } else {
        state.policies.data[idx] = incomingPolicy;
      }
    })
    .addCase(policiesLoaded, (state, action) => {
      state.policies.data = action.payload;
    })
    .addCase(policiesSetSince, (state, action) => {
      state.policies.since = action.payload;
    })
    // Purposes
    .addCase(purposeAdded, (state, action) => {
      state.purposes.data.push(action.payload);
    })
    .addCase(purposeDeleted, (state, action) => {
      remove(state.purposes.data, (purpose) => purpose['purpose-id'] === action.payload);
    })
    .addCase(purposeUpdated, (state, action) => {
      const existingPurposes = state.purposes.data;
      const incomingPurpose = action.payload;
      const idx = findIndex(
        existingPurposes,
        (existingPurpose) => incomingPurpose['purpose-id'] === existingPurpose['purpose-id']
      );
      state.purposes.data[idx] = incomingPurpose;
    })
    .addCase(purposeUpdatedOrAdded, (state, action) => {
      const existingPurposes = state.purposes.data;
      const incomingPurpose = action.payload;
      const idx = findIndex(
        existingPurposes,
        (existingPurpose) => incomingPurpose['purpose-id'] === existingPurpose['purpose-id']
      );
      if (idx === -1) {
        state.purposes.data.push(incomingPurpose);
      } else {
        state.purposes.data[idx] = incomingPurpose;
      }
    })
    .addCase(purposesLoaded, (state, action) => {
      state.purposes.data = action.payload;
    })
    .addCase(purposesSetSince, (state, action) => {
      state.purposes.since = action.payload;
    })
);
