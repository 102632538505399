import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { withTheme } from '@material-ui/core';
import DropdownIcon from '../../images/icons/dropdown.svg';

import './style.css';
import { TestID } from '../../testID';

function Warning({ warning, color }) {
  if (!warning) {
    return null;
  }

  return <WarningIcon style={{ color }} />;
}

function ExpansionPanel(props) {
  const [expanded, setExpanded] = useState(props.initiallyExpanded);

  const classes = `${props.className} expansion-panel ${
    expanded ? 'expansion-panel--expanded' : 'expansion-panel--collapsed'
  }`;

  let color;
  if (props.warning === 'expired') color = props.theme.palette.text.danger;
  if (props.warning === 'expiring') color = props.theme.palette.warning.yellow;

  return (
    <div
      className={classes}
      title={props.tooltip}
      style={{
        backgroundColor: props.theme.palette.background.light,
        border: `1px solid ${props.theme.palette.divider}`,
      }}
      data-testid={TestID.ExpansionPanelTitle}
    >
      <div className="expansion-panel_head">
        <Warning warning={props.warning} color={color} />
        <button
          className="expansion-panel__summary"
          style={{ color: props.theme.palette.text.primary }}
          onClick={() => setExpanded((expanded) => !expanded)}
        >
          <h3 className="expansion-panel__summary-text" style={{ color }}>
            {props.title}
          </h3>
          <div className="expansion-panel__toggle">
            <DropdownIcon />
          </div>
        </button>
      </div>
      <div className="expansion-panel__body">
        <div className="expansion-panel__content">{props.children}</div>
      </div>
    </div>
  );
}

ExpansionPanel.propTypes = {
  initiallyExpanded: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  warning: PropTypes.bool,
  theme: PropTypes.object,
};

ExpansionPanel.defaultProps = {
  initiallyExpanded: false,
  className: '',
  tooltip: '',
  warning: false,
};

Warning.propTypes = {
  warning: PropTypes.string,
  color: PropTypes.string,
};

export default withTheme(ExpansionPanel);
