import React from 'react';
import ExternalLink from 'Common/Links/ExternalLink';
import SesamLink from 'Common/Links/SesamLink';
import CenteredLayout from '../layout/centered';
import { Links } from 'Constants/links';

import './server-error.css';

export const ServerErrorPage = () => (
  <CenteredLayout>
    <main>
      <h2 className="server-error-title">Server error</h2>
      <p className="server-error-page-text">
        Uh-oh. Something unexpected just happened!
        <p>
          Please <SesamLink to="/">try again</SesamLink> in about 30 seconds.
        </p>
        <p>
          If the issue persists, try using our{' '}
          <ExternalLink href={Links.FallbackPortalUrl}>fallback portal</ExternalLink>, available as
          a temporary read-only option.
        </p>
        If you continue to experience problems, contact us at{' '}
        <ExternalLink href={Links.MailToInfoAtSesamDotIO}>info@sesam.io</ExternalLink>.
      </p>
    </main>
  </CenteredLayout>
);
