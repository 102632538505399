import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';

import { buildClassName, scrollToEl } from 'Internals/react-utils';
import { registerKey, unregisterKey } from 'Internals/global-shortcuts';

import './style.css';

const prefixRegExp = /^([\w-]+):(.*)$/;
const MAX_MATCHES = 25;

const searchTypes = {
  PIPE: 'pipe',
  DATASET: 'dataset',
  SYSTEM: 'system',
  GLOBAL: 'global',
  ALL: '',
};
const promoteOriginUser = (a, b) => {
  if (a.item.origin && b.item.origin) {
    if (a.item.origin === 'user' && b.item.origin !== 'user') {
      return -1;
    } else if (a.item.origin !== 'user' && b.item.origin === 'user') {
      return 1;
    } else {
      return 0;
    }
  } else if (a.item.origin && !b.item.origin) {
    return -1;
  } else if (!a.item.origin && b.item.origin) {
    return 1;
  } else {
    return 0;
  }
};

const Result = ({ needle, onClick, selected }) => {
  const resClass = 'command-palette__result';
  const selClass = 'command-palette__result--selected';

  return (
    <li
      className={buildClassName(resClass, selected ? selClass : undefined)}
      onClick={onClick(needle)}
      ref={(el) => selected && scrollToEl(el)}
      key={needle.keyMaker(needle.item)}
    >
      {needle.renderer(needle.item)}
    </li>
  );
};
Result.propTypes = {
  needle: PropTypes.shape({
    item: PropTypes.string,
    keyMaker: PropTypes.func,
    renderer: PropTypes.func,
  }),
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

const ResultsList = ({ results, handleNeedleClick, selIdx }) => {
  return (
    <ul className="command-palette__results">
      {results.map((needle, idx) => (
        <Result needle={needle} onClick={handleNeedleClick} selected={idx === selIdx} key={idx} />
      ))}
    </ul>
  );
};
ResultsList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleNeedleClick: PropTypes.func.isRequired,
  selIdx: PropTypes.number.isRequired,
};

const makeCreateNewNeedle = (id, router, subId, type) => ({
  item: id,
  keyMaker: (_id) => `makeNewPipe${_id}`,
  onActivate: () => {
    router.push(`/subscription/${subId}/${type}s/new?id=${id}`);
  },
  renderer: (_id) => (
    <div className="datahub-objects-palette__item" title={`Make a new pipe`}>
      <span className="visually-hidden">Make a new pipe</span>
      {`Create a new ${type} with id "${_id}"`}
    </div>
  ),
});

const makeGoToNiNeedle = (ni, router, subId) => ({
  item: ni,
  keyMaker: (_ni) => `goToNi${_ni}`,
  onActivate: () => {
    router.push(`/subscription/${subId}/browse/ni/${encodeURIComponent(ni)}`);
  },
  renderer: (_ni) => (
    <div className="datahub-objects-palette__item" title={`Make a new pipe`}>
      <span className="visually-hidden">Go to namespaced identifier</span>
      {`Go to namespaced identifier "${_ni}"`}
    </div>
  ),
});

const makeSearchNeedle = (query, router, subId) => ({
  item: query,
  keyMaker: (_query) => `search${_query}`,
  onActivate: () => {
    router.push(`/subscription/${subId}/browse/search?q=${encodeURIComponent(query)}`);
  },
  renderer: (_query) => (
    <div className="datahub-objects-palette__item" title={`Do a search`}>
      <span className="visually-hidden">Do a search</span>
      {`Search for "${_query}"`}
    </div>
  ),
});

const getSearchTypeMessage = (searchType) => {
  let text;
  if (searchType === searchTypes.PIPE) text = 'pipes';
  else if (searchType === searchTypes.SYSTEM) text = 'systems';
  else if (searchType === searchTypes.DATASET) text = 'datasets';
  else if (searchType === searchTypes.GLOBAL) text = 'globals';
  else return null;
  return <div className="command-palette__searchtype-message">{`Searching ${text}:`}</div>;
};

const getResults = (prefix, searchFor, haystackGenerator, maxMatches) => {
  let needle;
  let results = [];
  const substringRegex = new RegExp(`.*${escapeRegExp(searchFor)}.*`, 'i');
  const haystack = haystackGenerator(prefix);
  do {
    needle = haystack.next();

    // See the definition for CommandPalette~needle

    if (needle.value && needle.value.matcher(needle.value.item, substringRegex)) {
      results.push(needle.value);
    }
  } while (results.length < maxMatches && !needle.done);
  return results;
};

/**
 * An overlay command palette, à la Spotlight
 */
class CommandPalette extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = (ev) => {
      if (!this.rootEl.contains(ev.target)) {
        this.props.onRequestClose();
      }
    };

    this.handleOnChange = (ev) => {
      let input = ev.target.value;
      let results = [];
      let prefix = '';
      let searchType = this.state.searchType;
      let searchFor = input;

      // was the onChange event a Backspace key?
      const isBackspace = ev.nativeEvent.inputType === 'deleteContentBackward';
      // was the onChange vent a Delete key?
      const isDelete = ev.nativeEvent.inputType === 'deleteContentForward';

      if (input !== '') {
        // there is some input, is there a prefix? (pipe:,system:, dataset:)
        const prefixRegExpResult = input.match(prefixRegExp);
        if (prefixRegExpResult) {
          prefix = prefixRegExpResult[1];
          searchFor = prefixRegExpResult[2];
        }
        if (prefix && Object.values(searchTypes).includes(prefix)) {
          // slice out the prefix from the input because we add
          // the searchtype message in front
          searchType = prefix;
          input = input.slice(prefix.length + 1);
        }
        // if there is not prefix but we are within a search type, ie
        // there was a prefix before
        if (this.state.searchType && prefix === '') prefix = searchType;
        results = getResults(
          prefix,
          searchFor,
          this.props.haystackGenerator,
          this.props.maxMatches
        );
      } else {
        if (this.state.searchType) {
          results = getResults(searchType, '', this.props.haystackGenerator, this.props.maxMatches);
        }
      }
      results = results.slice().sort(promoteOriginUser);

      const originalInputLen = input.length;

      //Shortest element first
      results = results.sort((a, b) => {
        return a.item._id.length - b.item._id.length;
      });

      // autocomplete the input with the first result
      if (results.length > 0 && !isBackspace && !isDelete) {
        const firstResultId = results[0].item['_id'];
        if (firstResultId.startsWith(input)) {
          input = input + firstResultId.slice(input.length);
        }
      }

      ev.persist(); // needed to reference the event in the setState callback

      // update the "create new" results with current input
      const newPipeNeedle = makeCreateNewNeedle(input, this.props.router, this.props.subId, 'pipe');
      const newSystemNeedle = makeCreateNewNeedle(
        input,
        this.props.router,
        this.props.subId,
        'system'
      );

      const goToNiNeedle = makeGoToNiNeedle(input, this.props.router, this.props.subId);

      const searchNeedle = makeSearchNeedle(input, this.props.router, this.props.subId);

      const emptyResultNeedles = [newPipeNeedle, newSystemNeedle];
      if (searchType === 'system') emptyResultNeedles.reverse();
      if (this.props.hasSearch) {
        if (input.indexOf(':') !== -1) {
          emptyResultNeedles.push(goToNiNeedle);
        }
        emptyResultNeedles.unshift(searchNeedle);
        if (results.length > 0) {
          results.push(searchNeedle);
        }
      }

      this.setState(
        {
          results,
          input,
          selectedNeedleIdx: 0,
          searchType,
          emptyResultNeedles,
        },
        () => {
          // select the autocompleted part of input
          if (results.length > 0 && !isBackspace && !isDelete) {
            ev.target.setSelectionRange(originalInputLen, input.length);
          }
        }
      );
    };

    this.handleArrowDown = (ev) => {
      ev.preventDefault();

      if (this.state.selectedNeedleIdx < MAX_MATCHES) {
        let results = this.state.results;
        if (this.isShowingRecents()) results = this.props.recentResults;
        if (this.isShowingNothingFound()) {
          results = this.state.emptyResultNeedles;
        }

        if (this.state.selectedNeedleIdx < results.length - 1) {
          const updatedNeedleIdx = this.state.selectedNeedleIdx + 1;
          let newInput = this.state.input;
          if (this.isShowingResults()) {
            newInput = results[updatedNeedleIdx].item['_id'] || results[updatedNeedleIdx].item;
          }
          this.setState({
            selectedNeedleIdx: updatedNeedleIdx,
            input: newInput,
          });
        }
      }
    };

    this.handleArrowUp = (ev) => {
      ev.preventDefault();
      if (this.state.selectedNeedleIdx > 0) {
        let results = this.state.results;
        if (this.isShowingRecents()) results = this.props.recentResults;
        if (this.isShowingNothingFound()) {
          results = this.state.emptyResultNeedles;
        }

        const updatedNeedleIdx = this.state.selectedNeedleIdx - 1;
        let newInput = this.state.input;
        if (this.isShowingResults()) newInput = results[updatedNeedleIdx].item['_id'];
        this.setState({
          selectedNeedleIdx: updatedNeedleIdx,
          input: newInput,
        });
      }
    };

    this.handleSubmit = (results, ev) => {
      ev.preventDefault();

      const needle = results[this.state.selectedNeedleIdx];
      if (needle !== undefined) {
        needle.onActivate(needle.item);
        this.props.onRequestClose();
      }
    };

    this.handleNeedleClick = (needle) => (ev) => {
      ev.preventDefault();

      this.props.onRequestClose();
      needle.onActivate(needle.item);
    };

    this.handleRemovePrefixSearch = (ev) => {
      if (this.state.input === '' && this.state.searchType && ev.keyCode === 8) {
        // input is empty but we are in a prefix search
        // so remove that prefix search
        ev.preventDefault();
        const searchType = '';
        const input = this.state.searchType;
        this.setState({ searchType, input });
      }
    };

    this.routerListenerInited = false;
    this.isShowingResults = () => this.state.results.length > 0 && this.state.input.length > 0;
    this.isShowingRecents = () =>
      this.state.results.length < 1 &&
      this.state.input.length < 1 &&
      !this.state.searchType &&
      this.props.recentResults.length > 0;
    this.isShowingNothingFound = () => this.state.results.length < 1 && this.state.input.length > 0;

    this.state = {
      input: '',
      userInput: '',
      searchType: searchTypes.ALL,
      results: this.props.recentResults,
      selectedNeedleIdx: 0,
      emptyResultNeedles: [],
    };
  }

  componentDidMount() {
    registerKey('ArrowDown', this.handleArrowDown, {}, true, true);
    registerKey('ArrowUp', this.handleArrowUp, {}, true, true);
    registerKey('Escape', this.props.onRequestClose, {}, true, true);
    registerKey('Tab', this.handleArrowDown, {}, true, true);
    registerKey('Tab', this.handleArrowUp, { shiftKey: true }, true, true);
    document.addEventListener('mousedown', this.handleClick);
    this.unregisterRouterListener = this.props.router.listen(() => {
      // When registering this listener, it fires immediately with the latest
      // transition. We need a flag to ignore the initial call.
      if (this.routerListenerInited) {
        this.props.onRequestClose();
      }
      this.routerListenerInited = true;
    });
  }

  componentWillUnmount() {
    unregisterKey('ArrowDown');
    unregisterKey('ArrowUp');
    unregisterKey('Escape');
    unregisterKey('Tab');
    document.removeEventListener('mousedown', this.handleClick);
    this.unregisterRouterListener();
  }

  render() {
    let results = this.state.results;
    if (this.isShowingRecents()) results = this.props.recentResults;
    if (this.isShowingNothingFound()) {
      results = this.state.emptyResultNeedles;
    }
    const selIdx = this.state.selectedNeedleIdx;
    const selectedResult = results.length > 0 ? results[selIdx] : undefined;

    return (
      <form
        className="command-palette"
        ref={(el) => {
          this.rootEl = el;
        }}
        onSubmit={(ev) => this.handleSubmit(results, ev)}
      >
        <div className="command-palette__input">
          {getSearchTypeMessage(this.state.searchType)}
          <input
            autoFocus
            ref={(r) => {
              this.ref = r;
            }}
            className="command-palette__input-field"
            onChange={this.handleOnChange}
            placeholder="Search…"
            value={this.state.input}
            onKeyDown={this.handleRemovePrefixSearch}
          />
          <span aria-live="assertive" aria-atomic="true" className="visually-hidden">
            {selectedResult && (
              <span>
                {selectedResult.renderer(selectedResult.item)}
                Submit form to navigate
              </span>
            )}
          </span>
        </div>
        {this.isShowingRecents() && (
          <span aria-live="assertive" className="command-palette__feedback">
            Recent searches
          </span>
        )}
        {this.isShowingNothingFound() && (
          <div aria-live="assertive" className="command-palette__feedback">
            Nothing found!
          </div>
        )}
        <ResultsList results={results} handleNeedleClick={this.handleNeedleClick} selIdx={selIdx} />
        {this.props.hint && this.state.input.length === 0 && (
          <div className="command-palette__hint">{this.props.hint}</div>
        )}
      </form>
    );
  }
}

CommandPalette.propTypes = {
  /** A hint to display when openend */
  hint: PropTypes.node,

  /** {CommandPalette~haystackGenerator} Generator for stuff to search */
  haystackGenerator: PropTypes.func.isRequired,

  /** Maximum number of matches to display */
  maxMatches: PropTypes.number,

  /** Handler for when the user requests closing the palette (e.g. by pressing
      `Esc`) — this should update the parent component's state so as to unmount
      this component */
  onRequestClose: PropTypes.func.isRequired,

  /** {Router} */
  router: PropTypes.object.isRequired,
  recentResults: PropTypes.array.isRequired,
  subId: PropTypes.string.isRequired,
  hasSearch: PropTypes.bool.isRequired,
  isItestMode: PropTypes.bool,
};

CommandPalette.defaultProps = {
  hint: undefined,
  maxMatches: MAX_MATCHES,
};

export default withRouter(CommandPalette);

/**
 * @callback CommandPalette~haystackGenerator
 * @generator
 * @param {string} [prefix=""] A prefix to help the generator limit what to return
 * @yields {CommandPalette~needle}
 */

/**
 * @typedef {Object} CommandPalette~needle
 * @property {CommandPalette~matcherCallback} matcher A function that evaluates whether the item matches the search
 * @property {Object} item An arbitrary data obhect
 * @property {CommandPalette~activateCallback} onActivate Callback for when the user activates a result
 * @property {CommandPalette~rendererCallback} renderer Callback for rendering the item within the results list
 * @property {CommandPalette~keyMakerCallback} keyMaker Callback for generating a unique ID for React's `key`
 */

/**
 * @callback CommandPalette~matcherCallback
 * @param {object} item The data item being evaluated; @see {CommandPalette~needle} (the item param)
 * @param {RegExp} search A regular expression to use for testing strings against
 * @returns {bool} Whether the item matches the search
 */

/**
 * @callback CommandPalette~activateCallback
 * @param {object} item The data item being evaluated; @see {CommandPalette~needle} (the item param)
 */

/**
 * @callback CommandPalette~rendererCallback
 * @param {object} item The data item being evaluated; @see {CommandPalette~needle} (the item param)
 * @returns {Node} Contents to display as a result item
 */

/**
 * @callback CommandPalette~keyMakerCallback
 * @param {object} item The data item being evaluated; @see {CommandPalette~needle} (the item param)
 * @returns {string} A unique ID (the same item should always generate the same ID)
 */
