import produce from 'immer';
import { createReducer } from '@reduxjs/toolkit';
import { disconnected } from '../thunks/subscriptions';
import { getDeadLetterDatasetOriginalId, getType } from 'Internals/datasets';
import {
  datasetLoaded,
  datasetsLoadAllFinished,
  datasetRemoved,
  datasetRolledBack,
  datasetCommitted,
} from '../thunks/datasets';
import { SomeObject } from 'Types/common.types';
import { Dataset, DatasetMap } from 'Types/dataset.types';

// -----------------------------------------------------------------------------

type DatasetsState = DatasetMap;
const initialState: DatasetsState = {};

const isInternalDatasetRegex = /^(system:|configure_node:|load_nodeconfig:|merge_nodeconfig:)/;

export function decorateDataset(rawDataset: SomeObject): Dataset {
  // Todo proper typing of this
  return <Dataset>produce(rawDataset, (dataset) => {
    const id = dataset._id;
    dataset.link = encodeURIComponent(id);
    dataset.origin = dataset.runtime.origin;
    if (dataset.origin == null) {
      // fallback until all nodes expose this
      dataset.origin = isInternalDatasetRegex.test(id) ? 'system' : 'user';
    }
    dataset.isInternal = dataset.origin !== 'user';
    dataset.state = dataset.runtime.populated ? 'populated' : 'not populated';
    if (getType(dataset._id) === 'dead_letter_dataset') {
      dataset.originalDataset = getDeadLetterDatasetOriginalId(dataset._id);
    }
  });
}

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(datasetLoaded, (state, { payload }) => {
      state[payload.id] = decorateDataset(payload.dataset);
    })
    .addCase(datasetsLoadAllFinished, (state, action) => {
      const newState: DatasetMap = {};
      for (let i = 0; i < action.payload.length; i++) {
        newState[action.payload[i]._id] = decorateDataset(action.payload[i]);
      }
      return newState;
    })
    .addCase(datasetRemoved, (state, action) => {
      delete state[action.payload];
    })
    .addCase(datasetRolledBack, (state, action) => {
      if (state[action.payload]) {
        state[action.payload].runtime['circuit-breaker-tripped'] = false;
      }
    })
    .addCase(datasetCommitted, (state, action) => {
      if (state[action.payload]) {
        state[action.payload].runtime['circuit-breaker-tripped'] = false;
      }
    })
);

export default reducer;
