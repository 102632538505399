import './DatasetInspectorMessageStyle.css';

import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

import Button from 'Common/Button/Button';
import { DatasetInspectorContext } from '../DatasetInspectorContext';
import { getIndexWithoutDeleted, getCompleteLog, getIndexWithDeleted } from 'Internals/datasets';
import { DatasetInspectorFilters } from 'Types/enums';
import { TestID } from 'src/testID';

const DatasetInspectorMessage = (props) => {
  const [state] = useContext(DatasetInspectorContext);

  let total;
  let filterFriendlyName;
  switch (props.filter) {
    case DatasetInspectorFilters.CompleteLog:
      total = getCompleteLog(props.dataset);
      filterFriendlyName = 'all';
      break;
    case DatasetInspectorFilters.LatestWithoutDeleted:
      total = getIndexWithoutDeleted(props.dataset);
      filterFriendlyName = 'latest';
      break;
    case DatasetInspectorFilters.LatestWithDeleted:
      total = getIndexWithDeleted(props.dataset);
      filterFriendlyName = 'latest /w deleted';
      break;
  }

  const progressPercentage = Math.round((state.freetextProgress * 100) / total);

  const message = useMemo(() => {
    if (state.showingFreetextResults || state.showingIdResults) {
      if (state.populating || state.loadingMore || state.loadingMoreTop) {
        return (
          <div className="dataset-inspector-message__message">
            <CircularProgress
              size={20}
              thickness={5}
              style={{ marginLeft: '2px', marginRight: '2px' }}
            />
            <div
              className="dataset-inspector-message__text"
              data-testid={TestID.SearchPipeEntitiesInProgressStatusHeader}
            >
              {`Searching...${state.showingFreetextResults ? ` (${progressPercentage}%)` : ''}`}
            </div>
          </div>
        );
      } else {
        if (state.hasMore === true || state.hasMoreTop === true) {
          return (
            <div className="dataset-inspector-message__message">
              <CheckCircleOutlineIcon color="primary" className="dataset-inspector-message__icon" />
              <div className="dataset-inspector-message__text">
                {`Search paused... (${state.entities.length} found so far)`}
              </div>
            </div>
          );
        } else {
          return (
            <div className="dataset-inspector-message__message">
              <CheckCircleOutlineIcon color="primary" className="dataset-inspector-message__icon" />
              <div
                className="dataset-inspector-message__text"
                data-testid={TestID.SearchPipeEntitiesCompletedStatusHeader}
              >
                {`Search complete (${state.entities.length} found)`}
              </div>
            </div>
          );
        }
      }
    } else {
      if (state.isFilterDirty) {
        return (
          <div className="dataset-inspector-message__message">
            <ErrorOutline color="action" className="dataset-inspector-message__icon" />
            <div
              className="dataset-inspector-message__text"
              data-testid={TestID.SearchPipeClickGoStatusHeader}
            >
              Click GO to update results
            </div>
          </div>
        );
      } else {
        if (state.entities.length > 0) {
          return (
            <div className="dataset-inspector-message__message">
              <CheckCircleOutlineIcon color="primary" className="dataset-inspector-message__icon" />
              <div className="dataset-inspector-message__text">
                {`Browsing ${filterFriendlyName}`}
              </div>
            </div>
          );
        } else if (state.populating) {
          return (
            <div className="dataset-inspector-message__message">
              <CircularProgress
                size={20}
                thickness={5}
                style={{ marginLeft: '2px', marginRight: '2px' }}
              />
              <div className="dataset-inspector-message__text">Loading...</div>
            </div>
          );
        } else {
          return (
            <div className="dataset-inspector-message__message">
              <CheckCircleOutlineIcon color="primary" className="dataset-inspector-message__icon" />
              <div className="dataset-inspector-message__text">No entities found</div>
            </div>
          );
        }
      }
    }
  }, [
    filterFriendlyName,
    progressPercentage,
    state.entities.length,
    state.hasMore,
    state.hasMoreTop,
    state.isFilterDirty,
    state.loadingMore,
    state.loadingMoreTop,
    state.populating,
    state.showingFreetextResults,
    state.showingIdResults,
  ]);

  return (
    <div
      className="dataset-inspector-message"
      style={{
        backgroundColor: props.theme.palette.background.semilight,
        color: props.theme.palette.text.primary,
      }}
    >
      <div className="dataset-inspector-message__content">{message}</div>
      <div className="dataset-inspector-message__actions">
        {state.showingFreetextResults &&
          (state.populating || state.loadingMore || state.loadingMoreTop) && (
            <Button onClick={props.stopSearch} size="small">
              Stop
            </Button>
          )}
        {props.customActions.length > 0 &&
          props.customActions.map((action, i) => (
            <div key={i} className="dataset-inspector-message__custom-action">
              {action}
            </div>
          ))}
      </div>
    </div>
  );
};

DatasetInspectorMessage.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  filter: PropTypes.string.isRequired,
  customActions: PropTypes.arrayOf(PropTypes.node),
  stopSearch: PropTypes.func.isRequired,
};

DatasetInspectorMessage.defaultProps = {
  customActions: [],
};

export default withTheme(DatasetInspectorMessage);
