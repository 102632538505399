import { connect } from 'react-redux';
import { compose } from 'redux';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import union from 'lodash/union';
import { withTheme } from '@material-ui/core';

import DatahubActions from 'Redux/thunks/datahub';
import SubActions, { currentSub } from 'Redux/thunks/subscriptions';

import ACLEditor from './ACLEditor';
import Button from './Button/Button';
import CollapsePanel from './CollapsePanel/CollapsePanel';
import ErrorPanel from './ErrorPanel';
import { LoadingPanel } from './LoadingPanel';
import { Form } from './forms';
import SesamAutocomplete from './SesamAutocomplete/SesamAutocomplete';
import SesamTextField from './SesamTextField/SesamTextField';

import './PermissionsEditor.css';

class PermissionPreviewer extends React.Component {
  constructor(props) {
    super(props);

    // TODO: perhaps get these roles from the server somehow
    this.everyoneRole = 'group:Everyone';
    this.adminRole = 'group:Admin';
    this.systemAdminRole = 'group:SystemAdmin';

    this.hasPermission = (permission) => {
      const selectedPrincipals = this.state.selectedPrincipals;

      if (selectedPrincipals.indexOf(this.systemAdminRole) !== -1) {
        // The system admins are allowed to do anything.
        return true;
      }

      if (selectedPrincipals.indexOf(this.adminRole) !== -1) {
        // The admins are allowed to do anything, unless the permissions mentions the systemAdminRole
        let aclsIncludeSystemAdmin = false;
        for (const acl of this.props.acls) {
          if (acl) {
            for (const ace of acl) {
              const acePrincipals = ace[1];
              if (acePrincipals.indexOf(this.systemAdminRole) !== -1) {
                aclsIncludeSystemAdmin = true;
              }
            }
          }
        }
        if (!aclsIncludeSystemAdmin) {
          return true;
        }
      }

      for (const acl of this.props.acls) {
        if (acl) {
          for (const ace of acl) {
            const aceType = ace[0];
            const acePrincipals = ace[1];
            const acePermissions = ace[2];
            for (const principal of selectedPrincipals) {
              if (acePrincipals.indexOf(principal) !== -1) {
                // This ace applies to this principal
                if (aceType === 'allow_all') {
                  return true;
                }
                if (aceType === 'deny_all') {
                  return false;
                }
                if (acePermissions.indexOf(permission) !== -1) {
                  // This ace applies to this action
                  if (aceType === 'allow') {
                    return true;
                  }
                  return false;
                }
              }
            }
          }
        }
      }
      // Default to access denied.
      return false;
    };

    this.state = {
      selectedPrincipals: this.props.defaultSelectedPrincipals.concat(this.everyoneRole),
    };
  }

  render() {
    const thElements = [];
    const tdElements = [];
    const selectedPrincipals = clone(this.state.selectedPrincipals);

    for (const permissionName of Object.keys(this.props.permissionsInfo)) {
      const permissionInfo = this.props.permissionsInfo[permissionName];
      thElements.push(
        <th key={`action-${permissionName}`} className="action">
          <div>
            <span title={permissionInfo.description}>{permissionInfo.display_name}</span>
          </div>
        </th>
      );

      let isChecked = this.hasPermission(permissionName);
      tdElements.push(
        <td className="action" key={`action-${permissionName}`}>
          <input type="checkbox" checked={isChecked} readOnly />
        </td>
      );
    }

    return (
      <CollapsePanel title="Test permissions">
        <div className="permission-tester">
          <p>
            Here you can test how which permissions will be granted to a user with various roles.
          </p>

          <div>
            <label>
              Selected roles:
              <SesamAutocomplete
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                value={selectedPrincipals}
                renderInput={(params) => <SesamTextField {...params} margin="none" />}
                size="small"
                options={this.props.principalsInfo.map((p) => p['id'])}
                onChange={(obj, val) => {
                  if (val.indexOf(this.everyoneRole) === -1) {
                    val.push(this.everyoneRole);
                  }
                  this.setState({ selectedPrincipals: val });
                }}
              />
            </label>
            <Button
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
              onClick={() => {
                this.setState({
                  selectedPrincipals: this.props.defaultSelectedPrincipals,
                });
              }}
              title="Reset the selection to the current user's roles."
            >
              Reset test-roles
            </Button>
          </div>

          <label>
            Resulting permissions:
            <table className="acl">
              <thead>
                <tr>{thElements}</tr>
              </thead>
              <tbody>
                <tr>{tdElements}</tr>
              </tbody>
            </table>
          </label>
        </div>
      </CollapsePanel>
    );
  }
}

PermissionPreviewer.propTypes = {
  permissionsInfo: PropTypes.object.isRequired,
  principalsInfo: PropTypes.array.isRequired,
  defaultSelectedPrincipals: PropTypes.arrayOf(PropTypes.string),
  acls: PropTypes.arrayOf(PropTypes.array).isRequired,
};

/*
 * This is used to edit the permissions-settings on Systems, Datasets, Pipes and on the Node.
 *
 * The PermissionsEditor is given a path that points to the current Principal-settings, and that the
 * editor can send a PUT-request to to update the settings. Here are some examples of these
 * paths:
 *
 * ['systems']
 * ['systems', <systemid>]
 * ['pipes']
 * ['pipes', <pipeid>]
 * ['datasets']
 * ['datasets', <datasetid>]
 * ['node']
 *
 * The api returns a json object that looks something like this:
 *
 * {
 *   "inherited": [
 *     {"acl":[
 *         ("allow", "group:Admin", "read"),
 *         ("allow", "group:Admin", "write"),
 *         ("allow", "group:Admin", "comment"),
 *         ("allow", "group:User", "read"),
 *         ("allow", "group:User", "write"),
 *       ],
 *      "source": "The Dataset prototype"
 *   ],
 *   "local": [
 *     ("allow", "email:jimi@zax.io", "read"),
 *     ("allow", "group:User", "comment"),
 *     ("deny", "group:User", "write")
 *   ]
 * }
 *
 * When doing a PUT, only the "local" part will be sent to the server. The "inherited" part
 * is read-only.
 */
function mapStateToProps(state) {
  return {
    availableRoles: state.subscription.availableRoles,
    subId: state.subscription.id,
    userPrincipals: currentSub(state)['current-users-member'].roles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPermission: (path) => dispatch(DatahubActions.getPermission(path)),
    loadAvailableRoles: (subId) => dispatch(SubActions.loadAvailableRoles(subId)),
    putPermission: (path, settings) => dispatch(DatahubActions.putPermission(path, settings)),
  };
}

class _PermissionsEditor extends React.Component {
  constructor(props) {
    super(props);

    this._getPermissionPath = () => {
      if (this.props.permission) {
        return [this.props.permission];
      }
      return this.props.permissionPath;
    };

    this.onDownloadedPermissions = (reply) => {
      const inheritedSettings = reply.inherited;
      const localSettings = reply.local;

      const actionNamesSet = new Set();
      const principalNamesSet = new Set();

      const allACLs = [];
      if (localSettings !== null) {
        allACLs.push(localSettings);
      }
      for (const inheritedSetting of inheritedSettings) {
        allACLs.push(inheritedSetting.acl);
      }

      for (const principalInfo of reply.principals_info) {
        // skip a couple of special roles that we never will need to assign permissions to.
        if (principalInfo.id === 'group:Admin') {
          continue;
        }
        if (principalInfo.id === 'group:LicenceUploader') {
          continue;
        }
        principalNamesSet.add(principalInfo.id);
      }

      for (const acl of allACLs) {
        for (const ace of acl) {
          const principals = ace[1];
          const actions = ace[2];
          for (const principal of principals) {
            principalNamesSet.add(principal);
          }
          for (const action of actions) {
            actionNamesSet.add(action);
          }
        }
      }

      const actionNames = [];
      actionNamesSet.forEach((name) => actionNames.push(name));
      const principalNames = [];
      principalNamesSet.forEach((name) => principalNames.push(name));

      this.setState({
        actionNames,
        principalNames,
        inheritedSettings,
        permissionsInfo: reply.permissions_info,
        downloadError: null,
        localSettings,
        lastSavedLocalSettings: cloneDeep(localSettings),
      });
    };

    this.onLocalACLModified = (newACL) => {
      this.setState({ localSettings: newACL });
    };

    this.onSave = (event) => {
      event.preventDefault();
      const localSettings = cloneDeep(this.state.localSettings);

      this.props
        .putPermission(this._getPermissionPath(), localSettings)
        .then(() =>
          this.setState({
            lastSavedLocalSettings: localSettings,
            uploadError: null,
          })
        )
        .catch((uploadError) => this.setState({ uploadError }));
    };

    /**
     * Check if a principal ID is defined only locally (i.e. not provided by
     * props.availableRoles)
     * @param {string} principal principal ID
     * @return {Boolean}
     */
    this.isLocalPrincipalOnly = (principal) => {
      if (!this.props.availableRoles) return false;
      return this.props.availableRoles.some((role) => role.id === principal);
    };

    /**
     * Returns a list of principals available only locally. This is a list of all
     * principals defined locally but not provided in props.avaliableRoles
     */
    this.getLocalOnlyPrincipals = () => {
      if (!this.state.localSettings) {
        return [];
      }
      // union() de-duplicates values
      const localPrincipals = union(
        ...this.state.localSettings.map((localSetting) => localSetting[1])
      );
      return localPrincipals.filter((principal) => !this.isLocalPrincipalOnly(principal));
    };

    this.getLocallyAvailableRoles = () => {
      const mockedRoles = this.getLocalOnlyPrincipals().map((principal) => ({
        id: principal,
        name: principal,
      }));
      return this.props.availableRoles.concat(mockedRoles);
    };

    this.refresh = () => {
      this.props.loadAvailableRoles(this.props.subId);

      this.props
        .getPermission(this._getPermissionPath())
        .then((reply) => this.onDownloadedPermissions(reply))
        .catch((downloadError) => this.setState({ downloadError }));
    };

    this.state = {};
  }

  componentDidMount() {
    this.refresh();
    if (this.props.registerRefresh) this.props.registerRefresh(this.refresh);
  }

  componentWillUnmount() {
    if (this.props.unregisterRefresh) this.props.unregisterRefresh(this.refresh);
  }

  render() {
    if (this.state.downloadError) {
      return (
        <div>
          <div>Failed to download the permission settings for {this._getPermissionPath()}:</div>
          <ErrorPanel errors={this.state.downloadError} />
          );
        </div>
      );
    }
    if (!this.state.actionNames) {
      return <LoadingPanel />;
    }

    const saveNeeded = !isEqual(this.state.localSettings, this.state.lastSavedLocalSettings);

    const allACLs = [];
    if (this.state.localSettings !== null) {
      allACLs.push(this.state.localSettings);
    }
    const inheritedACLElements = [];
    for (const inheritedSetting of this.state.inheritedSettings) {
      allACLs.push(inheritedSetting.acl);
      inheritedACLElements.push(
        <div
          className="inherited-setting"
          key={inheritedSetting.source}
          style={{
            backgroundColor: this.props.theme.palette.background.semilight,
          }}
        >
          <h3 className="inherit-from">From {inheritedSetting.source}</h3>
          <ACLEditor
            principalNames={this.state.principalNames}
            permissionsInfo={this.state.permissionsInfo}
            principalsInfo={this.props.availableRoles}
            acl={inheritedSetting.acl}
          />
        </div>
      );
    }

    let localSettingsACLEditor = null;
    if (this.state.localSettings !== null) {
      localSettingsACLEditor = [
        <ACLEditor
          key="editor"
          acl={this.state.localSettings}
          principalNames={this.state.principalNames}
          permissionsInfo={this.state.permissionsInfo}
          principalsInfo={this.getLocallyAvailableRoles()}
          modifyACLCallback={this.onLocalACLModified}
        />,
        <div className="actions" key="actions">
          <Form onSubmit={this.onSave}>
            <Button type="submit" theme="primary" disabled={saveNeeded === false}>
              Save
            </Button>
          </Form>
        </div>,
        <ErrorPanel key="errors" title="Save failed" errors={this.state.uploadError} />,
      ];
    } else {
      // There are no local settings (this is probably a dataset, which inherits everything from
      // the pipe that writes to that dataset).
      localSettingsACLEditor = (
        <p>
          This component has no local permission settings. All permission settings are inherited.
        </p>
      );
    }

    return (
      <div className="permissions-editor">
        <h2 className="heading-section">Inherited permissions</h2>
        {inheritedACLElements}
        <h2 className="heading-section">Local permissions</h2>
        {localSettingsACLEditor}
        <PermissionPreviewer
          acls={allACLs}
          principalsInfo={this.getLocallyAvailableRoles()}
          permissionsInfo={this.state.permissionsInfo}
          defaultSelectedPrincipals={this.props.userPrincipals}
        />
      </div>
    );
  }
}

_PermissionsEditor.propTypes = {
  availableRoles: PropTypes.array.isRequired,
  getPermission: PropTypes.func.isRequired,
  loadAvailableRoles: PropTypes.func.isRequired,
  permission: PropTypes.string,
  permissionPath: PropTypes.array,
  putPermission: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  userPrincipals: PropTypes.array.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({
        semilight: PropTypes.string,
      }),
    }),
  }),
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

export const PermissionsEditor = compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(_PermissionsEditor);
