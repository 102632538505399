import React, { memo } from 'react';
import List from '@material-ui/core/List';
import SearchResultSkeleton from './SearchResultSkeleton';

const SearchResultSkeletonList: React.FC = memo(() => (
  <List disablePadding>
    {Array.from({ length: 10 }, (_val, index) => (
      <SearchResultSkeleton key={index} />
    ))}
  </List>
));

export { SearchResultSkeletonList };
