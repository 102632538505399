import { createAction, createReducer } from '@reduxjs/toolkit';
import type { AppThunk } from 'Types/state.types';

export type PostEntitiesModalArgs = {
  title: string;
  subTitle: string;
  errorMessages?: string[];
};

export const setModalInfoAction = createAction('info/set', (info: PostEntitiesModalArgs) => {
  return {
    payload: info,
  };
});

export const setErrorMessagesAction = createAction('info/setError', (errorMessages: string[]) => {
  return {
    payload: errorMessages,
  };
});

export const PostEntitiesModalThunk = {
  setModalInfo(title: string, subTitle: string): AppThunk {
    return (dispatch) => {
      dispatch(setModalInfoAction({ title, subTitle }));
    };
  },
  setErrorMessages(errorMessages: string[]): AppThunk {
    return (dispatch) => {
      dispatch(setErrorMessagesAction(errorMessages));
    };
  },
};

const initialState: PostEntitiesModalArgs = { title: '', subTitle: '', errorMessages: [] };

export const postEntitiesModal = createReducer(initialState, (builder) =>
  builder
    .addCase(setModalInfoAction, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(setErrorMessagesAction, (state, action) => {
      return {
        ...state,
        errorMessages: action.payload,
      };
    })
);
