import { sfetchJson } from 'Internals/sfetch';
import { SomeObject, SupportedOperation, AuditInfo } from 'Types/common.types';
import { Json } from '../types/json';
import { PaymentMethodAPIPayload, PaymentMethodInfoTypes } from 'Types/paymentMethod.types';

export interface NewPaymentMethod {
  name?: string;
  type?: PaymentMethodInfoTypes;
  description?: string;
  billing_info?: SomeObject;
  card_token?: string;
}

export interface UpdatedPaymentMethod {
  _updated?: SomeObject;
  name?: string;
  description?: string;
  billing_info?: SomeObject;
  [key: string]: any;
}

async function getAll(portalUrl: string): Promise<PaymentMethodAPIPayload[]> {
  const requestOptions: RequestInit = { credentials: 'include' };
  return await sfetchJson(`${portalUrl}/paymentmethods`, requestOptions);
}

async function remove(portalUrl: string, id: string): Promise<Json> {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  return await sfetchJson(`${portalUrl}/paymentmethods/${id}`, requestOptions);
}

async function post(
  portalUrl: string,
  paymentMethod: NewPaymentMethod
): Promise<PaymentMethodAPIPayload> {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(paymentMethod),
  };
  return await sfetchJson(`${portalUrl}/paymentmethods`, requestOptions);
}

async function put(
  portalUrl: string,
  paymentMethod: PaymentMethodAPIPayload
): Promise<UpdatedPaymentMethod> {
  const requestOptions: RequestInit = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(paymentMethod),
  };
  return await sfetchJson(`${portalUrl}/paymentmethods/${paymentMethod.id}`, requestOptions);
}

export default {
  getAll,
  remove,
  post,
  put,
};
