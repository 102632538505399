import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import datejs from 'dayjs';

import { Form, FormActions } from 'Common/forms';
import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';
import SesamKeyboardDatePicker from 'Common/SesamKeyboardDatePicker/SesamKeyboardDatePicker';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SubActions, { getCurrentSub, currentUserIsAdmin } from 'Redux/thunks/subscriptions';
import { RoleProps } from '../multirole-selector';
import PromiseButton from '../promise-button';
import moment from 'moment';
import './styles.css';
import { load } from 'redux-localstorage-simple';
import { TestID } from '../../testID';

const suggestedExpiry = moment().add(1, 'y');

const initialState = {
  description: '',
  exp: suggestedExpiry,
  name: '',
  principals: [],
  refresh_interval: '',
};
class AddJwtForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = (stateKey) => (ev) => {
      this.setState({ [stateKey]: ev.target.value });
    };

    this.handleExpiryChange = (date, value) => {
      this.setState({ exp: date });
      this.setState({ inputValue: value });
    };

    this.dateFormatter = (str) => {
      return str;
    };

    this.handleRolesChange = (roles) => {
      this.setState({ principals: roles });
    };

    this.handleOwnerChange = (owner) => {
      this.setState({ owner: owner });
    };

    this.handleRefreshIntervalChange = (ev) => {
      this.setState({
        refresh_interval: ev.target.value,
      });
    };

    this.add = () => {
      delete this.state.inputValue;
      const payload = {
        ...this.state,
        exp: this.state.exp.unix(),
      };
      delete payload.membersEmailArray;
      if (payload.refresh_interval === '') {
        delete payload.refresh_interval;
      } else {
        payload['refresh_interval'] = parseInt(payload['refresh_interval']);
      }
      return this.props.handleAdd(payload).then(() => this.setState(initialState));
    };

    this.state = { ...initialState };
    this.state.inputValue = datejs().add(1, 'year').format('dddd, MMMM D, YYYY h:mm A');
  }

  componentDidMount() {
    this.props.loadAvailableRoles(this.props.subId);
    this.props.loadMembers(this.props.subId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.members !== this.props.members) {
      const membersEmailArray = this.props.members.map((m) => m['user']['email']) ?? [];
      const currentUser = membersEmailArray.find((email) => email === this.props.currentUserId);
      this.state.membersEmailArray = membersEmailArray;
      this.state.owner = [currentUser];
    }
  }

  render() {
    const isValid = this.state.name && this.state.exp && this.state.principals.length && this.state.owner.length;

    const rolesArray = this.props.availableRoles.map((r) => r['id']);

    return (
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <h2 className="heading-section">Add JWT</h2>
        <p data-testid={TestID.JsonWebTokenTabDetails}>
          JSON Web Tokens (JWTs) allow external services to automatically connect and interact with
          data in this Sesam instance.
        </p>

        <SesamTextField
          id="addJwt-name"
          label="Name"
          onChange={this.handleFieldChange('name')}
          value={this.state.name}
          margin="normal"
          inputTestid={TestID.AddJwtFormName}
        />

        <SesamAutocomplete
          options={this.state.membersEmailArray}
          getOptionLabel={(o) => o}
          value={this.state.owner}
          onChange={(ev, val) => this.handleOwnerChange(val)}
          size="small"
          renderInput={(params) => {
            return <SesamTextField {...params} size="small" label="Owner" margin="normal" />;
          }}
          data-testid={TestID.AddJwtFormOwner}
        />

        <SesamTextField
          id="addJwt-description"
          label="Description"
          onChange={this.handleFieldChange('description')}
          value={this.state.description}
          margin="normal"
          multiline
          data-testid={TestID.AddJwtFormDescription}
        />

        <SesamTextField
          id="addJwt-refresh"
          label="Optional refresh interval (in seconds)"
          type="number"
          onChange={this.handleRefreshIntervalChange}
          value={this.state.refresh_interval}
          size="small"
          margin="normal"
        />

        <SesamKeyboardDatePicker
          value={this.state.exp}
          inputValue={
            this.state.inputValue ? this.state.inputValue : this.state?.exp?.format('LLLL')
          }
          onChange={this.handleExpiryChange}
          rifmFormatter={this.dateFormatter}
          label="Expiry"
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          TextFieldComponent={(props) => <SesamTextField {...props} />}
          InputAdornmentProps={{ size: 'small' }}
          margin="normal"
        />

        <SesamAutocomplete
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          options={rolesArray}
          getOptionLabel={(o) => o}
          value={this.state.principals}
          onChange={(ev, val) => this.handleRolesChange(val)}
          size="small"
          renderInput={(params) => {
            return <SesamTextField {...params} size="small" label="Role" margin="normal" />;
          }}
          data-testid={TestID.AddJwtFormRole}
        />

        <FormActions>
          <PromiseButton
            disabled={!isValid}
            onClick={this.add}
            pending="Adding…"
            data-testid={TestID.AddJwtFormAddButton}
          >
            Add
          </PromiseButton>
        </FormActions>
      </Form>
    );
  }
}

AddJwtForm.propTypes = {
  availableRoles: PropTypes.arrayOf(PropTypes.shape(RoleProps)).isRequired,
  handleAdd: PropTypes.func.isRequired,
  loadAvailableRoles: PropTypes.func.isRequired,
  loadMembers: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  members: PropTypes.array,
  currentUserId: PropTypes.string,
};

function mapStateToProps(state) {
  const currentSub = getCurrentSub(state);
  const userRoles = currentSub['current-users-member'].roles;
  const availableRoles = state.subscription.availableRoles;
  let allowedRoles = [];

  if (availableRoles.length > 0) {
    allowedRoles = currentUserIsAdmin(state)
      ? availableRoles
      : userRoles.map((userRole) => availableRoles.find((role) => userRole === role.id));
  }

  return {
    availableRoles: allowedRoles,
    subId: state.subscription.id,
    currentUserId: state.user.user_id,
    members: state.subscription.members,
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadAvailableRoles: (subId) => dispatch(SubActions.loadAvailableRoles(subId)),
  loadMembers: (subId) => dispatch(SubActions.loadMembers(subId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddJwtForm);
