import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';

import moment from 'moment';

import Button from 'Common/Button/Button';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from 'Common/SesamTypography';

import SesamKeyboardDatePicker from 'Common/SesamKeyboardDatePicker/SesamKeyboardDatePicker';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const fieldRequired = 'This field is required';
const stringRequired = 'Must be a string';
const numberRequired = 'Must be a number';
const validationSchema = yup.object().shape({
  title: yup.string(stringRequired).required(fieldRequired),
  description: yup.string(stringRequired).required(fieldRequired),
  version: yup.number(numberRequired).required(fieldRequired),
  lang: yup.string(stringRequired).required(fieldRequired),
});

const helperText = {};

const GdprPolicyForm = ({
  defaultValues,
  initialValues,
  newPolicy,
  onDelete,
  onSubmit,
  onUpdate,
  onCancel,
}) => {
  function handleFocus(e) {
    const target = e.target;
    const name = target.name;

    setCurrentFocus(name);
  }

  const [currentFocus, setCurrentFocus] = useState('');
  const [newAdditionalField, setNewAdditionalField] = useState('');

  return (
    <Formik
      onSubmit={(data) => {
        return newPolicy ? onSubmit(data) : onUpdate(data);
      }}
      initialValues={initialValues || defaultValues}
      validationSchema={validationSchema}
    >
      {({ values, touched, errors, isValid, setFieldValue }) => {
        function addField() {
          setFieldValue(newAdditionalField, '');
          setNewAdditionalField('');
        }

        function handleKeyDown(e) {
          if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.value;
            if (newAdditionalField.length < 1) return;
            setNewAdditionalField('');
            if (Object.keys(values).includes(value)) {
              return;
            }
            addField();
          }
        }

        const isError = (name) => touched[name] && Boolean(errors[name]);
        const getHelperText = (name) => touched[name] && errors[name];

        return (
          <Box width="100%" height="100%">
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>{`${
                        newPolicy ? 'Create new' : 'Edit'
                      } policy`}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name="title"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Title *"
                            inputProps={{ autoFocus: true }}
                            error={isError('title')}
                            helperText={getHelperText('title')}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name="description"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Description *"
                            error={isError('description')}
                            helperText={getHelperText('description')}
                          />
                        )}
                      />
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="valid-from"
                          render={({ field: { value, ...field } }) => {
                            return (
                              <SesamKeyboardDatePicker
                                {...field}
                                TextFieldComponent={(props) => (
                                  <SesamTextField margin="normal" {...props} />
                                )}
                                value={value}
                                label="Valid from"
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                onChange={(val) => setFieldValue('valid-from', val.format())}
                                onOpen={() => setFieldValue('valid-from', moment().format())}
                                onFocus={handleFocus}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          name="valid-to"
                          render={({ field: { value, ...field } }) => {
                            return (
                              <SesamKeyboardDatePicker
                                {...field}
                                TextFieldComponent={(props) => (
                                  <SesamTextField margin="normal" {...props} />
                                )}
                                value={value}
                                label="Valid to"
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                onChange={(val) => setFieldValue('valid-to', val.format())}
                                onOpen={() => setFieldValue('valid-to', moment().format())}
                                onFocus={handleFocus}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name="link"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Link"
                            error={isError('link')}
                            helperText={getHelperText('link')}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name="markup"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Markup"
                            error={isError('markup')}
                            helperText={getHelperText('markup')}
                          />
                        )}
                      />
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="version"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="number"
                              label="Version *"
                              error={isError('version')}
                              helperText={getHelperText('version')}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          name="lang"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="text"
                              label="Language *"
                              error={isError('lang')}
                              helperText={getHelperText('lang')}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {Object.keys(values).map((key) => {
                        const firstChar = key.charAt(0);
                        if (
                          firstChar !== '_' &&
                          firstChar !== '$' &&
                          !Object.keys(defaultValues).includes(key)
                        ) {
                          return (
                            <Field
                              name={key}
                              render={({ field: { value, ...field } }) => (
                                <SesamTextField
                                  {...field}
                                  margin="normal"
                                  value={value !== null ? value : ''}
                                  onFocus={handleFocus}
                                  type="text"
                                  label={key}
                                  error={isError(key)}
                                  helperText={getHelperText(key)}
                                />
                              )}
                            />
                          );
                        }
                      })}
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box pt={1}>
                          <Typography variant="h6" gutterBottom>
                            Add more fields
                          </Typography>
                        </Box>

                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box mr={1} flex="1">
                            <SesamTextField
                              margin="none"
                              label="Field id"
                              onChange={(e) => {
                                const value = e.target.value;
                                setNewAdditionalField(value);
                              }}
                              onKeyDownCapture={handleKeyDown}
                              value={newAdditionalField}
                            />
                          </Box>
                          <Button
                            type="button"
                            size="big"
                            onClick={() => addField()}
                            disabled={newAdditionalField.length < 1}
                          >
                            Add
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>

              <Grid item xs>
                <Box display="flex" height="100%" alignItems="center">
                  <Divider orientation="vertical" />
                  <Box pl={3}>
                    <Typography variant="body1">
                      {currentFocus ? helperText[currentFocus] : ''}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Form>
                <Box display="flex" flexDirection="row-reverse">
                  <Box ml={1}>
                    <Button type="button" onClick={onCancel} size="big" text="Cancel" />
                  </Box>
                  {!newPolicy && (
                    <Box ml={1}>
                      <Button
                        type="button"
                        onClick={() => onDelete(values)}
                        size="big"
                        text="Delete"
                        theme="danger"
                      />
                    </Box>
                  )}
                  {newPolicy ? (
                    <Button text="Add new policy" size="big" disabled={!isValid} />
                  ) : (
                    <Button text="Save changes" size="big" disabled={!isValid} />
                  )}
                </Box>
              </Form>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

GdprPolicyForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  newPolicy: PropTypes.bool,
  inModal: PropTypes.bool,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
};

GdprPolicyForm.defaultProps = {
  inModal: true,
  newPolicy: false,
};

export default GdprPolicyForm;
