import { createAction } from '@reduxjs/toolkit';
import PaymentMethodsAPI, { NewPaymentMethod } from '../../api/payment-methods';
import { handlePortalError } from '../utils';
import type { AppThunk } from 'Types/state.types';
import type { PaymentMethodAPIPayload } from 'Types/paymentMethod.types';

export const paymentMethodCreated = createAction<PaymentMethodAPIPayload>('paymentMethods/created');
export const paymentMethodsLoaded =
  createAction<PaymentMethodAPIPayload[]>('paymentMethods/loaded');
export const paymentMethodRemoved = createAction<string>('paymentMethods/removed');
export const paymentMethodUpdated = createAction<PaymentMethodAPIPayload>('paymentMethods/updated');

export default {
  loadAll(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.getAll(portalUrl)
        .then((paymentMethods) => dispatch(paymentMethodsLoaded(paymentMethods)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  remove(id: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.remove(portalUrl, id)
        .then(() => dispatch(paymentMethodRemoved(id)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  create(pm: NewPaymentMethod): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.post(portalUrl, pm)
        .then((paymentMethod) => dispatch(paymentMethodCreated(paymentMethod)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  update(pm: PaymentMethodAPIPayload): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.put(portalUrl, pm)
        .then((response) => dispatch(paymentMethodUpdated(response)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },
};
