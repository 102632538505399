import { createAction } from '@reduxjs/toolkit';
import debounceAction from 'debounce-action';

import { apologise } from './apology';
import { getApiConf } from './subscriptions';
import SystemsAPI from '../../api/systems';
import type { AppThunk } from 'Types/state.types';
import type { System } from 'Types/system.types';
import type { SomeObject } from 'Types/common.types';

export const systemAdded = createAction<System>('systems/added');
export const systemRemoved = createAction<string>('systems/removed');
export const systemUpdated = createAction<System>('systems/updated');
export const systemLoaded = createAction<{ id: string; system: System }>('system/loaded');

export const systemLoadAllStarted = createAction('systems/loadAllStarted');
export const systemLoadAllFinished = createAction<System[]>('systems/loadAllFinished');
export const systemLoadAllFailed = createAction('systems/loadAllFailed');

export const systemSecretsLoaded =
  createAction<{
    id: string;
    secrets: string[];
  }>('system-secrets/loadAll');
export const systemSecretsAdded =
  createAction<{
    id: string;
    secrets: string[];
  }>('system-secrets/added');
export const systemSecretsRemoved =
  createAction<{
    id: string;
    secretName: string;
  }>('system-secrets/remove');

export default {
  add(newSystem: System): AppThunk {
    return function (dispatch, getState) {
      return SystemsAPI.add(getApiConf(getState), newSystem)
        .then((systems) => dispatch(systemAdded(systems[0])))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  delete(id: string): AppThunk {
    return function (dispatch, getState) {
      return SystemsAPI.remove(getApiConf(getState), id)
        .then(() => dispatch(systemRemoved(id)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  update(id: string, updatedSystem: System): AppThunk {
    return function (dispatch, getState) {
      return SystemsAPI.update(getApiConf(getState), id, updatedSystem)
        .then((system) => dispatch(systemUpdated(system)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  load: debounceAction(
    (id: string): AppThunk =>
      (dispatch, getState) => {
        return SystemsAPI.get(getApiConf(getState), id)
          .then((system) => dispatch(systemLoaded({ id, system })))
          .catch((error) => dispatch(apologise(error)));
      },
    1000,
    { leading: true, trailing: false }
  ),

  loadForced:
    (id: string): AppThunk =>
    (dispatch, getState) => {
      return SystemsAPI.get(getApiConf(getState), id)
        .then((system) => dispatch(systemLoaded({ id, system })))
        .catch((error) => dispatch(apologise(error)));
    },

  loadAll: debounceAction(
    (loadInternal = false): AppThunk =>
      (dispatch, getState) => {
        dispatch(systemLoadAllStarted());
        return SystemsAPI.getAll(getApiConf(getState), loadInternal)
          .then((systems) => dispatch(systemLoadAllFinished(systems)))
          .catch((error) => {
            dispatch(systemLoadAllFailed());
            dispatch(apologise(error));
          });
      },
    1000,
    { leading: true, trailing: false }
  ),

  loadAllForced:
    (loadInternal = false): AppThunk =>
    (dispatch, getState) => {
      dispatch(systemLoadAllStarted());
      return SystemsAPI.getAll(getApiConf(getState), loadInternal)
        .then((systems) => dispatch(systemLoadAllFinished(systems)))
        .catch((error) => {
          dispatch(systemLoadAllFailed());
          dispatch(apologise(error));
        });
    },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  getSinkPrototypes(systemId: string): AppThunk {
    return function (dispatch, getState) {
      return SystemsAPI.getSinkPrototypes(getApiConf(getState), systemId).catch((error) =>
        dispatch(apologise(error))
      );
    };
  },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  getSourcePrototypes(systemId: string): AppThunk {
    return function (dispatch, getState) {
      return SystemsAPI.getSourcePrototypes(getApiConf(getState), systemId);
    };
  },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  getSystemPrototypes(): AppThunk {
    return function (dispatch, getState) {
      return SystemsAPI.getSystemPrototypes(getApiConf(getState));
    };
  },

  /* System bound secrets */
  systemSecretsLoadAll: debounceAction(
    (id: string): AppThunk =>
      (dispatch, getState) => {
        return SystemsAPI.getSecrets(getApiConf(getState), id)
          .then((secrets) => dispatch(systemSecretsLoaded({ id, secrets })))
          .catch((error) => dispatch(apologise(error)));
      },
    1000,
    { leading: true, trailing: false }
  ),

  systemSecretsAdd(id: string, secrets: SomeObject[]): AppThunk {
    return (dispatch, getState) => {
      return SystemsAPI.addSecrets(getApiConf(getState), id, secrets)
        .then(() => dispatch(systemSecretsAdded({ id, secrets: Object.keys(secrets) })))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  systemSecretDelete(id: string, secretName: string): AppThunk {
    return (dispatch, getState) => {
      return SystemsAPI.removeSecret(getApiConf(getState), id, secretName)
        .then(() =>
          dispatch(
            systemSecretsRemoved({
              id,
              secretName,
            })
          )
        )
        .catch((error) => dispatch(apologise(error)));
    };
  },
};
