import get from 'lodash/get';

const flowWorker = new Worker(new URL('./flows.worker', import.meta.url), {
  type: 'module',
});

import type { PipeMap } from 'Types/pipes.types';
import type { DownstreamMap, LookupMap } from 'Types/common.types';
import { Flow } from './overview-utils';

type CachedPipe = {
  _id: string;
  ts: number;
};

type ResolverMap = {
  [id: string]: (value?: Flow[] | PromiseLike<Flow[]> | undefined) => void;
};

const Flows = () => {
  let previousPipes: CachedPipe[] = [];
  let cachedFlows: Flow[] = [];
  // array of resolver functions
  let resolvers: ResolverMap = {};
  flowWorker.onmessage = (event: MessageEvent) => {
    const flows = event.data.flows as Flow[];
    const id = event.data.id;
    cachedFlows = flows;
    resolvers[id](flows);
    delete resolvers[id];
  };

  const getFlows = (pipes: PipeMap, downstreams: DownstreamMap, lookups: LookupMap) => {
    return new Promise<Flow[]>((resolve) => {
      const id = Date.now();
      if (!isUpdated(pipes)) {
        savePipes(pipes);
        return resolve(cachedFlows);
      } else {
        resolvers[id] = resolve;
        flowWorker.postMessage({ pipes, downstreams, lookups, id });
        savePipes(pipes);
      }
    });
  };

  const isUpdated = (pipesAfter: PipeMap) => {
    // different lengths, definitely different
    let pipesAfterList = Object.values(pipesAfter);
    let pipesBefore = previousPipes;
    if (pipesBefore.length !== pipesAfterList.length) {
      return true;
    }
    const length = pipesBefore.length;

    let same = true;
    for (let i = 0; i < length; i++) {
      const pipeAfterId = pipesAfterList[i]._id;
      const pipeAfterTs = get(pipesAfterList[i], 'config.audit.last_modified_ts');
      const pipeBefore = pipesBefore.find((p) => p._id === pipeAfterId);
      if (!pipeBefore) {
        return true;
      }
      const pipeBeforeTs = pipeBefore.ts;
      if (pipeBeforeTs !== pipeAfterTs) {
        same = false;

        break;
      }
    }

    return !same;
  };

  const savePipes = (pipes: PipeMap) => {
    let pipesList = Object.values(pipes);
    previousPipes = pipesList.map((p) => ({
      _id: p._id,
      ts: get(p, 'config.audit.last_modified_ts'),
    }));
  };

  const clearCache = () => {
    previousPipes = [];
    cachedFlows = [];
  };

  return { get: getFlows, clear: clearCache };
};

// singleton-ish
export default Flows();
