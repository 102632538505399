import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import produce, { Draft } from 'immer';
import get from 'lodash/get';
import GlobalPipeIcon from '../../../images/icons/global-pipe.svg';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';

import { getEdgeColor, getPathColor, makeUrlParamsFromPath } from 'Internals/graph';

import { getSinkDataset, isGlobal, isNonSystemInputEndpointPipe } from 'Internals/pipes';
import Hub from 'Common/Hub/Hub';
import EndpointNode from 'Common/Hub/EndpointNode';
import EmptyState from 'Common/EmptyState/EmptyState';
import PieHub from '../../components/hub/PieHub';
import { LoadingPanel } from 'Common/LoadingPanel';
import { getIndexDeleted, getIndexWithoutDeleted, getLogDeleted } from 'Internals/datasets';
import { useFlows } from '../../../hooks/useFlows';
import { nameSorter } from 'Internals/utils';
import {
  LocalAggregatedFlows,
  getAngle,
  getDirectionCounts,
  LocalOverviewNode,
  Flow,
} from 'Internals/overview-utils';

import type { DatasetMap } from 'Types/dataset.types';
import type { PipeID, PipeMap, UpstreamMap } from 'Types/pipes.types';
import type { SystemMap } from 'Types/system.types';
import { DownstreamMap, LookupMap } from 'Types/common.types';
import useLoadingState from '../../../hooks/useLoadingState';

function createNodes(
  aggregateFlows: LocalAggregatedFlows,
  pipeId: string,
  pipes: PipeMap,
  subId: string
): LocalOverviewNode[] {
  return Object.keys(aggregateFlows).map((identifier) => {
    return produce({}, (node: Draft<LocalOverviewNode>) => {
      const { kind, flows, direction, systemsInGroup, doesNotExist } = aggregateFlows[identifier];
      node.name = identifier;
      node.flows = flows.map((flow) => {
        const counterpartPipeId = pipeId === flow.from ? flow.to : flow.from;
        return {
          pipeName: pipes[counterpartPipeId] ? pipes[counterpartPipeId].name : '',
          link: `/subscription/${subId}/flows/flow?${makeUrlParamsFromPath(flow.path)}`,
          path: flow.path,
          localDirection: pipeId === flow.from ? 'outbound' : 'inbound',
        };
      });
      node.kind = kind;
      node.direction = direction;
      node.direct = flows.reduce((acc: boolean, f) => acc && f.direct === 'Yes', true);

      node.doesNotExist = doesNotExist;

      if (kind === 'system') {
        // gotta discern if there is only one system or group here
        if (systemsInGroup.length > 1) {
          node.link = `/subscription/${subId}/systems/all?System%20group.selected=${node.name}`;
        } else {
          node.link = `/subscription/${subId}/systems/system/${systemsInGroup[0]}/overview`;
        }
        const systemsPartOfUrl = systemsInGroup.map((s) => `System.selected=${s}`).join('&');
        node.pipesFromLink = `/subscription/${subId}/flows/all?${systemsPartOfUrl}&From.selected=${pipeId}`;
        node.pipesToLink = `/subscription/${subId}/flows/all?${systemsPartOfUrl}&To.selected=${pipeId}`;
      } else if (kind === 'global') {
        node.link = `/subscription/${subId}/pipes/pipe/${node.name}/overview`;
        node.pipesFromLink = `/subscription/${subId}/flows/all?To.selected=${node.name}&From.selected=${pipeId}`;
        node.pipesToLink = `/subscription/${subId}/flows/all?From.selected=${node.name}&To.selected=${pipeId}`;
      } else if (kind === 'endpoint') {
        node.link = `/subscription/${subId}/pipes/all?Source%20type.selected=${node.name}`;
        node.pipesFromLink = `/subscription/${subId}/flows/all?Endpoint%20type.selected=${node.name}&To.selected=${pipeId}`;
        node.pipesToLink = `/subscription/${subId}/flows/all?Endpoint%20type.selected=${node.name}&From.selected=${pipeId}`;
      }

      node.color = getPathColor(
        [...new Set<PipeID>(node.flows.map(({ path }) => path).flat())],
        pipes
      );

      return node;
    });
  });
}

function getAggregateFlows(pipeId: string, systems: SystemMap, flows: Flow[]) {
  let aggregateFlows: LocalAggregatedFlows = {};
  for (const f of flows) {
    let identifier = '';
    let systemId = '';
    let doesNotExist = false; // flag that is set to true if the system doesn't exist
    if (pipeId === f.from || pipeId === f.to) {
      const direction = pipeId === f.to ? 'incoming' : 'outgoing';
      // flow going from this pipe
      if (f.kind === 'system') {
        const system = systems[f.systemId];
        systemId = f.systemId;
        if (system) {
          identifier = system.systemGroup;
        } else {
          // system doesn't exist
          identifier = f.systemId;
          doesNotExist = true;
        }
      } else if (f.kind === 'endpoint') {
        if (f.endpointType !== 'conditional' && f.endpointType !== 'embedded') {
          identifier = f.endpointType;
        } else {
          continue;
        }
      } else if (f.kind === 'global') {
        identifier = pipeId === f.from ? f.to : f.from;
      }
      if (aggregateFlows[identifier]) {
        aggregateFlows[identifier].flows.push(f);
        if (systemId.length > 0 && !aggregateFlows[identifier].systemsInGroup.includes(systemId)) {
          aggregateFlows[identifier].systemsInGroup.push(systemId);
        }
        if (aggregateFlows[identifier].direction !== direction) {
          aggregateFlows[identifier].direction = 'both';
        }
      } else {
        aggregateFlows[identifier] = {
          kind: f.kind,
          direction: direction,
          doesNotExist: doesNotExist,
          systemsInGroup: systemId.length > 0 ? [systemId] : [],
          flows: [f],
        };
      }
    }
  }
  return aggregateFlows;
}

type PipeOverviewProps = {
  params: {
    pipeID: string;
  };
  pipe: Pipe;
  pipes: PipeMap;
  upstreams: UpstreamMap;
  downstreams: DownstreamMap;
  lookups: LookupMap;
  datasets: DatasetMap;
  systems: SystemMap;
  subId: string;
  handleRedirect: boolean;
};

function PipeOverview(props: PipeOverviewProps, context: any) {
  // redirect if this isn't a global
  useEffect(() => {
    if (props.pipe && !isGlobal(props.pipe) && props.handleRedirect) {
      context.router.replace({
        pathname: `/subscription/${props.subId}/pipes/pipe/${encodeURIComponent(
          props.pipe._id
        )}/graph`,
      });
    }
  }, [props.pipe, props.subId, context.router]);

  const { pipes: allPipes, systems, pipe, subId } = props;

  let { flows, finished: flowsComputed, loading: flowsLoading } = useFlows();

  const aggregateFlows = getAggregateFlows(pipe._id, systems, flows);

  const loadingState = useLoadingState();
  const nodes = createNodes(aggregateFlows, pipe._id, allPipes, subId);
  // for the angle calculation
  const directionCounts = getDirectionCounts(nodes);

  // this whole process can be optimized a lot if it becomes slow

  if (loadingState.pipesLoading) {
    return <LoadingPanel loadingMessage={'Loading pipes...'} />;
  }

  if (flowsLoading) {
    return <LoadingPanel loadingMessage={'Loading flows...'} />;
  }

  if (nodes.length < 1 && flowsComputed) {
    return <EmptyState>This global pipe has no flows to systems or other global pipes</EmptyState>;
  }

  const sinkDataset = getSinkDataset(pipe, props.datasets);
  const slices = [
    {
      color: '#529cba', //blue
      name: 'Latest',
      onClick: () => {},
      value: getIndexWithoutDeleted(sinkDataset),
    },
    {
      color: '#b56370', //red
      name: 'Deleted',
      onClick: () => {},
      value: getIndexDeleted(sinkDataset),
    },
    {
      color: '#008580', //green
      name: 'History',
      onClick: () => {},
      value: getLogDeleted(sinkDataset),
    },
  ];

  return (
    <ErrorBoundary>
      <div className="scrollArea">
        <Hub>
          <Hub.Nexus>
            <PieHub slices={slices} overlayColor="white">
              <GlobalPipeIcon className="info-pipe__icon" width="50" height="50" />
              <span>{pipe.name}</span>
            </PieHub>
          </Hub.Nexus>
          <Hub.Wheel>
            {nodes
              .filter((n) => n.direction !== 'outgoing')
              .sort(nameSorter)
              .reverse()
              .map((node, i) => (
                <Hub.Spoke
                  color={node.color}
                  dashed={!node.direct}
                  direction={node.direction}
                  angle={getAngle(i, directionCounts.left)}
                  key={i}
                >
                  <EndpointNode {...node} />
                </Hub.Spoke>
              ))}
            {nodes
              .filter((n) => n.direction === 'outgoing')
              .sort(nameSorter)
              .map((node, i) => (
                <Hub.Spoke
                  color={node.color}
                  dashed={!node.direct}
                  direction={node.direction}
                  angle={getAngle(i, directionCounts.right)}
                  key={i}
                >
                  <EndpointNode {...node} />
                </Hub.Spoke>
              ))}
          </Hub.Wheel>
        </Hub>
      </div>
    </ErrorBoundary>
  );
}

PipeOverview.defaultProps = {
  handleRedirect: true,
};

PipeOverview.contextTypes = {
  router: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    datasets: state.datasets,
    upstreams: state.upstreams,
    downstreams: state.downstreams,
    lookups: state.lookups,
    pipe: state.pipes[ownProps.params.pipeID],
    systems: state.systems,
    pipes: state.pipes,
    subId: state.subscription.id,
  };
}

export default connect(mapStateToProps)(PipeOverview);
