import { push } from 'react-router-redux';
import { handlePortalError } from '../utils';
import get from 'lodash/get';
import UserAPI, {
  UserInfo,
  UserProfileResponse,
  SupportedOperationsResponse,
} from '../../api/user';
import type { AppThunk } from 'Types/state.types';
import { createAction } from '@reduxjs/toolkit';

interface UserProfileData
  extends Pick<
    UserInfo,
    | 'profile'
    | 'user_id'
    | 'announcements'
    | 'approvedTos'
    | 'providerIds'
    | 'providerWhitelist'
    | 'lastCreateTrialRequest'
    | 'isPortalAdmin'
  > {
  supportedOperations: SupportedOperationsResponse;
}

function prepareProfile(response: UserProfileResponse): { payload: UserProfileData } {
  const userData: UserProfileData = {
    profile: response.userInfo.profile,
    user_id: response.userInfo.user_id,
    announcements: response.userInfo.announcements,
    isPortalAdmin: response.userInfo.isPortalAdmin,
    lastCreateTrialRequest: response.userInfo.lastCreateTrialRequest,
    supportedOperations: response['supported-operations'],
    providerIds: response.userInfo.providerIds,
    providerWhitelist: response.userInfo.providerWhitelist,
    approvedTos: response.userInfo.approvedTos,
  };
  return { payload: userData };
}

export const userProfileLoaded = createAction<typeof prepareProfile>(
  'userProfile/loaded',
  prepareProfile
);

export default {
  load(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return UserAPI.get(portalUrl)
        .then((response) => {
          dispatch(userProfileLoaded(response));
          return response;
        })
        .then((resp) => {
          const portal_tos_version = getState().globals.status.tos_version;
          if (!portal_tos_version) {
            // The portal doesnt have the ToS version property, probably old version
            return;
          }
          if (resp.userInfo.approvedTos < portal_tos_version) {
            dispatch(push({ pathname: '/approve-tos' }));
          }
        })
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  changePassword(password: string, old_password: string | null): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return UserAPI.putPassword(portalUrl, password, old_password).catch((error) => {
        if (get(error, 'response.status') === 401) {
          throw error;
        }
        // else fallback to generic error
        return dispatch(handlePortalError(error));
      });
    };
  },

  changeProfile(profileForm: any, userInfo: any): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return UserAPI.put(portalUrl, profileForm, userInfo)
        .then((response) => {
          dispatch(userProfileLoaded(response));
        })
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  deleteAccount(userId: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return UserAPI.remove(portalUrl, userId)
        .then(() => document.location.reload())
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  deleteAccountWithRedirect(userId: string, pathname: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return UserAPI.remove(portalUrl, userId)
        .then(() => dispatch(push({ pathname })))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  requestVerifyEmail(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return UserAPI.requestEmailVerification(portalUrl).catch((error) =>
        dispatch(handlePortalError(error))
      );
    };
  },
};
