import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { buildClassName, ClassNamePropType } from 'Internals/react-utils';

import './style.css';

const HeaderButton = forwardRef(
  ({ children, className, isToggler, isToggled, darkModeActive, testid }, ref) => {
    const classNames = buildClassName(
      darkModeActive ? 'header-button dark' : 'header-button',
      isToggler ? 'header-button--toggler' : null,
      isToggled ? 'header-button--open' : null,
      className
    );

    return (
      <div className={classNames} ref={ref} data-testid={testid}>
        {children}
      </div>
    );
  }
);

HeaderButton.propTypes = {
  children: PropTypes.node,
  className: ClassNamePropType,
  isToggled: PropTypes.bool,
  isToggler: PropTypes.bool,
  darkModeActive: PropTypes.bool,
};

export default HeaderButton;
