import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import Button from 'Common/Button/Button';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import { changelog } from '../changelog/changelog';
import { LogChange } from 'Types/common.types';
import { calculateChangelogDialogAction, ChangelogDialogAction } from 'Utils/changelog.utils';
import { CHANGELOG_BASE_KEY } from 'Constants/commonConstants';
import { ChangeLogButton, ChangeLogDialogContent } from 'Components/changelog/ChangeLog.view';
import { initChangelogService } from 'src/services/changelog.service';
import { useChangelogStyle } from './useChangelogStyle';
import { TestID } from 'src/testID';

type ChangeLogDialogLocalStorageState = {
  lastSeen?: number;
  showUnreadChanges?: boolean;
};

type ChangeLogDialogState = {
  open?: boolean;
  unreadChanges: LogChange[];
  lastSeenTimestamp?: number;
};

const initialChangelogLocalStorage = localStorage.getItem(CHANGELOG_BASE_KEY);

const initialState: ChangeLogDialogLocalStorageState = initialChangelogLocalStorage
  ? (JSON.parse(initialChangelogLocalStorage) as ChangeLogDialogLocalStorageState)
  : {
      lastSeen: -1,
      showUnreadChanges: true,
    };

const changelogService = initChangelogService({
  lastSeen: initialState.lastSeen ?? 0,
  showUnreadChanges: initialState.showUnreadChanges ?? true,
  changelog,
});

export const ChangelogDialogContainer: React.FC<{ darkModeActive: boolean }> = ({
  darkModeActive,
}) => {
  const classes = useChangelogStyle();

  const [openOnUnreadChangesCheck, setOpenOnUnreadChangesCheck] = useState(
    initialState.showUnreadChanges
  );
  const [changelogDialogState, setChangelogDialogState] = useState<ChangeLogDialogState>({
    open: false,
    lastSeenTimestamp: changelogService.getLastSeen(),
    unreadChanges: changelogService.getUnreadLogs(),
  });

  const checkboxRef = useRef<HTMLInputElement>(null);

  const markChangesAsRead = () => {
    const nextLastSeen = changelogService.getLatestLogTimestamp();

    localStorage.setItem(
      CHANGELOG_BASE_KEY,
      JSON.stringify({
        lastSeen: nextLastSeen,
        showUnreadChanges: checkboxRef.current?.checked,
      })
    );

    setChangelogDialogState({
      unreadChanges: [],
      open: false,
      lastSeenTimestamp: nextLastSeen,
    });

    changelogService.setLastSeen(nextLastSeen);
  };

  const shouldOpenDialog = () => {
    const action =
      calculateChangelogDialogAction({
        open: changelogDialogState.open,
        openOnUnreadChanges: openOnUnreadChangesCheck,
        isThereUnreadLogs: changelogDialogState?.unreadChanges?.length > 0,
      }) === ChangelogDialogAction.open;

    setChangelogDialogState({
      ...changelogDialogState,
      open: action,
    });
  };

  useEffect(() => {
    shouldOpenDialog();
  }, []);

  return (
    <>
      <ChangeLogButton
        darkModeActive={darkModeActive}
        showBadge={changelogDialogState?.unreadChanges?.length > 0}
        classes={classes}
        onClick={() => {
          setChangelogDialogState({
            ...changelogDialogState,
            open: true,
          });
        }}
      />
      <Dialog
        open={changelogDialogState.open ?? false}
        onKeyUp={(e) => {
          if (e.key === 'Escape') {
            markChangesAsRead();
          }
        }}
        data-testid={TestID.ChangelogDialog}
      >
        <DialogTitle>What's new?</DialogTitle>
        <Divider />
        <ChangeLogDialogContent
          changelogs={changelogService.getLogsToDisplay()}
          classes={classes}
        />
        <Divider />
        <DialogActions>
          <SesamCheckboxField
            label="Automatically show new entries"
            CheckboxProps={{
              inputRef: checkboxRef,
              checked: openOnUnreadChangesCheck,
              onChange: (ev) => setOpenOnUnreadChangesCheck(ev.target.checked),
            }}
            FormControlLabelProps={{ labelPlacement: 'start' }}
            data-testid={TestID.ChangelogDialogAutoOpenCheckbox}
          />
          <Button
            className={classes.okButton}
            onClick={markChangesAsRead}
            data-testid={TestID.ChangelogDialogCloseButton}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
