import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';

import Button from 'Common/Button/Button';

import moment from 'moment';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from 'Common/SesamTypography';

import SesamKeyboardDatePicker from 'Common/SesamKeyboardDatePicker/SesamKeyboardDatePicker';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import './GdprPurposeFormStyle.css';

const fieldRequired = 'This field is required';
const stringRequired = 'Must be a string';
const numberRequired = 'Must be a number';
const validationSchema = yup.object().shape({
  ['title']: yup.string(stringRequired).required(fieldRequired),
  ['business-process']: yup.string(stringRequired),
  ['description']: yup.string(stringRequired).required(fieldRequired),
  ['detail']: yup.string(stringRequired).required(fieldRequired),
  ['data-source']: yup.string(stringRequired).required(fieldRequired),
  ['data-target']: yup.string(stringRequired).required(fieldRequired),
  ['purpose-type-id']: yup
    .string(stringRequired)
    .oneOf([
      'consent',
      'contract',
      'legal-obligation',
      'vital-interest',
      'public-interest',
      'official-authority',
      'legitimate-interest',
    ]),
  ['legal-days']: yup.number(numberRequired).required(fieldRequired),
  ['version']: yup.number(numberRequired).required(fieldRequired),
  ['lang']: yup.string(stringRequired).required(fieldRequired),
  ['valid-from']: yup.string(stringRequired).nullable(),
  ['valid-to']: yup.string(stringRequired).nullable(),
});

const helpText = {
  ['business-process']:
    'A short description of which high level business process/group the purpose belongs to, for example "Marketing" or "Employment".',
  description:
    'A longer description describing the purpose in more detail. It should be long enough that the end user can understand the purpose. For example "Handling salary information for employees" or "To survey employee satisfaction".',
  ['title']:
    'A short name describing the the purpose at a more detailed level than BusinessProcess, for example if the BusinessProcess is "Employment", it can be "Salary" or "Payroll". It is used as a property heading/label in the end-user interface.',
  ['purpose-type-id']:
    'These are the legal grounds for processing of personal data. Without any of these grounds, the processing is unlawful. In addition to identifying the legal ground set out below, the processing of data must also be necessary in connection with the given legal ground, i.e.: \nConsent \nContract \nLegal obligation \nVital interest \nPublic interest \nOfficial authority \nLegitimate interest',
  ['detail']:
    'The contents/description of the applicable LegalType relevant for the particular case. E.g. contents of the consent: Can we use your e-mailaddress to send you information about GDPR? In case of legal obligation + law: which law is applicable for the neccessary processing of the data in question.',
  ['data-source']: 'Where the data stored is gathered from, and/or how it is gathered.',
  ['data-target']: 'Where you are sending the data to.',
  ['legal-days']: 'The number of days the data stored for this purpose is stored.',
  ['policy-id']: 'A link to the policy description for the purpose.',
  lang: 'The language code of the purpose description.',
  ['valid-from']: 'Specifies from when this purpose definition is valid',
  ['valid-to']: 'Specifies how long this purpose definition is valid',
  version: 'A code specifying the version of this purpose',
};

const GdprPurposeForm = ({
  defaultValues,
  initialValues,
  inModal,
  newPurpose,
  onDelete,
  onSubmit,
  onUpdate,
  onCancel,
  policyOptions,
  purposeTypes,
}) => {
  function handleFocus(e) {
    const target = e.target;
    const name = target.name;

    setCurrentFocus(name);
  }

  const [currentFocus, setCurrentFocus] = useState('');
  const [newAdditionalField, setNewAdditionalField] = useState('');

  return (
    <Formik
      onSubmit={(data) => (newPurpose ? onSubmit(data) : onUpdate(data))}
      initialValues={initialValues || defaultValues}
      validationSchema={validationSchema}
    >
      {({ values, touched, errors, isValid, setFieldValue }) => {
        function addField() {
          setFieldValue(newAdditionalField, '');
          setNewAdditionalField('');
        }

        function handleKeyDown(e) {
          if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.value;
            if (newAdditionalField.length < 1) return;
            setNewAdditionalField('');
            if (Object.keys(values).includes(value)) {
              return;
            }
            addField();
          }
        }

        const isError = (name) => touched[name] && Boolean(errors[name]);
        const getHelperText = (name) => touched[name] && errors[name];

        return (
          <Box width="100%" height="100%">
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>{`${
                        newPurpose ? 'Create new' : 'Edit'
                      } purpose`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="title"
                        render={({ field: { value, ...field } }) => {
                          return (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="text"
                              label="Title *"
                              inputProps={{ autoFocus: true }}
                              error={isError('title')}
                              helperText={getHelperText('title')}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="business-process"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Business process"
                            error={isError('business-process')}
                            helperText={getHelperText('business-process')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="description"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Description *"
                            error={isError('description')}
                            helperText={getHelperText('description')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="detail"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Legal detail *"
                            backgroundColor="#ffffff"
                            error={isError('detail')}
                            helperText={getHelperText('detail')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="purpose-type-id"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              select
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              label="Legal type *"
                              error={isError('purpose-type-id')}
                              helperText={getHelperText('purpose-type-id')}
                            >
                              {purposeTypes.map((c, i) => (
                                <SesamMenuItem key={c.value} value={c.value}>
                                  {c.display}
                                </SesamMenuItem>
                              ))}
                            </SesamTextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="legal-days"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="number"
                              label="Legal days *"
                              error={isError('legal-days')}
                              helperText={getHelperText('legal-days')}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="data-source"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="text"
                              label="Data source *"
                              error={isError('data-source')}
                              helperText={getHelperText('data-source')}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="data-target"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="text"
                              label="Data target *"
                              error={isError('data-target')}
                              helperText={getHelperText('data-target')}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="valid-from"
                          render={({ field: { value, ...field } }) => (
                            <SesamKeyboardDatePicker
                              {...field}
                              TextFieldComponent={(props) => (
                                <SesamTextField margin="normal" {...props} />
                              )}
                              value={value}
                              label="Valid from"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              onChange={(val) => setFieldValue('valid-from', val.format())}
                              onOpen={() => setFieldValue('valid-from', moment().format())}
                              onFocus={handleFocus}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="valid-to"
                          render={({ field: { value, ...field } }) => (
                            <SesamKeyboardDatePicker
                              TextFieldComponent={(props) => (
                                <SesamTextField margin="normal" {...props} />
                              )}
                              {...field}
                              value={value}
                              label="Valid from"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              onChange={(val) => setFieldValue('valid-to', val.format())}
                              onOpen={() => setFieldValue('valid-to', moment().format())}
                              onFocus={handleFocus}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="version"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="number"
                              label="Version"
                              error={isError('version')}
                              helperText={getHelperText('version')}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="lang"
                          render={({ field: { value, ...field } }) => (
                            <SesamTextField
                              {...field}
                              margin="normal"
                              value={value !== null ? value : ''}
                              onFocus={handleFocus}
                              type="text"
                              label="Language *"
                              error={isError('lang')}
                              helperText={getHelperText('lang')}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="policy-id"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            select
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            label="Policy"
                            error={isError('policy-id')}
                            helperText={getHelperText('policy-id')}
                          >
                            {policyOptions.map((c, i) => (
                              <SesamMenuItem key={c.value} value={c.value}>
                                {c.display}
                              </SesamMenuItem>
                            ))}
                          </SesamTextField>
                        )}
                      />
                    </Grid>
                    {Object.keys(values).map((key) => {
                      const firstChar = key.charAt(0);
                      if (
                        firstChar !== '_' &&
                        firstChar !== '$' &&
                        !Object.keys(defaultValues).includes(key)
                      ) {
                        return (
                          <Field
                            name={key}
                            render={({ field: { value, ...field } }) => (
                              <SesamTextField
                                {...field}
                                margin="normal"
                                value={value !== null ? value : ''}
                                onFocus={handleFocus}
                                type="text"
                                label={key}
                                error={isError(key)}
                                helperText={getHelperText[key]}
                              />
                            )}
                          />
                        );
                      }
                    })}
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box pt={1}>
                        <Typography variant="h6" gutterBottom>
                          Add more fields
                        </Typography>
                      </Box>

                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box mr={1} flex="1">
                          <SesamTextField
                            margin="none"
                            label="Field id"
                            onChange={(e) => {
                              const value = e.target.value;
                              setNewAdditionalField(value);
                            }}
                            onKeyDownCapture={handleKeyDown}
                            value={newAdditionalField}
                          />
                        </Box>
                        <Button
                          type="button"
                          size="big"
                          onClick={() => addField()}
                          disabled={newAdditionalField.length < 1}
                        >
                          Add
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
              <Grid item xs>
                <Box display="flex" height="100%" alignItems="center">
                  <Divider orientation="vertical" />
                  <Box pl={3}>
                    <Typography variant="body1">
                      {currentFocus ? helpText[currentFocus] : ''}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Form>
                <Box display="flex" flexDirection="row-reverse">
                  <Box ml={1}>
                    <Button type="button" onClick={onCancel} size="big" text="Cancel" />
                  </Box>
                  {!newPurpose && (
                    <Box ml={1}>
                      <Button
                        type="button"
                        onClick={() => onDelete(values)}
                        size="big"
                        text="Delete"
                        theme="danger"
                      />
                    </Box>
                  )}
                  {newPurpose ? (
                    <Button text="Add new policy" size="big" disabled={!isValid} />
                  ) : (
                    <Button text="Save changes" size="big" disabled={!isValid} />
                  )}
                </Box>
              </Form>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

GdprPurposeForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  newPurpose: PropTypes.bool,
  inModal: PropTypes.bool,
  purposeTypes: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  policyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

GdprPurposeForm.defaultProps = {
  inModal: true,
  newPurpose: false,
};

export default GdprPurposeForm;
