import cloneDeep from 'lodash/cloneDeep';
// @ts-ignore
import debounceAction from 'debounce-action';

import UserProfileActions from './user-profile';
import { handlePortalError, clone } from '../utils';
import { apologise } from './apology';
import { SubscriptionsState } from '../reducers/subscriptions';
import get from 'lodash/get';
import set from 'lodash/set';
import DatahubAPI from '../../api/datahub';
import JwtAPI from '../../api/jwt';
import SubscriptionsAPI from '../../api/subscriptions';
import type { AppThunk, AppDispatch, RootState, GetStateFn } from 'Types/state.types';
import { SomeObject, Optional } from 'Types/common.types';
import { createAction } from '@reduxjs/toolkit';

type RootState_ = RootState | (() => RootState);

// Makes it possible to pass both 'state' & 'getState'
function getStateObject(s: RootState_) {
  if (typeof s === 'function') return s();
  return s;
}

export function getSubUrl(state: RootState_) {
  return getStateObject(state).subscription.url;
}
export function getSubToken(state: RootState_) {
  return getStateObject(state).subscription.token;
}
export function getApiConf(state: RootState_) {
  return {
    subUrl: getStateObject(state).subscription.url,
    token: getStateObject(state).subscription.token,
  };
}
export const allSubsLoaded = createAction<SomeObject[]>('subscription/loadedAll');
export const subUpdated = createAction<SomeObject>('subscription/updated');
export const subLoaded = createAction<SomeObject>('subscription/loaded');
export const subSwitched =
  createAction<{ subscriptionId: string; defaultConnectionUrl: string }>('subscription/switched');
export const subCreated = createAction<SomeObject>('subscription/created');
export const subRemoved = createAction<string>('subscription/left');

export const connectionLogUpdated = createAction<{
  event: string;
  timestamp: Date;
}>('subscription/updateConnectionLog');

export const membersLoaded = createAction<SomeObject[]>('subscription/membersLoaded');

export const memberUpdated = createAction<{
  member: SomeObject;
  subId: string;
  isCurrentUser: boolean;
}>('subscription/memberUpdated');

export const memberRemoved = createAction<{
  member: SomeObject;
  subId: string;
  isCurrentUser: boolean;
}>('subscription/memberRemoved');

export const memberInvited = createAction<SomeObject>('subscription/memberInvited');
export const memberReinvited = createAction<SomeObject>('subscription/memberReinvited');

export const tooManySubscriptionsFound = createAction('subscription/tooManyFound');
export const connectionFailed = createAction<string>('subscription/connectionFailed');
export const disconnected = createAction<boolean>('subscription/disconnected');
export const connectAttemptStarted = createAction('subscription/connectAttemptStarted');
export const connectAttemptFinished = createAction<{
  url: string;
  token: string;
}>('subscription/connectAttemptFinished');
export const connectAttemptFailed = createAction<{
  status: string;
  url: string;
}>('subscription/connectAttemptFailed');

export const availableRolesLoaded = createAction<SomeObject[]>('subscription/rolesLoaded');

export const subRoleRemoved = createAction<SomeObject>('subscription/roleRemoved');
export const subRoleAdded = createAction<SomeObject>('subscription/roleAdded');
export const subRoleUpdated = createAction<SomeObject>('subscription/roleUpdated');

export function findDefaultUrl(sub: Optional<SomeObject>) {
  if (!sub) {
    return null;
  }
  const defaultConnection = sub.connections.filter((c: SomeObject) => c.id === 'default');
  if (!defaultConnection.length) {
    return null;
  }
  return defaultConnection[0].url;
}

export function getSubName(sub: SomeObject) {
  return sub ? sub.name : '';
}

export function isProvisioning(sub: SomeObject) {
  const service = get(sub, 'service');
  const provisioningStatus = get(sub, 'provisioning_status');
  return (
    service === 'in-cloud' &&
    (provisioningStatus === 'pending' || provisioningStatus === 'provisioning')
  );
}

export function hasProvisioningFailed(sub: SomeObject) {
  const service = get(sub, 'service');
  const provisioningStatus = get(sub, 'provisioning_status');
  return service === 'in-cloud' && provisioningStatus === 'failed';
}

export function isCloudInstance(sub: SomeObject) {
  return sub.service === 'in-cloud';
}

export function findSub(state: SubscriptionsState) {
  return function (id: string) {
    return state.find((s) => s.id === id);
  };
}

export function currentSub(state: RootState) {
  return findSub(state.subscriptions)(state.subscription.id);
}

export function cloneSubById(id: string, getState: () => RootState) {
  return cloneDeep(getState().subscriptions.find((s) => s.id === id));
}

export function getCurrentSub(state: RootState) {
  return findSub(state.subscriptions)(state.subscription.id);
}

// DEPRECATED: This works with only a static state; if it changes, the checker
// function won't provide an updated overview. Use the "valuesFromOperations"
// (below) and "propTypesFromOperations" (in model-props) to set up props
// directly instead.
export function makePermissionCheckerForCurrentSub(state: RootState) {
  return function (permissionId: string) {
    const currentSub = getCurrentSub(state);
    const permission = currentSub && currentSub['supported-operations'][permissionId];
    return permission && permission.enabled;
  };
}

// DEPRECATED: This works with only a static state; if it changes, the checker
// function won't provide an updated overview. Use the "valuesFromOperations"
// (below) and "propTypesFromOperations" (in model-props) to set up props
// directly instead, or "currentUserHasRole"/"currentUserIsAdmin", which are not
// bound to a specific state.
export function makeRoleCheckerForCurrentSub(state: RootState) {
  return function (roleId: string) {
    const currentSub = getCurrentSub(state);
    return currentSub && currentSub['current-users-member'].roles.includes(roleId);
  };
}

/**
 * Creates a tailored object from a subscription's operations, suitable for use
 * as props for a React component via Redux's `connect()`. This is useful to
 * change a component based on the permissions a user has in a given
 * subscription.
 *
 * If a component uses other Redux-derived props than just the operations, it's
 * possible to join them using `Object.assign` (see example).
 *
 * To include the props provided by this function in a component's PropTypes,
 * use the `propTypesFromOperations` function.
 *
 * @example
 * const operations = ['delete_subscription', 'modify_subscription'];
 * function mapStateToProps(state) {
 *   const sub = getCurrentSub(state);
 *   return Object.assign(valuesFromOperations(sub, operations), {
 *     connected: state.subscription.connected,
 *     someProp: state.someValue,
 *   });
 * };
 *
 * @param {object} sub The subscription object, from the Redux store
 * @param {array} ops An array of operation names (strings), like
 *                    'accept_invitation', 'renew_license', etc
 */
export function valuesFromOperations(sub: SomeObject, ops: string[]) {
  const values: SomeObject = {};
  ops.forEach((op) => {
    values[`can_${op}`] = sub['supported-operations'][op];
  });
  return values;
}

/**
 * Checks if the current user has a given role in the current subscription
 * @param  {object} state  The Redux state
 * @param  {string} roleId ID of the state, e.g. 'accept_invitation'
 * @return {bool}
 */
export function currentUserHasRole(state: RootState, roleId: string) {
  const sub = getCurrentSub(state);
  return sub && sub['current-users-member'].roles.includes(roleId);
}

/**
 * Checks if the current user is an administrator in the current subscription
 * @param  {object} state The Redux state
 * @return {bool}
 */
export function currentUserIsAdmin(state: RootState) {
  return currentUserHasRole(state, 'group:Admin');
}

/**
 * Whether this an invite to join the subscription
 * @param {object} sub The subscription object
 * @returns {bool}
 */
export function isInvite(sub: SomeObject) {
  return get(sub, 'supported-operations.accept_invitation.enabled', false);
}

function persistSubscription(sub: SomeObject): AppThunk {
  return function (dispatch, getState) {
    const portalUrl = getState().globals.portalUrl;
    return SubscriptionsAPI.put(portalUrl, sub)
      .then((updatedSub) => {
        dispatch(subUpdated(sub));
        return updatedSub;
      })
      .then((updatedSub) => dispatch(subUpdated(updatedSub)))
      .catch((error) => dispatch(handlePortalError(error)));
  };
}

function persistSubscriptionIgnoreProvisioning(sub: SomeObject): AppThunk {
  return function (dispatch, getState) {
    const portalUrl = getState().globals.portalUrl;
    return SubscriptionsAPI.put(portalUrl, sub)
      .then((updatedSub) => {
        dispatch(subUpdated(sub));
        return updatedSub;
      })
      .then((updatedSub) => {
        // TODO tmp hack to prevent domain name changes from flipping the user into wait for provisioner
        updatedSub.provisioning_status = 'completed';
        dispatch(subUpdated(updatedSub));
      })
      .catch((error) => dispatch(handlePortalError(error)));
  };
}

function refreshSubscription(subId: string): AppThunk {
  return function (dispatch, getState) {
    const portalUrl = getState().globals.portalUrl;
    return SubscriptionsAPI.get(portalUrl, subId)
      .then((sub) => dispatch(subUpdated(sub)))
      .catch((error) => dispatch(handlePortalError(error)));
  };
}

function updateConnectionLog(event: string): AppThunk {
  return function (dispatch) {
    return dispatch(
      connectionLogUpdated({
        event,
        timestamp: new Date(),
      })
    );
  };
}

function putMember(subId: string, member: SomeObject): AppThunk {
  return function (dispatch, getState) {
    const portalUrl = getState().globals.portalUrl;
    const action = member.roles.length > 0 ? memberUpdated : memberRemoved;

    return SubscriptionsAPI.putMember(portalUrl, subId, member)
      .then((updatedMemberFromServer) => {
        const actionData = {
          member: updatedMemberFromServer,
          subId,
          isCurrentUser: getState().user.user_id === member.user.user_id,
        };

        dispatch(action(actionData));
      })
      .catch((error) => dispatch(handlePortalError(error)));
  };
}

function getCurrentUserMembership(getState: () => RootState) {
  return function (subId: string) {
    const state = getState();
    const sub = findSub(state.subscriptions)(subId);
    return sub['current-users-member'];
  };
}

function isNotInternalRole(r: string) {
  return r !== 'group:Invited' && r !== 'group:DeclinedInvitation' && r !== 'group:Disabled';
}

const thunks = {
  loadAll: debounceAction(
    function (): AppThunk {
      return function (dispatch, getState) {
        const portalUrl = getState().globals.portalUrl;
        return SubscriptionsAPI.getAll(portalUrl)
          .then((subscriptions) => dispatch(allSubsLoaded(subscriptions)))
          .catch((error) => dispatch(handlePortalError(error)));
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  reload(subId: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.get(portalUrl, subId)
        .then((subscription) => dispatch(subLoaded(subscription)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  connect(id: string): AppThunk {
    return (dispatch, getState) => {
      const sub = getState().subscriptions.find((s) => s.id === id);
      const persistedURL = getState().customConnectionURLs[id];

      if (!sub) {
        dispatch(apologise(`No subscription with id: ${id}`));
        // promise not fulfilled
        throw new Error('unknown_sub');
      } else {
        const defaultConnectionUrl = findDefaultUrl(sub);

        dispatch(subSwitched({ subscriptionId: id, defaultConnectionUrl }));

        const defaultUrl = persistedURL || defaultConnectionUrl;

        if (!defaultUrl) {
          const error = 'no_default_connection';
          dispatch(connectionFailed(error));
          throw new Error(error);
        }

        return dispatch(this.attemptConnect(defaultUrl));
      }
    };
  },

  disconnect(unselectId = false): AppThunk {
    return (dispatch) => {
      dispatch(disconnected(unselectId));
      dispatch(updateConnectionLog('disconnected'));
    };
  },

  attemptConnectV2(subscriptionId: string, url: string) {
    return async (dispatch: AppDispatch, getState: GetStateFn) => {
      dispatch(connectAttemptStarted());

      try {
        const preStatus = await DatahubAPI.checkIsNode(url);

        if (preStatus !== 'okay') {
          throw Error(preStatus);
        }

        const jwtToken = await JwtAPI.get(getState().globals.portalUrl, subscriptionId);
        const licenseStatus = await DatahubAPI.getLicenseStatus(url, jwtToken, subscriptionId);

        dispatch(connectAttemptFinished({ url, token: jwtToken }));

        return licenseStatus;
      } catch (error: unknown) {
        // dispatch(apologise(`Something unexpected happened when connecting ${url}: ${error}`));
        throw Error(error as string);
      }
    };
  },

  attemptConnect(url: string): AppThunk {
    // TODO: This needs simplifying; it's possible to squash this logic
    return (dispatch, getState) => {
      dispatch(connectAttemptStarted());
      const id = getState().subscription.id;
      dispatch(updateConnectionLog(`connecting to ${url}`));

      // returns information about the sesam portal.
      return DatahubAPI.checkIsNode(url)
        .then((preStatus) => {
          if (preStatus !== 'okay') {
            dispatch(updateConnectionLog(preStatus));
            return preStatus;
          }

          dispatch(updateConnectionLog('generating_jwt_token'));

          return (
            // returns the JSON Web Token for the currently logged in user.
            JwtAPI.get(getState().globals.portalUrl, id)
              // unable to create JWT token, this is a portal problem
              .then((token) => {
                dispatch(updateConnectionLog('checking_license'));
                return DatahubAPI.getLicenseStatus(url, token, id).then((status) => {
                  dispatch(updateConnectionLog(`license_status: ${status}`));
                  dispatch(connectAttemptFinished({ url, token }));
                  return status;
                });
              })
              .catch((error) => {
                dispatch(handlePortalError(error));
                return 'Error getting jwt';
              })
          );
        })
        .catch((error) => {
          // TODO: Rendering errors are getting swallowed here if they happen during the
          //       connection process (i.e. not logged in console, but generation a call
          //       to `apologyse()`). They should bubble up to the browser handler.
          // something unexpected happened when talking to the node
          dispatch(apologise(`Something unexpected happened when connecting ${url}: ${error}`));
          throw error;
        })
        .then((status) => {
          if (status !== 'okay') {
            dispatch(connectAttemptFailed({ status, url }));
            // promise is not fulfilled, so let's throw something
            throw new Error(status);
          }
          return status;
        });
    };
  },

  storeUrl(id: string, url: string): AppThunk {
    return function (dispatch, getState) {
      const sub = cloneSubById(id, getState);
      sub.connections = sub.connections
        .filter((c) => c.id !== 'default')
        .concat({ name: 'Default', id: 'default', type: 'sesam-node', url });
      return dispatch(persistSubscription(sub));
    };
  },

  updateBasics(id: string, name: string, description: string, url: string): AppThunk {
    return function (dispatch, getState) {
      const sub = cloneSubById(id, getState);
      sub.name = name;
      sub.description = description;
      sub.url = url;
      return dispatch(persistSubscription(sub));
    };
  },

  updateTag(id: string, tag: string): AppThunk {
    return (dispatch, getState) => {
      const sub = cloneSubById(id, getState);
      set(sub, 'products.datahub.version', tag);
      return dispatch(persistSubscription(sub));
    };
  },

  renewLicense(id: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.postLicense(portalUrl, id)
        .then((sub) => dispatch(subUpdated(sub)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  checkDomain(domain: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      const subId = getState().subscription.id;
      let resolvedDomain = domain;
      if (domain.indexOf('.') === -1) {
        resolvedDomain = domain + '.sesamdata.com';
      }
      return SubscriptionsAPI.checkDomain(portalUrl, subId, resolvedDomain)
        .then((response) => ({
          available: response.available,
          resolvedDomain,
        }))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  updateDomain(domain: string): AppThunk {
    return function (dispatch, getState) {
      const subId = getState().subscription.id;
      const sub = cloneSubById(subId, getState);
      let domains = get(sub.products.gdpr_platform, 'custom_fqdn', []);
      if (domains.length > 0) {
        domains[0] = domain;
      } else {
        domains = [domain];
      }
      sub.products.gdpr_platform.custom_fqdn = domains;
      return dispatch(persistSubscriptionIgnoreProvisioning(sub));
    };
  },

  updateProducts(id: string, products: any, paymentMethodId: string): AppThunk {
    return function (dispatch, getState) {
      const sub = cloneSubById(id, getState);
      sub.products = products;
      sub.paymentmethod_id = paymentMethodId;
      if (paymentMethodId) {
        sub.type = 'paid';
      }
      return dispatch(persistSubscription(sub));
    };
  },

  updateVpn(subId: string, products: object): AppThunk {
    return function (dispatch, getState) {
      const sub = cloneSubById(subId, getState);
      sub.products = products;
      return dispatch(persistSubscription(sub));
    };
  },

  requestTrial(data: SomeObject): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.requestTrial(portalUrl, data)
        .then((response) => {
          // Reload the user's profile to update the gui
          dispatch(UserProfileActions.load());
          return response;
        })
        .catch((error) => {
          dispatch(handlePortalError(error));
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  cancelRequestTrial(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.cancelRequestTrial(portalUrl)
        .then((response) => {
          // Reload the user's profile to update the gui
          dispatch(UserProfileActions.load());
          return response;
        })
        .catch((error) => {
          dispatch(handlePortalError(error));
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  dismissDeniedRequestTrial(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.dismissDeniedRequestTrial(portalUrl)
        .then((response) => {
          // Reload the user's profile to update the gui
          dispatch(UserProfileActions.load());
          return response;
        })
        .catch((error) => {
          dispatch(handlePortalError(error));
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  createSubscription(data: SomeObject): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.post(portalUrl, data)
        .then((sub) => {
          dispatch(subCreated(sub));
          return sub.id;
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            dispatch(tooManySubscriptionsFound());
          } else {
            dispatch(handlePortalError(error));
          }
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  loadMembers(subId: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.getMembers(portalUrl, subId)
        .then((members) => dispatch(membersLoaded(members)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  loadAvailableRoles(subId: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.getRoles(portalUrl, subId)
        .then((roles) => dispatch(availableRolesLoaded(roles)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  invite(subId: string, email: string, role: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      const invite = {
        email,
        // need to include at least one role in order to prevent the user from leaving
        // the sub when accepting the invite
        roles: [role],
      };
      return SubscriptionsAPI.postMember(portalUrl, subId, invite)
        .then((member) => dispatch(memberInvited(member)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  remove(subId: string, member: SomeObject): AppThunk {
    return function (dispatch) {
      const updatedMember = clone(member);
      updatedMember.roles = [];
      return dispatch(putMember(subId, updatedMember));
    };
  },

  reinvite(subId: string, member: SomeObject): AppThunk {
    return function (dispatch, getState) {
      const invite = {
        email: member.user.email,
        roles: member.roles.filter(isNotInternalRole),
      };

      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.postMember(portalUrl, subId, invite)
        .then(() => dispatch(memberReinvited(member)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  block(subId: string, member: SomeObject): AppThunk {
    return function (dispatch) {
      const updatedMember = clone(member);
      updatedMember.roles = member.roles.concat('group:Disabled');
      return dispatch(putMember(subId, updatedMember));
    };
  },

  unblock(subId: string, member: SomeObject): AppThunk {
    return function (dispatch) {
      const updatedMember = clone(member);
      updatedMember.roles = member.roles.filter((r) => r !== 'group:Disabled');
      return dispatch(putMember(subId, updatedMember));
    };
  },

  updateRoles(subId: string, member: SomeObject, roles: SomeObject[]): AppThunk {
    return function (dispatch) {
      const updatedMember = clone(member);
      updatedMember.roles = roles;
      return dispatch(putMember(subId, updatedMember));
    };
  },

  leave(subId: string): AppThunk {
    return function (dispatch, getState) {
      const member = getCurrentUserMembership(getState)(subId);
      const updatedMember = clone(member);
      updatedMember.roles = [];
      return dispatch(putMember(subId, updatedMember)).then(() => dispatch(subRemoved(subId)));
    };
  },

  accept(subId: string): AppThunk {
    return function (dispatch, getState) {
      const member = getCurrentUserMembership(getState)(subId);
      const updatedMember = clone(member);
      updatedMember.roles = member.roles.filter(isNotInternalRole);
      return dispatch(putMember(subId, updatedMember)).then(() =>
        dispatch(refreshSubscription(subId))
      );
    };
  },

  decline(subId: string): AppThunk {
    return function (dispatch, getState) {
      const member = getCurrentUserMembership(getState)(subId);
      const updatedMember = clone(member);
      updatedMember.roles = member.roles
        .filter(isNotInternalRole)
        .concat('group:DeclinedInvitation');
      return dispatch(putMember(subId, updatedMember)).then(() => dispatch(subRemoved(subId)));
    };
  },

  delete(subId: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.remove(portalUrl, subId)
        .then(() => dispatch(subRemoved(subId)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  removeRole(subId: string, role: SomeObject): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.removeRole(portalUrl, subId, role.id)
        .then(() => dispatch(subRoleRemoved(role)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  addRole(subId: string, role: SomeObject): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.postRole(portalUrl, subId, role)
        .then((roleResponse) => dispatch(subRoleAdded(roleResponse)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  updateRole(subId: string, role: SomeObject): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return SubscriptionsAPI.updateRole(portalUrl, subId, role)
        .then((roleResponse) => dispatch(subRoleUpdated(roleResponse)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  updateNetworkAcl(id: string, acl: any[], publicAccess: boolean): AppThunk {
    return function (dispatch, getState) {
      const sub = cloneSubById(id, getState);
      sub.network['network-acl'] = acl;
      sub.network['public-access'] = publicAccess;
      return dispatch(persistSubscription(sub));
    };
  },

  calcPrice(subId: string, products: SomeObject, expectedDataSize?: number): AppThunk {
    return function (dispatch, getState) {
      const subscription = cloneDeep(getState().subscriptions.find((s) => s.id === subId));
      const portalUrl = getState().globals.portalUrl;
      const priceBasis = {
        products,
        type: subscription.type,
      };

      return SubscriptionsAPI.getPrice(portalUrl, subId, priceBasis, expectedDataSize).catch(
        (error) => dispatch(handlePortalError(error))
      );
    };
  },

  calcNewPrice(products: SomeObject, service: any, type: any, expectedDataSize?: number): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      const priceBasis = {
        products,
        service,
        type,
      };

      return SubscriptionsAPI.getPriceFull(portalUrl, priceBasis, expectedDataSize).catch((error) =>
        dispatch(handlePortalError(error))
      );
    };
  },

  refresh(subId: string): AppThunk {
    return (dispatch) => {
      dispatch(refreshSubscription(subId));
    };
  },
};

export default thunks;
