import { SomeObject } from 'Types/common.types';
import sfetch, { sfetchJson } from 'Internals/sfetch';
import isUndefined from 'lodash/isUndefined';

async function get(portalUrl: string, subId: string) {
  const options: RequestInit = { credentials: 'include' };
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}`, options);
}

async function getAll(portalUrl: string) {
  return await sfetchJson(`${portalUrl}/subscriptions`, {
    credentials: 'include',
  });
}

async function post(portalUrl: string, data: SomeObject) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: data.name,
      description: data.description,
      url: data.url,
      service: data.service,
      products: data.products,
      paymentmethod_id: data.paymentMethodId,
      type: data.type || 'paid',
    }),
  };

  return await sfetchJson(`${portalUrl}/subscriptions`, options);
}

async function put(portalUrl: string, sub: SomeObject) {
  const options: RequestInit = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(sub),
  };
  return await sfetchJson(`${portalUrl}/subscriptions/${sub.id}`, options);
}

async function remove(portalUrl: string, subId: string) {
  const options: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  return await sfetch(`${portalUrl}/subscriptions/${subId}`, options);
}

async function putMember(portalUrl: string, subId: string, member: SomeObject) {
  const options: RequestInit = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(member),
  };
  return await sfetchJson(
    `${portalUrl}/subscriptions/${subId}/members/${member.user.user_id}`,
    options
  );
}

async function getMembers(portalUrl: string, subId: string) {
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}/members`, {
    credentials: 'include',
  });
}

type InviteInfo = {
  email: string;
  roles: string[];
};
async function postMember(portalUrl: string, subId: string, invite: InviteInfo) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(invite),
  };
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}/members`, options);
}

async function getRoles(portalUrl: string, subId: string) {
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}/available-roles`, {
    credentials: 'include',
  });
}

async function postRole(portalUrl: string, subId: string, role: SomeObject) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(role),
  };
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}/available-roles`, options);
}

async function updateRole(portalUrl: string, subId: string, role: SomeObject) {
  const options: RequestInit = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(role),
  };
  return await sfetchJson(
    `${portalUrl}/subscriptions/${subId}/available-roles/${role.id}`,
    options
  );
}

async function removeRole(portalUrl: string, subId: string, roleId: string) {
  const options: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  return await sfetch(`${portalUrl}/subscriptions/${subId}/available-roles/${roleId}`, options);
}

async function postLicense(portalUrl: string, id: string) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  return await sfetchJson(`${portalUrl}/subscriptions/${id}/licenses`, options);
}

async function checkDomain(portalUrl: string, subId: string, domain: string) {
  const url = `${portalUrl}/provisioning/domain?fqdn=${encodeURI(
    domain
  )}&subscription_id=${encodeURIComponent(subId)}`;
  return await sfetchJson(url, { credentials: 'include' });
}

async function requestTrial(portalUrl: string, data: SomeObject) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return await sfetchJson(`${portalUrl}/request_trial`, options);
}

async function cancelRequestTrial(portalUrl: string) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };
  return await sfetchJson(`${portalUrl}/cancel_request_trial`, options);
}

async function dismissDeniedRequestTrial(portalUrl: string) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };
  return await sfetchJson(`${portalUrl}/dismiss_denied_request_trial`, options);
}

type PriceBasis = {
  products: SomeObject;
  type: string;
  service?: SomeObject;
};
async function getPrice(
  portalUrl: string,
  subId: string,
  priceBasis: PriceBasis,
  expectedDataSize?: number
) {
  // might want to move the logic of creating price basis here,
  // right now it's in the calcPrice redux action
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(priceBasis),
  };

  let url = `${portalUrl}/subscriptions/${subId}/estimate-subscription-price`;

  if (!isUndefined(expectedDataSize)) {
    url = url + `?expected_data_size=${expectedDataSize}`;
  }

  return await sfetchJson(url, options);
}

async function getPriceFull(portalUrl: string, priceBasis: PriceBasis, expectedDataSize?: number) {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(priceBasis),
  };

  let url = `${portalUrl}/estimate-subscription-price`;

  if (!isUndefined(expectedDataSize)) {
    url = url + `?expected_data_size=${expectedDataSize}`;
  }

  return await sfetchJson(url, options);
}

export default {
  put,
  post,
  get,
  getAll,
  remove,
  putMember,
  getMembers,
  postMember,
  getRoles,
  postRole,
  updateRole,
  removeRole,
  postLicense,
  checkDomain,
  requestTrial,
  cancelRequestTrial,
  dismissDeniedRequestTrial,
  getPrice,
  getPriceFull,
};
