import { PaymentMethodAPIPayload } from 'Types/paymentMethod.types';
import {
  paymentMethodCreated,
  paymentMethodRemoved,
  paymentMethodsLoaded,
  paymentMethodUpdated,
} from '../thunks/payment-methods';
import { createReducer } from '@reduxjs/toolkit';

type PaymentMethodsState = PaymentMethodAPIPayload[];

const initialState: PaymentMethodsState = [];

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(paymentMethodsLoaded, (state, action) => {
      return action.payload;
    })
    .addCase(paymentMethodRemoved, (state, action) => {
      const index = state.findIndex((pm: PaymentMethodAPIPayload) => pm.id === action.payload);
      if (index !== -1) state.splice(index, 1);
    })
    .addCase(paymentMethodCreated, (state, action) => {
      const index = state.findIndex((pm: PaymentMethodAPIPayload) => pm.id === action.payload.id);
      if (index !== -1) state.splice(index, 1);
      state.unshift(action.payload);
    })
    .addCase(paymentMethodUpdated, (state, action) => {
      const index = state.findIndex((pm: PaymentMethodAPIPayload) => pm.id === action.payload.id);
      if (index !== -1) state.splice(index, 1);
      state.unshift(action.payload);
    })
);
