import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import sum from 'lodash/sum';
import { notEmpty, visit } from './utils';
import { getLookupPipes } from './datasets';
import memoize from 'memoize-one';
import { DownstreamMap, LookupMap, Optional } from 'Types/common.types';
import type { DatasetID, DatasetMap } from 'Types/dataset.types';
import type { PipeMap, Pipe, UpstreamMap, PipeResponse, PipeConfig } from 'Types/pipes.types';
import type { System, SystemID } from 'Types/system.types';

/**
 * Retrieves available actions for a pipe or for an intersection of pipes
 * @param {object|array} pipeOrPipes pipe object or array of pipe objects
 * @returns {array} of action strings
 */
export function getAvailableActions(pipeOrPipes: Pipe | Pipe[]) {
  const pipes = Array.isArray(pipeOrPipes) ? pipeOrPipes : [pipeOrPipes];

  const statuses = flow(map('actionStatus.status'), flatten, uniq)(pipes);
  const supportedOperations = flow(map('runtime.supported-operations'), flatten, uniq)(pipes);

  return Array.from(supportedOperations).filter((val) => {
    if (val === 'update-last-seen') {
      return !statuses.includes('update-last-seen');
    } else {
      return true;
    }
  });
}

/**
 * Finds the dataset objects which correspond to the
 * dataset this pipes looks up/hops to
 * @param {Pipe} pipe object
 * @param {DatasetMap} allDatasets dataset objects
 * @returns {array} dataset objects
 */
export function getLookupDatasets(pipe: Pipe, allDatasets: DatasetMap) {
  const collectedDatasets = collectDatasets(pipe);
  return Array.from(new Set(collectedDatasets))
    .map((id) => allDatasets[id])
    .filter(Boolean);
}

/**
 * Gets all pipe objects whose sinks this pipe
 * looks up/hops to
 * @param {Pipe} pipe object
 * @param {object} allPipes objects
 * @param {object} upstreams
 * @returns {array} pipe objects
 */
export function getUpstreamLookups(pipe: Pipe, allPipes: PipeMap, upstreams: UpstreamMap) {
  const datasetIds = collectDatasets(pipe);
  return uniq(datasetIds)
    .map((datasetId) => getUpstreamPipeFromDatasetId(datasetId, allPipes, upstreams))
    .filter(notEmpty);
}

/**
 * Gets all pipe objects which hop to/look up
 * to the dataset produced by this pipe
 * @param {Pipe} pipe object
 * @param {Pipe[]} allPipes objects
 * @param {object} lookups
 * @returns {array} pipe objects
 */
export function getDownstreamLookups(pipe: Pipe, allPipes: PipeMap, lookups: LookupMap) {
  const sinkDatasetId = getSinkDatasetId(pipe);
  if (!sinkDatasetId) return [];
  const datasetLookups = get(lookups, sinkDatasetId, {});
  return Object.keys(datasetLookups)
    .map((id) => allPipes[id])
    .filter(Boolean);
}

/**
 * Retrieves ids of source datasets for a pipe
 * @param {Pipe} pipe object
 * @returns {string[]} of dataset ids
 */
export function getSourceDatasetIds(pipe: Pipe | PipeResponse): DatasetID[] {
  const source = get(pipe, 'config.effective.source', {});
  const replicaSource = pipe?.config?.original?.metadata?.dataset;
  if (replicaSource) return [replicaSource];
  else if (source.dataset) return [source.dataset];
  else if (source.datasets && Array.isArray(source.datasets))
    return source.datasets.map((d: DatasetID) => d.split(' ')[0]);
  else return [];
}

export function getSourceDatasetIdsFromPipeConfig(
  pipeConfig: PipeConfig | PipeResponse
): DatasetID[] {
  const source = get(pipeConfig, 'effective.source', {});
  const replicaSource = pipeConfig?.original?.metadata?.dataset;
  if (replicaSource) return [replicaSource];
  else if (source.dataset) return [source.dataset];
  else if (source.datasets && Array.isArray(source.datasets))
    return source.datasets.map((d: DatasetID) => d.split(' ')[0]);
  else return [];
}

/**
 * Gets the dataset objects that this pipe uses as source
 * @param {Pipe} pipe object
 * @param {DatasetMap} allDatasets dataset objects
 * @returns {array} dataset objects
 */
export const getSourceDatasets = (pipe: Pipe, allDatasets: DatasetMap) => {
  const ids = getSourceDatasetIds(pipe);
  return ids.map((id) => allDatasets[id]).filter(Boolean);
};

export const getSourceDatasetsMemoized = memoize(getSourceDatasets);

/**
 * Gets the dataset object that this pipe uses as sink
 * @param {object} pipe object
 * @param {object} allDatasets dataset objects lookup table
 * @returns {object|undefined} dataset object or undefined if not found
 */
export function getSinkDataset(pipe: Pipe, allDatasets: DatasetMap) {
  const sinkDatasetId = getSinkDatasetId(pipe);
  if (!sinkDatasetId) return undefined;
  return allDatasets[sinkDatasetId];
}

export const getSinkDatasetMemoized = memoize(getSinkDataset);

/**
 * Gets the sink dataset id from pipe object
 * @param {Pipe} pipe object
 * @returns {?string} dataset id or undefined if no such dataset
 */
export function getSinkDatasetId(pipe: Pipe): Optional<string> {
  return get(pipe, 'config.effective.sink.dataset');
}

/**
 * Gets the source system id from pipe object if it
 * exists and isn't sesam-node
 * @param {Pipe} pipe object
 * @returns {string|null} system id or undefined if no such system
 */
export function getSourceSystemId(pipe: Pipe | PipeResponse): Optional<SystemID> {
  const system = get(pipe, 'config.effective.source.system');
  if (system && !system.startsWith('system:sesam-node')) return system;
  else return null;
}

/**
 * Gets the sink system id from pipe object if it
 * exists and isn't sesam-node
 * @param {object} pipe object
 * @returns {string|null} system id or undefined if no such system
 */
export function getSinkSystemId(pipe: Pipe): Optional<SystemID> {
  const system = get(pipe, 'config.effective.sink.system');
  if (system && !system.startsWith('system:sesam-node')) return system;
  else return null;
}

/**
 * Retrieves the upstream pipe of a dataset from its id
 * (without replicas)
 * @param {string} datasetId
 * @param {object} allPipes pipe objects
 * @param {object} upstreams
 * @returns {object|undefined} pipe object or undefined
 */
export function getUpstreamPipeFromDatasetId(
  datasetId: string,
  allPipes: PipeMap,
  upstreams: UpstreamMap
): Pipe | undefined {
  const pipe = allPipes[upstreams[datasetId]];

  if (!pipe) return undefined;
  else {
    if (isMirorrOrReplica(pipe)) {
      // if replica, follow upstream
      const newDatasetId = get(pipe, 'config.effective.metadata.dataset', '');
      if (newDatasetId !== datasetId) {
        // prevents cycles
        return getUpstreamPipeFromDatasetId(
          get(pipe, 'config.effective.metadata.dataset', ''),
          allPipes,
          upstreams
        );
      } else {
        return pipe;
      }
    } else {
      return pipe;
    }
  }
}

/**
 * Specialized function to include replicas, which means more
 * than one pipe can be returned
 * @param {string} datasetId
 * @param {object} allPipes pipe objects
 * @param {boolean} includeReplicas whether to include replica pipes
 * @param {object} upstreams
 * @returns {array} pipe objects
 */
export function getUpstreamPipesWithReplicasFromDatasetId(
  datasetId: string,
  allPipes: PipeMap,
  includeReplicas = false,
  upstreams: UpstreamMap
) {
  const upstreamPipes = [];
  const directUpstreamPipeID = upstreams[datasetId];
  if (!directUpstreamPipeID) {
    return [];
  }
  const directUpstreamPipe = allPipes[directUpstreamPipeID];
  const isReplicaOrMirror = isMirorrOrReplica(directUpstreamPipe);
  if (!isReplicaOrMirror) {
    upstreamPipes.push(directUpstreamPipe);
  } else {
    // pipe is a replica, so let's look one level upstream
    const replicaDataset = get(directUpstreamPipe, 'config.effective.metadata.dataset', '');
    const originalUpstreamPipe = getUpstreamPipeFromDatasetId(replicaDataset, allPipes, upstreams);
    const replicaQueue = getPipeQueueSize(directUpstreamPipe);
    if (originalUpstreamPipe) {
      upstreamPipes.push({
        ...originalUpstreamPipe,
        replicaQueueSize: replicaQueue,
      });
    }
    if (includeReplicas) {
      // add replica as well
      upstreamPipes.push(directUpstreamPipe);
    }
  }

  return upstreamPipes;
}

/**
 * Gets all pipe objects that are direct upstream parents of pipe
 * @param pipe
 * @param {object} allPipes pipe objects
 * @param {boolean} includeReplicas whether to include replica pipes
 * @param {object} upstreams
 * @return {array} pipe objects
 */
export function getUpstreamPipes(
  pipe: Pipe,
  allPipes: PipeMap,
  upstreams: UpstreamMap,
  includeReplicas = false
): Pipe[] {
  if (!pipe) {
    return [];
  }
  const upstreamPipes = getSourceDatasetIds(pipe).map((datasetId: DatasetID) =>
    getUpstreamPipesWithReplicasFromDatasetId(datasetId, allPipes, includeReplicas, upstreams)
  );
  // we need to flatten the nested array and remove undefineds
  return flatten(upstreamPipes).filter((x) => x);
}

/**
 * Retrieves the downstream pipes of a dataset from its id
 * @param {string} datasetId
 * @param {object} allPipes pipe objects
 * @param {boolean} includeReplicas whether to include replica pipes
 * @param {object} downstreams
 * @returns {array} pipe objects
 * TODO optimize with new pipes redux structure
 */
export function getDownstreamPipesFromDatasetId(
  datasetId: string,
  allPipes: PipeMap,
  includeReplicas: boolean = false,
  downstreams: DownstreamMap
): Pipe[] {
  const downstreamPipes = [];
  const directDownstreamPipeIds = Object.keys(get(downstreams, datasetId, {}));

  for (const id of directDownstreamPipeIds) {
    const p = allPipes[id];
    if (!p) continue;
    const isReplicaOrMirror = isMirorrOrReplica(p);
    if (!isReplicaOrMirror) {
      downstreamPipes.push(p);
    } else {
      // pipe is a replica so find original pipe downstream
      const replicaSinkId = getSinkDatasetId(p);
      if (replicaSinkId === datasetId) {
        // this should never happen but
        // at least we won't crash if it does
        continue;
      }
      const replicasDownstreamPipes = getDownstreamPipesFromDatasetId(
        replicaSinkId,
        allPipes,
        false,
        downstreams
      );
      const replicaQueue = getPipeQueueSize(p);
      for (const dp of replicasDownstreamPipes) {
        downstreamPipes.push({ ...dp, replicaQueueSize: replicaQueue });
      }
      // also add replicas
      if (includeReplicas) {
        downstreamPipes.push(p);
      }
    }
  }
  return downstreamPipes;
}
/**
 * Gets all pipe objects that are direct downstream children of pipe
 * @param {object} pipe object
 * @param {object} allPipes pipe objects
 * @param {boolean} includeReplicas whether to include replica pipes
 * @param {boolean} downstreams
 * @return {array} pipe objects
 * TODO optimize with new pipes redux structure
 */
export function getDownstreamPipes(
  pipe: Pipe,
  allPipes: PipeMap,
  downstreams: DownstreamMap,
  includeReplicas: boolean = false
): Pipe[] {
  const sinkDatasetId = getSinkDatasetId(pipe);
  return sinkDatasetId
    ? getDownstreamPipesFromDatasetId(sinkDatasetId, allPipes, includeReplicas, downstreams)
    : [];
}

/**
 * Get a list of pipes that perform hops/lookups to this pipe's sink dataset
 * @param pipe
 * @param allPipes
 * @param lookups
 */
export function getIncomingLookupPipes(pipe: Pipe, allPipes: PipeMap, lookups: LookupMap): Pipe[] {
  const sinkDatasetId = getSinkDatasetId(pipe);
  if (sinkDatasetId) {
    return getLookupPipes(sinkDatasetId, allPipes, lookups);
  } else {
    return [];
  }
}

/**
 * Get a list of pipes whose datasets this pipe hops to/performs a lookup on
 * @param pipe
 * @param allDatasets
 * @param allPipes
 * @param upstreams
 * @param includeReplicas
 */
export function getOutgoingLookupPipes(
  pipe: Pipe,
  allDatasets: DatasetMap,
  allPipes: PipeMap,
  upstreams: UpstreamMap,
  includeReplicas: boolean
) {
  const lookupDatasets = getLookupDatasets(pipe, allDatasets);
  const upstreamPipesFromLookupDatasets = lookupDatasets.map((dataset) =>
    getUpstreamPipesWithReplicasFromDatasetId(
      dataset._id,
      allPipes,
      includeReplicas,
      upstreams
    ).map((p) => ({ ...p, sinkDatasetId: dataset._id }))
  );
  return flatten(upstreamPipesFromLookupDatasets);
}

/**
 * Collects datasets from the dtl of a pipe.
 *
 * @returns {Array} of dataset id-s that were found, empty array if nothing is found
 */
export function collectDatasets(pipe: Pipe) {
  let datasetIds: string[] = [];
  if (!pipe.config.effective) {
    // old api
    return datasetIds;
  }
  if (!pipe.config.effective.transform) {
    return datasetIds;
  }
  visit(pipe.config.effective.transform, (key: string, value: any) => {
    if (key === 'datasets' && Array.isArray(value) && value.every((d) => typeof d === 'string')) {
      // datasets are aliased, e.g. 'Dataset d'
      datasetIds = datasetIds.concat(
        value.map((datasetWithAlias) => datasetWithAlias.split(' ')[0])
      );
    }
  });
  return datasetIds;
}

/**
 * Collects transforms from the dtl of a pipe
 * @param pipe
 * @returns {array} of systems ids
 */
export function collectTransforms(pipe: Pipe) {
  let systemIds: string[] = [];
  const transform = get(pipe, 'config.effective.transform');
  if (transform) {
    if (
      Array.isArray(transform) ||
      (transform.type == 'chained' && Array.isArray(transform.transforms))
    ) {
      if (Array.isArray(transform)) systemIds = systemIds.concat(transform.map((t) => t.system));
      else systemIds = systemIds.concat(transform.transforms.map((t) => t.system));
    } else if (transform.system) {
      systemIds = [transform.system];
    }
  }
  return systemIds.filter(Boolean);
}

/**
 * Retrieves the systems that this pipe uses in its http_transforms
 * @param {Pipe} pipe t
 * @param {System[]} allSystems system objects
 * @returns {array} system objects
 */
export function getHttpTransformSystems(pipe: Pipe, allSystems: System[]): System[] {
  if (!get(pipe, 'config.effective.transform')) return [];
  let systemNames: string[] = [];

  visit(pipe.config.effective.transform, (key, value) => {
    if (key === 'system' && typeof value === 'string') {
      // only happens with http_transform
      systemNames.push(value);
    }
  });

  return allSystems.filter((s) => systemNames.includes(s._id));
}

/**
 * Retrieves the pipe queue size
 * @param {object} pipe object
 * @param {?object} sourcePipe optional pipe object to
 * get the queue size specifically from this
 * pipe source
 * @returns number queue size
 */
export function getPipeQueueSize(
  pipe: Pipe,
  sourcePipe: Pipe | null = null,
  isDependency: boolean = false
) {
  if (!pipe || !pipe.runtime || !pipe.runtime.queues) {
    return 0;
  }
  let result;
  if (isDependency === false) {
    const source = pipe.runtime.queues.source;
    if (isObject(source)) {
      if (sourcePipe) {
        result = source[sourcePipe._id];
      } else {
        // get sum of queues when multiple sources
        const values = Object.keys(source).map((key) => source[key]);
        // defaults to 0 if empty list
        result = sum(values);
      }
    } else {
      result = source;
    }
  } else {
    const dependencies = pipe.runtime.queues.dependencies;
    if (dependencies !== undefined) {
      result = dependencies[sourcePipe._id] || 0;
    }
  }

  if (isNaN(result)) result = 0;
  return result;
}

export function isSamePipe(pipeA: Pipe, pipeB: Pipe) {
  return pipeA._id === pipeB._id;
}

/**
 * Checks if pipe is global or not
 * @param {object} pipe object
 * @returns {boolean} true if it is, false if not
 */
export function isGlobal(pipe: Pipe) {
  return get(pipe, 'config.original.metadata.global', false);
}

export function isDurable(pipe: Pipe) {
  return get(pipe, 'config.effective.metadata.durable', false);
}

export function isTenant(pipe: Pipe) {
  return get(pipe, 'config.effective.metadata.$tenant', false);
}

/**
 * Checks if pipe is a mirror or replica pipe
 * @param {object} pipe object
 * @returns {boolean} true if it is, false if not
 */
export function isMirorrOrReplica(pipe: Pipe) {
  const origin = get(pipe, 'config.original.metadata.origin');
  return origin === 'replica' || origin === 'mirror';
}

/**
 * Answers whether pipe is an input endpoint
 * (i.e. pipe doesn't have a dataset/s source)
 * @param {object} pipe pipe object
 * @returns {boolean} true if is input endpoint, false otherwise
 */
export function isInputEndpointPipe(pipe: Pipe) {
  return !(
    get(pipe, 'config.effective.source.dataset') || get(pipe, 'config.effective.source.datasets')
  );
}

export function isNonSystemInputEndpointPipe(pipe: Pipe) {
  const sourceType = get(pipe, 'config.effective.source.type');
  return ['http_endpoint', 'csv_endpoint', 'excel_endpoint', 'xml_endpoint'].includes(sourceType);
}

export function isConditionalSourcePipe(pipe: Pipe) {
  return get(pipe, 'config.effective.source.type') === 'conditional';
}

export function isEmbeddedSourcePipe(pipe: Pipe) {
  return get(pipe, 'config.effective.source.type') === 'embedded';
}

/**
 * Answers whether pipe is an output endpoint
 * (i.e. pipe doesn't have a dataset/s sink)
 * @param {object} pipe pipe object
 * @returns {boolean} true if is output endpoint, false otherwise
 */
export function isOutputEndpointPipe(pipe: Pipe) {
  return !get(pipe, 'config.effective.sink.dataset');
}

export function isValidConfig(pipe: Pipe) {
  return get(pipe, 'runtime.is-valid-config', false);
}

export function isRunError(pipe: Pipe) {
  return get(pipe, 'runtime.state') === 'finished' && !get(pipe, 'runtime.success');
}

export function hasConfigWarnings(pipe: Pipe) {
  if (!pipe) return false;
  const warnings = pipe['config-warnings'];
  if (Array.isArray(warnings)) return warnings.length > 0;
  else return false;
}

export function pipeConfigSorter(a: Pipe, b: Pipe) {
  if (isValidConfig(a) && isValidConfig(b)) {
    if (!hasConfigWarnings(a) && hasConfigWarnings(b)) return -1;
    if (hasConfigWarnings(a) && !hasConfigWarnings(b)) return 1;
  }
  if (isValidConfig(a) && !isValidConfig(b)) return -1;
  if (!isValidConfig(a) && isValidConfig(b)) return 1;

  return 0;
}
