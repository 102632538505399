import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Flows from 'Internals/flows';
import { useSelector } from 'react-redux';
import { BaseFlow, Flow } from 'Internals/overview-utils';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'Types/state.types';

export const pipeStateLoaded = createSelector(
  (state: RootState) => Object.keys(state.pipes).length,
  (numOfPipes) => {
    // 1 because sometimes when in a pipe view, a single pipe gets loaded first
    // so the four maps here all have one element, but that's not
    // what we want to mean that it's finished loading
    // another place where changing the entire loading structure (maybe using react-query)
    // would avoid this issue
    return numOfPipes > 1;
  }
);

export function useFlows() {
  const [loading, setLoading] = useState(false);
  const pipes = useSelector((state: RootState) => state.pipes);

  const downstreams = useSelector((state: RootState) => state.downstreams);
  const lookups = useSelector((state: RootState) => state.lookups);
  const initialLoadFinished = useSelector(pipeStateLoaded);
  const [flows, setFlows] = useState<Flow[]>([]);
  const [flowsComputed, setFlowsComputed] = useState(false);

  useEffect(() => {
    if (initialLoadFinished && !loading) {
      setLoading(true);
      Flows.get(pipes, downstreams, lookups).then((flows) => {
        setFlows(flows);
        setFlowsComputed(true);
        setLoading(false);
      });
    }
  }, [initialLoadFinished, pipes, downstreams, lookups]);

  return { loading: loading, flows: flows, finished: flowsComputed };
}
