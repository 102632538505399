import { portalErrorHandler } from '../utils';
import ChecksAPI from '../../api/portal';
import type { AppThunk } from 'Types/state.types';
import { createAction } from '@reduxjs/toolkit';
import { SomeObject } from 'Types/common.types';

type PredicateFunction = (_: any) => boolean;

export type Apology = {
  message: string;
  type: string;
  id: number;
};

export const apologyDismissed = createAction<number>('apology/dismissed');
export const versionLoaded = createAction<string>('version/loaded');
export const statusLoaded = createAction<any>('status/loaded');
let errorCounter = 0;

function prepareApologise(message: string, type = 'unknown') {
  errorCounter += 1;
  console.error('Apologise() invoked', message);
  const apology: Apology = {
    message,
    type,
    id: errorCounter,
  };
  return {
    payload: apology,
  };
}

// this is almost an action creator if you don't look at the errorCounter side effect
export const apologise = createAction<typeof prepareApologise>('apologise', prepareApologise);

export default {
  dismissApology(id: number): AppThunk {
    return function (dispatch) {
      return dispatch(apologyDismissed(id));
    };
  },

  syncCompleted(): AppThunk {
    return (dispatch) => {
      dispatch(this.dismissApologies((a) => a.messageType === 'background_sync'));
    };
  },

  dismissApologies(filter: PredicateFunction): AppThunk {
    return (dispatch, getState) => {
      getState()
        .globals.apologies.filter(filter)
        .forEach((a) => dispatch(this.dismissApology(a.id)));
    };
  },

  apologiseUnless(message: string, type = 'unknown', duplicate = () => false): AppThunk {
    return (dispatch, getState) => {
      if (!getState().globals.apologies.find(duplicate)) {
        dispatch(apologise(message, type));
      }
    };
  },

  syncFailed(): AppThunk {
    return (dispatch, getState) => {
      dispatch(
        this.apologiseUnless(
          { subId: getState().subscription.id },
          'background_sync',
          (a) => a.messageType !== 'background_sync'
        )
      );
    };
  },

  serverUpgraded(): AppThunk {
    return (dispatch, getState) => {
      dispatch(
        this.apologiseUnless(
          { subId: getState().subscription.id },
          'server_upgraded',
          (a: SomeObject) => a.messageType !== 'server_upgraded'
        )
      );
    };
  },

  checkVersion(): AppThunk {
    return function (dispatch, getState) {
      return ChecksAPI.getVersion()
        .then((version) => {
          if (!getState().globals.version) {
            // TODO include build number in the bundle to avoid this race condition
            // no build number yet, so let's remember this
            dispatch(versionLoaded(version));
          } else if (getState().globals.version !== version) {
            // a new version, does the user know?
            if (!getState().globals.apologies.find((a: SomeObject) => a.type === 'stale_client')) {
              // Nope
              if (getState().editor.isDirty) {
                // The editor is currently in dirty state, so we won't do an automatic reload.
                dispatch(apologise('', 'stale_client'));
              } else {
                // The editor is currently untouched, so it's okay to reload.
                location.reload();
              }
            }
          }
        })
        .catch(portalErrorHandler(dispatch, getState));
    };
  },

  checkStatus(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return ChecksAPI.getStatus(portalUrl)
        .then((response) => {
          dispatch(statusLoaded(response.status));
        })
        .catch(portalErrorHandler(dispatch, getState));
    };
  },
};
