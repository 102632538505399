import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'Common/Button/Button';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import useDownload from '../../../hooks/useDownload';
import {
  DEFAULT_CONFIG_GROUP_ID,
  DEFAULT_CONFIG_GROUP_LABEL,
  configGroupsSelector,
} from 'Redux/selectors';
import { getSubName, getCurrentSub } from 'Redux/thunks/subscriptions';
import { Form, FormActions } from 'Common/forms';
import type { RootState } from 'Types/state.types';

const DownloadConfig = () => {
  const subName = useSelector((state: RootState) => getSubName(getCurrentSub(state)));
  const subUrl = useSelector((state: RootState) => state.subscription.url);
  const configGroups = useSelector(configGroupsSelector);
  const [configGroup, setConfigGroup] = useState(DEFAULT_CONFIG_GROUP_ID);

  const url = useMemo(() => {
    let u = `${subUrl}/config`;
    if (configGroup !== DEFAULT_CONFIG_GROUP_ID) {
      u = u + `/${encodeURIComponent(configGroup)}`;
    }

    return u;
  }, [subUrl, configGroup]);

  const downloadJson = useDownload({
    url: url,
    mimeType: 'application/json',
    fileName: configGroup !== DEFAULT_CONFIG_GROUP_ID ? `${subName} - ${configGroup}` : subName,
  });

  const downloadZip = useDownload({
    url: url,
    mimeType: 'application/zip',
    fileName: configGroup !== DEFAULT_CONFIG_GROUP_ID ? `${subName} - ${configGroup}` : subName,
  });

  return (
    <Form component="div" margin={false}>
      <SesamTextField
        select
        margin="normal"
        label="Config group"
        value={configGroup}
        onChange={(ev) => {
          setConfigGroup(ev.target.value);
        }}
        SelectProps={{
          renderValue: (value) => {
            return value === DEFAULT_CONFIG_GROUP_ID ? DEFAULT_CONFIG_GROUP_LABEL : value;
          },
        }}
      >
        {configGroups.map((cg) => (
          <SesamMenuItem key={cg} value={cg}>
            {cg === DEFAULT_CONFIG_GROUP_ID ? <strong>{DEFAULT_CONFIG_GROUP_LABEL}</strong> : cg}
          </SesamMenuItem>
        ))}
      </SesamTextField>
      <FormActions>
        <Button onClick={() => downloadJson()}>Export JSON</Button>
        <Button onClick={() => downloadZip()}>Export ZIP</Button>
      </FormActions>
    </Form>
  );
};

export default DownloadConfig;
