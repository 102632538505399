import React from 'react';

import Box from 'Common/Box/Box';
import JsonPanel from 'Common/JsonPanel';
import { makeStyles } from '@material-ui/core/styles';
import { SomeObject } from 'Types/common.types';

interface SearchEntityViewerProps {
  selectedEntity: SomeObject;
}

const useStyle = makeStyles({
  box: {
    '& .jsonpanel > div': {
      minHeight: '100%',
    },
  },
});

const SearchEntityViewer: React.FC<SearchEntityViewerProps> = ({ selectedEntity }) => {
  const classes = useStyle();
  return (
    <Box>
      <Box.Header>
        <Box.Title>
          {selectedEntity.$ids ? selectedEntity.$ids.toString() : selectedEntity._id}
        </Box.Title>
      </Box.Header>
      <Box.Content white className={classes.box}>
        <JsonPanel rawJson={selectedEntity} />
      </Box.Content>
    </Box>
  );
};

export default SearchEntityViewer;
