import get from 'lodash/get';
import { SomeObject } from 'Types/common.types';

const DAYS_BEFORE_OUTDATED = 100;
export const getDatahubIsOutdated = (datahubInfo: SomeObject) => {
  const buildDate = get(datahubInfo, ['status', 'build-date']);
  if (buildDate) {
    const diff = new Date() - new Date(buildDate);
    if (!isNaN(diff)) {
      const diffInDays = Math.round(diff / (1000 * 60 * 60 * 24));
      return diffInDays > DAYS_BEFORE_OUTDATED;
    }
  }
  return undefined;
};
