/**
 * Utility functions that work with the graph of pipes
 */

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  getDownstreamPipes,
  getUpstreamPipes,
  isInputEndpointPipe,
  getUpstreamLookups,
  getDownstreamLookups,
  isGlobal,
  isMirorrOrReplica,
} from './pipes';
import { ensureArray } from './utils';
import { globalPipeIcon, pipeIcon, systemIcon } from './graph_icons';
import type { PipeID, PipeMap, Pipe, PipePredicate, UpstreamMap } from 'Types/pipes.types';
import type { System } from 'Types/system.types';
import type { _ID, DownstreamMap, LookupMap, NodeComponent } from 'Types/common.types';

export const HOP_IDENTIFIER = '---';

function isValidOutputPipeForFlow(pipe: Pipe) {
  if (isMirorrOrReplica(pipe)) return false;
  const sink = get(pipe, 'config.effective.sink', {});
  if (sink.type === 'conditional' || sink.type === 'embedded') return false;
  return !sink.dataset;
}

/**
 * Creates a list of all upstream endpoint pipes from origin pipe
 * @param {object} originPipe pipe from which we look
 * @param {object} allPipes all pipes in node
 * @param {object} upstreams
 * @param {object} lookups
 * @returns {object} of endpoint pipe-ids as well as paths towards them
 * and whether they are through hops or not
 */
export const findUpstreamEndpointPipes = (
  originPipe: Pipe,
  allPipes: PipeMap,
  upstreams: UpstreamMap,
  lookups: LookupMap
) =>
  collectPipes({
    direction: 'upstream',
    matcher: isInputEndpointPipe,
    originPipe,
    pipes: allPipes,
    downstreamsOrUpstreams: upstreams,
    lookups,
  });

/**
 * Creates a list of all downstream endpoint pipes from origin pipe
 * @param {object} originPipe pipe from which we look
 * @param {object} allPipes all pipes in node
 * @param {object} downstreams
 * @param {object} lookups
 * @returns {object} of endpoint pipe-ids as well as paths towards them
 * and whether they are through hops or not
 */
export function findDownstreamEndpointPipes(
  originPipe: Pipe,
  allPipes: PipeMap,
  downstreams: DownstreamMap,
  lookups: LookupMap
) {
  return collectPipes({
    direction: 'downstream',
    matcher: isValidOutputPipeForFlow,
    originPipe,
    pipes: allPipes,
    downstreamsOrUpstreams: downstreams,
    lookups,
    stopMatcher: isGlobal,
  });
}
/**
 * Creates a list of all upstream global pipes from origin pipe
 * @param {object} originPipe pipe from which we look
 * @param {object} allPipes all pipes in node
 * @param {object} upstreams
 * @param {object} lookups
 * @returns {object} of endpoint pipe-ids as well as paths towards them
 * and whether they are through hops or not
 */
export function findUpstreamGlobals(
  originPipe: Pipe,
  allPipes: PipeMap,
  upstreams: UpstreamMap,
  lookups: LookupMap
) {
  return collectPipes({
    direction: 'upstream',
    matcher: isGlobal,
    originPipe,
    pipes: allPipes,
    downstreamsOrUpstreams: upstreams,
    lookups,
    allowTraverseLoop: isGlobal(originPipe),
  });
}

/**
 * Creates a list of all downstream global pipes from origin pipe
 * @param {object} originPipe pipe from which we look
 * @param {object} allPipes all pipes in node
 * @param {object} downstreams
 * @param {object} lookups
 * @returns {object} of endpoint pipe-ids as well as paths towards them
 * and whether they are through hops or not
 */
export function findDownstreamGlobals(
  originPipe: Pipe,
  allPipes: PipeMap,
  downstreams: DownstreamMap,
  lookups: LookupMap
) {
  return collectPipes({
    direction: 'downstream',
    matcher: isGlobal,
    originPipe,
    pipes: allPipes,
    downstreamsOrUpstreams: downstreams,
    lookups,
    deepTraverse: false,
    allowTraverseLoop: isGlobal(originPipe),
  });
}

/**
 * Returns a path between the origin pipe and the target pipe
 * @param {object} originPipe pipe from which we look
 * @param {object} targetPipe pipe from which we look
 * @param {object} allPipes all pipes in node
 * @param {boolean} includeLookups
 * @param {object} downstreams
 * @param {object} lookups
 * @returns {object} of endpoint pipe-ids as well as paths towards them
 * and whether they are through hops or not
 */
export const findDownstreamPipe = (
  originPipe: Pipe,
  targetPipe: Pipe,
  allPipes: PipeMap,
  includeLookups: boolean,
  downstreams: DownstreamMap,
  lookups: LookupMap
) =>
  collectPipes({
    direction: 'downstream',
    matcher: (pipe: Pipe) => pipe._id === targetPipe._id,
    originPipe: originPipe,
    pipes: allPipes,
    downstreamsOrUpstreams: downstreams,
    lookups: lookups,
    stopAfterFirstMatch: true,
    includeLookups: includeLookups,
  });

interface CollectionResult {
  id: PipeID;
  path: Path;
}

interface TraversalConfig {
  direction: 'upstream' | 'downstream';
  matcher: PipePredicate;
  originPipe: Pipe;
  pipes: PipeMap;
  downstreamsOrUpstreams: DownstreamMap | UpstreamMap;
  lookups: LookupMap;
  stopAfterFirstMatch?: boolean;
  includeLookups?: boolean;
  deepTraverse?: boolean;
  stopMatcher?: PipePredicate;
  allowTraverseLoop?: boolean;
}

export type Path = PipeID[];

/**
 * Base function to do upstream or downstream traversal from
 * a single pipe to the edges of the graph
 * @returns object of pipes
 * @param config
 */
function collectPipes(config: TraversalConfig) {
  if (isEmpty(config.pipes) || !config.originPipe) throw new Error('No pipe supplied');

  if (config.includeLookups === undefined) {
    config.includeLookups = true;
  }
  if (config.deepTraverse === undefined) {
    config.deepTraverse = true;
  }

  if (!config.stopMatcher) {
    config.stopMatcher = (pipe: Pipe) => false;
  }

  if (!config.allowTraverseLoop) {
    config.allowTraverseLoop = false;
  }

  let directTraverseFunction, indirectTraverseFunction;
  if (config.direction === 'upstream') {
    directTraverseFunction = (pipe: Pipe) =>
      getUpstreamPipes(pipe, config.pipes, config.downstreamsOrUpstreams as UpstreamMap);
    indirectTraverseFunction = (pipe: Pipe) =>
      getUpstreamLookups(pipe, config.pipes, config.downstreamsOrUpstreams as UpstreamMap);
  } else if (config.direction === 'downstream') {
    directTraverseFunction = (pipe: Pipe) =>
      getDownstreamPipes(pipe, config.pipes, config.downstreamsOrUpstreams as DownstreamMap);
    indirectTraverseFunction = (pipe: Pipe) =>
      getDownstreamLookups(pipe, config.pipes, config.lookups);
  } else throw new Error('Invalid direction supplied');

  let visited: PipeID[] = [];
  let results: CollectionResult[] = [];
  let queue: PipeID[][] = [[config.originPipe._id]];
  let counter = 0;
  const firstVisited: PipeID = config.originPipe._id;
  while (queue.length > 0) {
    const currPath = queue.shift();
    if (!Array.isArray(currPath)) {
      continue;
    }
    const id = currPath[currPath.length - 1];
    const pipe = config.pipes[id];

    // add to visited here so we ignore immediate loops
    // unless allowTraverseLoop is true
    if (!config.allowTraverseLoop || counter > 0) {
      visited.push(id);
    }

    let foundMatch = false;
    // add pipe to results if matches
    if (config.matcher(pipe) && counter > 0) {
      results.push({
        id: pipe._id,
        path: currPath,
      });
      foundMatch = true;

      if (config.stopAfterFirstMatch) {
        break;
      }
    }

    let shouldContinue;
    if (counter === 0) {
      // we just started, we should continue even in the case
      // that the stopMatcher function would return true
      // and even if deepTraverse is off
      shouldContinue = true;
    } else {
      const shouldIgnorePipe = config.stopMatcher(pipe);
      if (!foundMatch && !shouldIgnorePipe) {
        shouldContinue = true;
      } else if (foundMatch && config.deepTraverse && !shouldIgnorePipe) {
        shouldContinue = true;
      } else {
        shouldContinue = false;
      }
    }

    if (shouldContinue) {
      // add direct neighbours to the queue
      const directNeighbours = directTraverseFunction(pipe);
      for (const p of directNeighbours) {
        if (!visited.includes(p._id)) {
          queue.push(currPath.concat(p._id));
        }
      }

      if (config.includeLookups) {
        // add indirect neighbours to the queue
        const indirectNeighbours = indirectTraverseFunction(pipe);
        for (const p of indirectNeighbours) {
          // only if it's not in visited
          // and not in direct neighbours already
          if (!visited.includes(p._id) && !directNeighbours.find((n) => n._id === p._id)) {
            queue.push(currPath.concat(HOP_IDENTIFIER, p._id));
          }
        }
      }
    }

    counter = counter + 1;
  }
  return results.reduce(coalescePaths, {});
}

type CoalescedCollectionResult = {
  [PipeID: string]: Path[];
};
/**
 * Groups the array of endpoints and paths to them based
 * on endpoint.
 * @param {object} acc accumulator
 * @param {{string, array}} endpoint
 */
function coalescePaths(acc: CoalescedCollectionResult, { id, path }: CollectionResult) {
  if (acc[id]) {
    acc[id].push(path);
  } else {
    acc[id] = [path];
  }
  return acc;
}

/**
 * Creates the URL param string from a path array
 * (used for flows)
 * @param {Array|string} path
 */
export function makeUrlParamsFromPath(path: PipeID[]) {
  return `path=${ensureArray(path).join('&path=')}`;
}

// More specific utilities for Vis.js graphs follow

/**
 * Returns the type of entity. If it's a system, it returns only
 * the string system, not the whole system name
 * @param {object} entity pipe or system or dataset
 * @returns {?string} type of entity
 */
export function getComponentType(entity: NodeComponent): string | null {
  const configType = get(entity, 'config.effective.type');
  if (typeof configType === 'string') {
    if (configType.startsWith('system')) return 'system';
    else return configType;
  } else return null;
}

/**
 * Returns the data string for icon depending on type
 * @param {object} obj pipe/globalPipe/system
 * @returns {string} the icon in svg data string representation
 */
export function getIcon(obj: Pipe | System) {
  const color = getEdgeColor(obj);
  if (getComponentType(obj) === 'pipe') {
    if (isGlobal(obj as Pipe)) {
      return globalPipeIcon(color);
    } else {
      return pipeIcon(color);
    }
  } else {
    return systemIcon(color);
  }
}

/**
 * Creates a string of, which is a comma
 * separated list of pipes transforms
 * DTL is excluded
 * @param {object} pipe object
 * @returns {string} comma separated list of transforms
 */
export function getPipeTransforms(pipe: Pipe) {
  // old api
  if (!pipe.config.original) return '';

  const transforms = ensureArray(get(pipe, 'config.original.transform', []));
  if (transforms.length < 1) return '';
  else
    return transforms
      .map((t) => t.type)
      .filter((t) => t && t.toLowerCase() !== 'dtl')
      .join(', ');
}

/**
 * Creates a label to be used in the graphs
 * with pipe's name, transforms and queue size
 * regardless if it is global or not
 * @param {object} pipe object
 * @returns {string} label for pipe node
 */
export function getPipeNodeLabel(pipe: Pipe) {
  const name = get(pipe, 'name', '');
  const transforms = getPipeTransforms(pipe);
  return `${name}\n${transforms}`;
}

/**
 * Creates a label to be used in the graphs
 * with system's name
 * @param {object} system object
 * @returns {string} label for system node
 */
export function getSystemNodeLabel(system: System) {
  return get(system, 'name', '');
}

/**
 * Gets label for node in graph
 * @param {object} obj
 * @return {string} for the label of the node
 */
export function getNodeLabel(obj: NodeComponent) {
  const type = getComponentType(obj);
  if (type === 'pipe') return getPipeNodeLabel(obj as Pipe);
  else if (type === 'system') return getSystemNodeLabel(obj as System);
  else return '';
}

enum EdgeState {
  Default = 'Default',
  Running = 'Running',
  Failed = 'Failed',
  Disabled = 'Disabled',
  Warning = 'Warning',
}

export function getEdgeState(obj: NodeComponent, warning: boolean = false) {
  const isRunning = get(obj, 'runtime["is-running"]');
  const isSuccess = get(obj, 'runtime.success');
  const isFailed = !isSuccess && isSuccess !== null;
  const isDisabled = get(obj, 'runtime["is-disabled"]');

  if (getComponentType(obj) === 'pipe') {
    if (isDisabled) return EdgeState.Disabled;
    else if (isFailed) return EdgeState.Failed;
    else if (warning) return EdgeState.Warning;
    else if (isRunning) return EdgeState.Running;
  }
  return EdgeState.Default;
}

/**
 * Returns the state of path made up of pipes (i.e. a flow)
 * @param {array} pipeIds
 * @param {object} allPipes
 * @returns {string}
 */
export function getPathState(pipeIds: PipeID[], allPipes: PipeMap): EdgeState {
  const pipeEdgeState = pipeIds.map((id) => getEdgeState(allPipes[id]));
  if (pipeEdgeState.includes(EdgeState.Disabled)) return EdgeState.Disabled;
  if (pipeEdgeState.includes(EdgeState.Failed)) return EdgeState.Failed;
  if (pipeEdgeState.includes(EdgeState.Running)) return EdgeState.Running;
  else return EdgeState.Default;
}

export function getBranchState(paths: PipeID[][], edges: _ID[], allPipes: PipeMap) {
  const pathStates = paths.map((path: PipeID[]) => getPathState(path, allPipes));
  const edgeStates = edges.map((id: _ID) => getEdgeState(allPipes[id]));

  const branchEdgeState = [...pathStates, ...edgeStates];

  if (
    branchEdgeState.length > 0 &&
    branchEdgeState.filter((state) => state !== EdgeState.Disabled).length === 0
  )
    return EdgeState.Disabled;
  else if (branchEdgeState.some((state) => state === EdgeState.Failed)) return EdgeState.Failed;
  else if (branchEdgeState.some((state) => state === EdgeState.Running)) return EdgeState.Running;
  else {
    return EdgeState.Default;
  }
}

const colors = {
  black: 'black',
  green: 'rgb(37, 206, 28)',
  grey: 'lightgrey',
  orange: '#ff9800',
  red: 'red',
};

const darkColors = {
  black: '#fff',
  green: 'green',
  grey: 'grey',
  orange: '#fab20e',
  red: '#c93c37',
};

export function getColorFromState(state: EdgeState, darkMode: boolean) {
  switch (state) {
    case EdgeState.Running:
      return darkMode ? darkColors.green : colors.green;
    case EdgeState.Failed:
      return darkMode ? darkColors.red : colors.red;
    case EdgeState.Disabled:
      return darkMode ? darkColors.grey : colors.grey;
    case EdgeState.Warning:
      return darkMode ? darkColors.orange : colors.orange;
    default:
      return darkMode ? darkColors.black : colors.black;
  }
}

export function getColorClassNamesFromState(state: EdgeState, darkMode: boolean) {
  switch (state) {
    case EdgeState.Running:
      return 'status-green';
    case EdgeState.Failed:
      return 'status-red';
    case EdgeState.Disabled:
      return 'status-grey';
    case EdgeState.Warning:
      return 'status-yellow';
    default:
      return 'status-black';
  }
}

/**
 * Gets the desired edge color based on runtime
 * information from the pipe (or just black if system)
 * @param {pipe|system} obj
 */
export function getEdgeColor(
  obj: NodeComponent,
  warning: boolean = false,
  darkMode: boolean = false
) {
  return getColorFromState(getEdgeState(obj, warning), darkMode);
}

export function getPathColor(pipeIds: PipeID[], allPipes: PipeMap, darkMode: boolean = false) {
  return getColorFromState(getPathState(pipeIds, allPipes), darkMode);
}

export function getBranchColor(
  paths: PipeID[][],
  edges: _ID[],
  allPipes: PipeMap,
  darkMode: boolean = false
) {
  return getColorFromState(getBranchState(paths, edges, allPipes), darkMode);
}
