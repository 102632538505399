import clsx from 'clsx';
import React, { forwardRef } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import Select, { SelectProps } from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { refType } from '@material-ui/utils';
import { SomeObject } from 'Types/common.types';
import { FormControl, FormHelperText } from '../forms';
import { theme, darkTheme } from '../../../themes';

export interface SesamTextFieldProps {
  autoComplete: string;
  autoFocus: boolean;
  classes: {};
  className: string;
  color: 'primary' | 'secondary';
  defaultValue: any;
  disabled: boolean;
  error: boolean;
  FormHelperTextProps: {};
  fullWidth: boolean;
  helperText: JSX.Element;
  hiddenLabel: boolean;
  id: string;
  InputLabelProps: InputLabelProps;
  inputProps: {};
  InputProps: {};
  inputRef: refType;
  label: JSX.Element;
  margin: 'dense' | 'none' | 'normal';
  maxRows: string | number;
  minRows: string | number;
  multiline: boolean;
  name: string;
  onBlur: () => {};
  onChange: () => {};
  onFocus: () => {};
  placeholder: string;
  required: boolean;
  rows: string | number;
  select: boolean;
  SelectProps: SelectProps;
  size: 'medium' | 'small';
  type: string;
  value: any;
}

export const styles = {
  root: {
    '& .MuiInputBase-root': { backgroundColor: theme.palette.background.light },
  },
  rootDark: {
    '& .MuiInputBase-root': {
      backgroundColor: darkTheme.palette.background.light,
    },
  },
};

const SesamTextField: React.FC<SesamTextFieldProps> = forwardRef(function SesamTextField(
  props,
  ref
) {
  const {
    autoComplete,
    autoFocus = false,
    children,
    classes,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    FormHelperTextProps,
    fullWidth = true,
    helperText,
    hiddenLabel,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    maxRows,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    select = false,
    SelectProps,
    size = 'small',
    type,
    value,
    theme,
    inputTestid,
    ...other
  } = props;

  const InputMore: SomeObject = {};

  if (label) {
    const displayRequired = InputLabelProps?.required ?? required;
    InputMore.label = (
      <React.Fragment>
        {label}
        {displayRequired && '\u00a0*'}
      </React.Fragment>
    );
  }

  if (select) {
    // unset defaults from textbox inputs
    if (!SelectProps || !SelectProps.native) {
      InputMore.id = undefined;
    }
    InputMore['aria-describedby'] = undefined;
  }

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  const InputElement: React.ReactNode = (
    <OutlinedInput
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      // maxRows={maxRows}
      type={type}
      value={value}
      id={id}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      inputProps={inputProps}
      notched={true}
      data-testid={inputTestid}
      {...InputMore}
      {...InputProps}
    />
  );

  return (
    <FormControl
      className={clsx(theme.palette.type === 'dark' && classes.rootDark, classes.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      ref={ref}
      required={required}
      color={color}
      size={size}
      {...other}
    >
      {label && (
        <InputLabel
          htmlFor={id}
          id={inputLabelId}
          {...InputLabelProps}
          variant="outlined"
          shrink={true}
        >
          {label}
        </InputLabel>
      )}

      {select ? (
        <Select
          aria-describedby={helperTextId}
          id={id}
          labelId={inputLabelId}
          value={value ? value : ''}
          input={InputElement}
          {...SelectProps}
        >
          {children}
        </Select>
      ) : (
        InputElement
      )}

      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default withStyles(styles, { name: 'MuiTextField', withTheme: true })(SesamTextField);
