import { getSinkDatasetId } from 'Internals/pipes';
import { sfetchJson } from 'Internals/sfetch';
import { useEffect, useMemo, useState } from 'react';
import type { Pipe } from 'Types/pipes.types';
import { EntityTypeKind } from '../api/pipe-types';

type UsePipeEntitiesProps = {
  limit: number;
  pipe: Pipe;
  stage: EntityTypeKind;
  subUrl: string;
  token: string;
};

function usePipeEntities(props: UsePipeEntitiesProps) {
  const { limit, pipe, stage, subUrl, token } = props;

  const [entities, setEntities] = useState([]);
  const [selectedEntityIndex, setSelectedEntityIndex] = useState(0);

  const selectNextEntity = () => {
    if (entities.length - 1 > selectedEntityIndex) {
      setSelectedEntityIndex((idx) => idx + 1);
    }
  };

  const selectPreviousEntity = () => {
    if (selectedEntityIndex > 0) {
      setSelectedEntityIndex((idx) => idx - 1);
    }
  };

  useEffect(() => {
    const requestOptions: RequestInit = {
      credentials: 'include',
      headers: { Authorization: `bearer ${token}` },
    };

    if (!pipe) {
      return;
    }

    const datasetId = getSinkDatasetId(pipe);

    const datasetSinkUrl = `${subUrl}/datasets/${encodeURIComponent(
      datasetId
    )}/entities?deleted=false&history=false&reverse=true&uncommitted=false&limit=${limit}`;

    const stageUrl = `${subUrl}/pipes/${encodeURIComponent(
      pipe._id
    )}/entities?stage=${stage}&limit=${limit}`;

    // try if there are any entities in the sink dataset
    if (stage === 'sink' && datasetId) {
      sfetchJson(datasetSinkUrl, requestOptions)
        .then((entities) => {
          if (entities.length < 1) {
            throw new Error();
          } else {
            setEntities(entities);
          }
        })
        .catch(() => {
          sfetchJson(stageUrl, requestOptions)
            .then((entities) => setEntities(entities))
            .catch(() => {
              setEntities([]);
            });
        });
    } else {
      sfetchJson(stageUrl, requestOptions)
        .then((entities) => setEntities(entities))
        .catch(() => {
          setEntities([]);
        });
    }
  }, [limit, pipe, stage, subUrl, token]);

  const selectedEntity = useMemo(() => {
    return entities[selectedEntityIndex];
  }, [entities, selectedEntityIndex]);

  return {
    entities,
    selectedEntity: selectedEntity,
    selectedEntityIndex,
    selectNextEntity,
    selectPreviousEntity,
  };
}

export default usePipeEntities;
