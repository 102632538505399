import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/sortBy';

import PaymentMethodActions from 'Redux/thunks/payment-methods';
import SesamModal from 'Common/SesamModal/SesamModal';
import Button from 'Common/Button/Button';

import InvoiceIcon from '../../../images/icons/invoice.svg';
import CallToActionCard from '../../../components/call-to-action-card';
import Card from '../../../components/card';
import CardList from '../../../components/card-list';
import Media from '../../../components/media';
import PromiseButton from '../../../components/promise-button';
import ButtonLink from '../../../components/common/ButtonLink/ButtonLink';
import get from 'lodash/get';

import './index.css';

const canEdit = (pm) =>
  get(pm, 'supported-operations.modify_name_and_description.enabled') ||
  get(pm, 'supported-operations.modify_billing_info.enabled');

const canDelete = (pm) => get(pm, 'supported-operations.delete_paymentmethod.enabled');

class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.showRemoveConfirmation = (pm) => {
      this.setState({
        paymentMethodToDelete: pm,
        showDeleteConfirm: true,
      });
    };

    this.approveDelete = () => {
      this.setState({ deleting: true });
      return this.props.remove(this.state.paymentMethodToDelete.id).then(() =>
        this.setState({
          deleting: false,
          paymentMethodToDelete: null,
          showDeleteConfirm: false,
        })
      );
    };

    this.cancelDelete = () => {
      this.setState({
        paymentMethodToDelete: null,
        showDeleteConfirm: false,
      });
    };

    this.getCardData = () => {
      // First get all existing payment methods

      const cardData = sortBy(this.props.paymentMethods, 'name').map((pm) => {
        const text = (
          <span data-selenium-card-id={pm.id}>
            {pm?.subscriptions?.length > 0 && (
              <span>
                Used in{' '}
                {pm.subscriptions.map((s, idx) => (
                  <span key={`${pm.id}-${s.id}`}>
                    <SesamLink to={`/subscription/${s.id}`} key={s.id}>
                      {s.name}
                    </SesamLink>
                    {idx < pm.subscriptions.length - 1 && ', '}
                  </span>
                ))}
              </span>
            )}
            <br />
            {pm.description}
          </span>
        );

        const actions = [];

        if (canEdit(pm)) {
          actions.push(
            <ButtonLink
              key="edit"
              to={`/user/payment-methods/edit/${pm.id}`}
              size="small"
              theme="default"
            >
              Edit…
            </ButtonLink>
          );
        }

        actions.push(
          <Button
            theme="secondary"
            disabled={!canDelete(pm)}
            key="delete"
            onClick={() => this.showRemoveConfirmation(pm)}
          >
            Delete
          </Button>
        );

        const header = (
          <Media img={<InvoiceIcon />} type="icon">
            <h3 className="heading-section" data-selenium-card-name>{`${pm.name} (${pm.type})`}</h3>
          </Media>
        );

        const badges = {
          verification: pm.is_verified ? 'Verified' : 'Not yet verified',
        };
        if (pm.owner && pm.owner.user_id && pm.owner.user_id !== this.props.currentUserId) {
          badges['other-owner-info'] = `Owned by '${pm.owner.email}')`;
        }

        return {
          actions,
          badges,
          cardRenderer: Card,
          header,
          id: pm.id,
          text,
          type: pm.is_verified ? 'success' : 'default',
        };
      });

      // Then add a special card for adding a new payment method

      cardData.push({
        cardRenderer: CallToActionCard,
        id: 'new',
        name: 'Add payment method',
        tagline: 'Invoice only',
        description:
          'Currently we must validate your payment details manually, which might take a little time.',
        actions: [
          <ButtonLink
            data-selenium-add-payment
            key="create"
            to="/user/payment-methods/create"
            size="small"
          >
            Add…
          </ButtonLink>,
        ],
      });

      return cardData;
    };

    this.state = {
      deleting: false,
      paymentMethodToDelete: null,
      showDeleteConfirm: false,
    };
  }

  componentDidMount() {
    this.props.reload();
  }

  render() {
    return (
      <main className="scrollArea">
        <h2 className="heading-section">Payment methods</h2>
        <CardList data={this.getCardData()} dataKey="id" id="payment-methods" />
        <SesamModal
          className="simple-dialog"
          isOpen={this.state.showDeleteConfirm}
          onRequestClose={this.cancelDelete}
          contentLabel="Confirmation"
        >
          <h2 className="heading-page">Please confirm</h2>
          <p>
            Do you want to delete the payment method &quot;
            {this.state.paymentMethodToDelete && this.state.paymentMethodToDelete.name}
            &quot;?
          </p>
          <div className="toolbar toolbar--right">
            <Button onClick={this.cancelDelete} disabled={this.state.deleting}>
              Cancel
            </Button>
            <PromiseButton onClick={this.approveDelete} pending="Deleting…" theme="danger">
              Yes, delete
            </PromiseButton>
          </div>
        </SesamModal>
      </main>
    );
  }
}

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    paymentMethods: state.paymentMethods,
    currentUserId: state.user.user_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reload: () => dispatch(PaymentMethodActions.loadAll()),
    remove: (id) => dispatch(PaymentMethodActions.remove(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
