import { ApiConf, SomeObject } from 'Types/common.types';
import sfetch, { sfetchJson } from 'Internals/sfetch';
import isEmpty from 'lodash/isEmpty';

async function getInfo(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(subUrl, options);
}

async function getLicense(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(`${subUrl}/license`, options);
}

async function getLicenseStatus(url: string, token: string, subId: string) {
  const headers = new Headers();
  headers.append('Authorization', `bearer ${token}`);
  return await sfetchJson(`${url}/license`, { headers })
    .then((license) => {
      if (license._id === subId) {
        // looks like we are talking to a licensed node, everything is fine
        return 'okay';
      } else if (isEmpty(license)) {
        // empty doc, node is not licensed yet
        return 'unlicensed';
      }
      return 'id_mismatch';
    })
    .catch((error) => {
      if (error.response && error.response.status === 403) {
        return 'access_denied';
      }
      // don't know what situations ends up here, so we rethrow
      throw error;
    });
}

async function putLicense(apiConf: ApiConf, license: any) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/license`;
  const options: RequestInit = {
    body: license,
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'text/plain',
    },
    method: 'PUT',
  };
  return await sfetch(url, options);
}

async function getPermission(apiConf: ApiConf, permissionPathArray: any[]) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/permissions`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  const permissionPath = permissionPathArray
    .map((segment) => encodeURIComponent(segment))
    .join('/');

  return await sfetchJson(`${url}/${permissionPath}`, options);
}

async function putPermission(
  apiConf: ApiConf,
  permissionPathArray: any[],
  permissionData: SomeObject
) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/permissions`;
  const options: RequestInit = {
    body: JSON.stringify(permissionData),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  };
  const permissionPath = permissionPathArray
    .map((segment) => encodeURIComponent(segment))
    .join('/');
  return await sfetchJson(`${url}/${permissionPath}`, options);
}

// test the url and see if it appears to be a node (and not something else)
async function checkIsNode(url: string) {
  return await sfetchJson(url, { credentials: 'include' })
    .then((config) => {
      if (config.type && config.type === 'sesam:node') {
        return 'okay';
      }
      // fallback until nodes has type in the root document (can be removed later)
      if (config._id && config._id.startsWith('sesam:node')) {
        return 'okay';
      }
      return 'not_a_node';
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return 'not_found';
      }
      if (error instanceof SyntaxError) {
        return 'not_json';
      }
      if (error instanceof TypeError) {
        // both network problems and ssl problems appear as the same, the user can check console
        return 'network_problem';
      }
      // don't know what situations ends up here, so we rethrow
      throw error;
    });
}

async function getLogs(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/logs`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
    },
  };
  return await sfetchJson(url, options);
}

export default {
  getInfo,
  getLicense,
  getLicenseStatus,
  putLicense,
  getPermission,
  putPermission,
  checkIsNode,
  getLogs,
};
