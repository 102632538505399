import get from 'lodash/get';
import type { PipeMap } from 'Types/pipes.types';
import type { SystemID, System } from 'Types/system.types';
import { InboundMap, OutboundMap, TransformMap } from 'Types/common.types';

export function getPipesFromSystem(systemId: SystemID, pipes: PipeMap, inbounds: InboundMap) {
  const pipesFromSystem = Object.keys(get(inbounds, systemId, {}));
  return pipesFromSystem.map((id) => pipes[id]).filter(Boolean);
}

export function getPipesToSystem(systemId: SystemID, pipes: PipeMap, outbounds: OutboundMap) {
  const pipesToSystem = Object.keys(get(outbounds, systemId, {}));
  return pipesToSystem.map((id) => pipes[id]).filter(Boolean);
}

export function getPipesWithSystemTransform(
  systemId: SystemID,
  pipes: PipeMap,
  transforms: TransformMap
) {
  const pipesWithTransformIds = Object.keys(get(transforms, systemId, {}));
  return pipesWithTransformIds.map((id) => pipes[id]).filter(Boolean);
}

export function getSystem(systemId: SystemID, systems: System[]) {
  return systems.find((s) => s._id === systemId);
}

export function hasConfigErrors(system: System) {
  if (!system || !system.runtime) return false;
  const errors = system.runtime['config-errors'];
  if (Array.isArray(errors)) return errors.length > 0;
  else return false;
}

export function isValidConfig(system: System) {
  return system && system.runtime['is-valid-config'];
}

export function systemConfigSorter(a: System, b: System) {
  if (isValidConfig(a) && isValidConfig(b)) {
    if (!hasConfigErrors(a) && hasConfigErrors(b)) return -1;
    if (hasConfigErrors(a) && !hasConfigErrors(b)) return 1;
  }
  if (isValidConfig(a) && !isValidConfig(b)) return -1;
  if (!isValidConfig(a) && isValidConfig(b)) return 1;

  return 0;
}

export function getGroup(system: System) {
  const systemGroupFromMetadata = get(system, 'config.effective.metadata[system-group]');
  if (systemGroupFromMetadata) {
    return systemGroupFromMetadata;
  } else {
    return system._id.split('-')[0];
  }
}

export function isTenant(system: System) {
  return get(system, 'config.effective.metadata.$tenant', false);
}
