import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useSubId } from '../../hooks/sesam';
import { Form } from 'Common/forms';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import { setDefault } from 'Internals/utils';
import SesamLink from 'Common/Links/SesamLink';

import NotificationRulesAPI from '../../api/pipe-notification-rules';
import { SomeObject } from 'Types/common.types';
import useResourceInject from '../../hooks/useResourceInject';
import InfoTooltip from '../../components/common/InfoTooltip/InfoTooltip';

import './style.css';

const PIPE_MONITORING_PRICE = 20;

function transit_decode_decimal(value: string) {
  if (value.startsWith('~f')) {
    return value.slice(2);
  } else {
    return value;
  }
}

function fixedType(key: string) {
  switch (key) {
    case 'datahub':
      return 'Datahub';
    case 'microservices':
      return 'Microservices';
    default:
      return key;
  }
}

function dynamicType(key: string) {
  switch (key) {
    case 'backup_pr_GB':
      return 'Backup';
    case 'sla_pr_GB':
      return 'Service level';
    case 'storage_pr_GB':
      return 'Base data price';
    case 'vpn_pr_GB':
      return 'VPN';
    case 'monitoring_pr_GB':
      return 'Monitoring';
    default:
      return key;
  }
}

type PriceCalculatorProps = {
  value: {
    currency: string;
    fixed_total: string;
    dynamic_total: string;
    expected_total_data_cost?: string;
    fixed_items: {
      [key: string]: {
        sub_type: string;
        price: string;
      };
    };
    dynamic_items: {
      [key: string]: {
        price: string;
      };
    };
  };
  oldPrice: SomeObject;
  shouldFetchNotificationRules: boolean;
  expectedDataSize: number;
  onExpectedDataSizeChange: (size: Event) => void;
  disableExpectedDataSize: boolean;
  currentDataSize: number;
  products?: SomeObject;
  isProvisionerVersion2?: boolean;
};

const useStyles = makeStyles({
  input: {
    justifyContent: 'flex-end',
  },
});

const calculateSlaPrice = (slaPricePerGb: number, expectedDataSize: number) => {
  if (expectedDataSize < 50) return slaPricePerGb * 50;
  if (expectedDataSize > 300) return slaPricePerGb * 300;
  return slaPricePerGb * expectedDataSize;
};

const getDataSize = (
  subType: string,
  dataSize: number,
  isSearchEnabled: boolean,
  isProvisionerVersion2: boolean | undefined
) => {
  if (subType === 'developer' || subType === 'developer-pro') return 0;
  // Integrated search incures a 33% increase in expected data size
  return isSearchEnabled && isProvisionerVersion2 ? dataSize + dataSize * 0.33 : dataSize;
};

function PriceCalculator(props: PropsWithChildren<PriceCalculatorProps>) {
  const subId = useSubId();
  const styles = useStyles();

  const subType = props.value.fixed_items.datahub.sub_type;
  const isSearchEnabled = get(props, 'products.search');
  const expectedDataSize = getDataSize(
    subType,
    props.expectedDataSize,
    isSearchEnabled,
    props.isProvisionerVersion2
  );
  const currentDataSize = getDataSize(
    subType,
    props.currentDataSize,
    isSearchEnabled,
    props.isProvisionerVersion2
  );

  let shouldFetchNotificationRules = setDefault(props.shouldFetchNotificationRules, false);

  const getAllNotificationRules = useCallback(() => {
    if (shouldFetchNotificationRules) {
      return NotificationRulesAPI.getAll(subId);
    } else return Promise.resolve([]);
  }, [subId]);

  const [notificationRules] = useResourceInject(getAllNotificationRules, true, []);

  const numOfPipesWithNotificationRules = useMemo(() => {
    const pipesWithNotificationRules: { [pipe_id: string]: true } = {};
    if (notificationRules) {
      for (const rule of notificationRules) {
        if (rule.pipe_id) {
          pipesWithNotificationRules[rule.pipe_id] = true;
        }
      }
    }
    return Object.keys(pipesWithNotificationRules).length;
  }, [notificationRules]);

  function toPrice(encoded: string, ...additions: number[]) {
    const base = parseFloat(transit_decode_decimal(encoded));
    let total = base;
    for (const addition of additions) {
      total = total + addition;
    }

    return encoded ? `${total.toFixed(2)} ${props.value.currency}` : '?';
  }

  const getServiceLevelCost = () => {
    let size = expectedDataSize;
    if (expectedDataSize < 50) size = 50;
    else if (expectedDataSize > 300) size = 300;
    return `${size} * ${toPrice(slaPricePerGb.toString())} = ${toPrice(slaPrice.toString())}`;
  };

  // Filter out the base data price if subscription size is developer or developer-pro
  const dynamicItemKeys = Object.keys(props.value.dynamic_items).filter(
    (key) => !(key === 'storage_pr_GB' && (subType === 'developer' || subType === 'developer-pro'))
  );

  const searchPrice =
    isSearchEnabled &&
    props.isProvisionerVersion2 &&
    subType !== 'developer' &&
    subType !== 'developer-pro'
      ? props.expectedDataSize *
        0.33 *
        parseFloat(transit_decode_decimal(props.value.dynamic_total))
      : 0;

  // Note: we have to be careful, this value is hardcoded in EUR
  const monitoringPricePerGB = props.value.dynamic_items.monitoring_pr_GB
    ? parseFloat(transit_decode_decimal(props.value.dynamic_items.monitoring_pr_GB.price))
    : 0;
  const monitoringPrice = dynamicItemKeys.includes('monitoring_pr_GB')
    ? monitoringPricePerGB * expectedDataSize
    : numOfPipesWithNotificationRules * PIPE_MONITORING_PRICE;

  const parsedExpectedDataCost = props.value.expected_total_data_cost
    ? parseFloat(transit_decode_decimal(props.value.expected_total_data_cost))
    : 0;

  const slaPricePerGb = props.value.dynamic_items.sla_pr_GB
    ? parseFloat(transit_decode_decimal(props.value.dynamic_items.sla_pr_GB.price))
    : 0;
  const slaPrice = calculateSlaPrice(slaPricePerGb, expectedDataSize);

  const vpnPricePerGb = props.value.dynamic_items.vpn_pr_GB
    ? parseFloat(transit_decode_decimal(props.value.dynamic_items.vpn_pr_GB.price))
    : 0;
  const vpnPrice = vpnPricePerGb * expectedDataSize;

  return (
    <div className="price-calculator">
      <table>
        <tbody>
          {Object.keys(props.value.fixed_items).map((key) => (
            <tr key={key}>
              <td>
                {fixedType(key)} {/*{props.value.fixed_items[key].sub_type}*/}
              </td>
              <td> {toPrice(props.value.fixed_items[key].price)}</td>
            </tr>
          ))}

          {subType !== 'developer' &&
            subType !== 'developer-pro' &&
            dynamicItemKeys.map((key) => (
              <>
                <tr key={key}>
                  {dynamicType(key) === 'Monitoring' && (
                    <td>
                      {dynamicType(key)}{' '}
                      {numOfPipesWithNotificationRules > 0 && (
                        <SesamLink
                          to={`/subscription/${subId}/settings-subscription/notifications`}
                        >
                          {`(${numOfPipesWithNotificationRules} pipe${
                            numOfPipesWithNotificationRules > 1 ? 's' : ''
                          })`}
                        </SesamLink>
                      )}
                    </td>
                  )}
                  {dynamicType(key) !== 'Monitoring' && <td>{dynamicType(key)}</td>}
                  <td> {toPrice(props.value.dynamic_items[key].price)} per GB</td>
                </tr>
                {dynamicType(key) === 'Service level' && (
                  <tr
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: '400',
                      position: 'relative',
                      top: '-10px',
                    }}
                  >
                    <td>
                      Service level cost{' '}
                      <InfoTooltip
                        info="SLA is billed for minimum 50GB data and a maximum of 300GB"
                        style={{ marginLeft: '10px', position: 'relative', bottom: '2px' }}
                      />
                    </td>
                    <td>{getServiceLevelCost()}</td>
                  </tr>
                )}
              </>
            ))}

          {subType !== 'developer' &&
            subType !== 'developer-pro' &&
            !props.disableExpectedDataSize && (
              <tr>
                <td>Expected data size</td>
                <td>
                  <Form component="div" horizontal className={styles.input}>
                    <Input
                      id="expectedDataSize"
                      name="expectedDataSize"
                      value={props.expectedDataSize}
                      margin="dense"
                      onChange={props.onExpectedDataSizeChange}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: 1000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                    <label htmlFor="expectedDataSize">GB</label>
                  </Form>
                </td>
              </tr>
            )}

          {subType !== 'developer' && subType !== 'developer-pro' && props.disableExpectedDataSize && (
            <tr>
              <td>Current data size</td>
              <td>{currentDataSize} GB</td>
            </tr>
          )}

          {subType !== 'developer' &&
            subType !== 'developer-pro' &&
            props.value.expected_total_data_cost &&
            parsedExpectedDataCost > 0 && (
              <tr>
                <td>Expected data cost</td>
                <td>{toPrice(props.value.expected_total_data_cost)}</td>
              </tr>
            )}
          {numOfPipesWithNotificationRules > 0 && !dynamicItemKeys.includes('monitoring_pr_GB') && (
            <tr key="Pipes with monitoring enabled">
              <td>
                Monitoring{' '}
                <SesamLink to={`/subscription/${subId}/settings-subscription/notifications`}>
                  {`(${numOfPipesWithNotificationRules} pipe${
                    numOfPipesWithNotificationRules > 1 ? 's' : ''
                  })`}
                </SesamLink>
              </td>
              <td>{toPrice(String(monitoringPrice))}</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>Total (monthly)</td>
            <td>
              {toPrice(
                props.value.fixed_total,
                monitoringPrice,
                props.value.expected_total_data_cost ? parsedExpectedDataCost : 0,
                vpnPrice,
                searchPrice
              )}
              <br />
              {subType !== 'developer' &&
                subType !== 'developer-pro' &&
                props.expectedDataSize < 1 &&
                `plus ${toPrice(props.value.dynamic_total)} per GB`}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default PriceCalculator;
