import React, { useCallback, useEffect, useRef, useState } from 'react';
import UtilsAPI from '../api/utils';
import { apologise } from 'Redux/thunks/apology';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from 'Types/state.types';
import { uuid } from 'Internals/utils';

const zipMimeTypes = [
  'application/zip',
  'application/x-zip',
  'application/octet-stream',
  'application/x-zip-compressed',
];

export type FileType = {
  blobFile: File;
  name: string;
  uuid: string;
  status: 'attached' | 'uploading' | 'uploaded' | 'failed';
};

interface UploadProps {
  multiple: boolean;
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

function useUpload({ multiple = false, url = '', method = 'POST' }: UploadProps) {
  const triggerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const dropzoneRef: React.RefObject<HTMLDivElement> = useRef(null);

  const [fileList, setFileList] = useState<FileType[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const token = useSelector((state: RootState) => state.subscription.token);

  const dispatch = useDispatch();

  const updateFileList = (nextFile: FileType) => {
    setFileList((fileList: FileType[]) => {
      return fileList.map((file: FileType) => {
        return file.uuid === nextFile.uuid ? nextFile : file;
      });
    });
  };

  const addFiles = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = ev?.target?.files;
    if (files) {
      const newFileList: FileType[] = [];

      Array.from(files).forEach((file) => {
        newFileList.push({
          blobFile: file,
          name: file.name,
          uuid: uuid(),
          status: 'attached',
        });
      });

      setFileList((fileList: FileType[]) => {
        if (multiple) {
          return [...fileList, ...newFileList];
        } else {
          return [...newFileList];
        }
      });

      if (triggerRef.current) {
        triggerRef.current.value = '';
      }
    }
  };

  const removeFile = useCallback((uuid) => {
    setFileList((fileList: FileType[]) => {
      return fileList.filter((file: FileType) => {
        return file.uuid !== uuid;
      });
    });
  }, []);

  const upload = useCallback(() => {
    setUploading(true);
  }, []);

  useEffect(() => {
    triggerRef.current?.addEventListener('change', addFiles);

    return () => {
      triggerRef.current?.removeEventListener('change', addFiles);
    };
  });

  const addFilesFromDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.preventDefault();

    const file = ev.dataTransfer.files[0];

    setFileList((fileList: FileType[]) => {
      if (multiple) {
        return [
          ...fileList,
          {
            blobFile: file,
            name: file.name,
            uuid: uuid(),
            status: 'attached',
          },
        ];
      } else {
        return [
          {
            blobFile: file,
            name: file.name,
            uuid: uuid(),
            status: 'attached',
          },
        ];
      }
    });
  };

  useEffect(() => {
    dropzoneRef.current?.addEventListener('dragover', (ev) => {
      ev.preventDefault();
    });
    dropzoneRef.current?.addEventListener('drop', addFilesFromDrop);

    return () => {
      dropzoneRef.current?.removeEventListener('dragover', (ev) => {
        ev.preventDefault();
      });
      dropzoneRef.current?.removeEventListener('drop', addFilesFromDrop);
    };
  });

  useEffect(() => {
    async function uploadToServer() {
      Promise.all(
        fileList.map((file: FileType) => {
          if (file.status === 'attached' || file.status === 'failed') {
            updateFileList({ ...file, status: 'uploading' });
            let mimeType = file.blobFile?.type || 'text/plain';
            if (zipMimeTypes.includes(mimeType)) {
              mimeType = 'application/zip';
            }
            return UtilsAPI.upload(token, url, mimeType, method, file.blobFile)
              .then(() => {
                updateFileList({ ...file, status: 'uploaded' });
              })
              .catch((e) => {
                updateFileList({ ...file, status: 'failed' });
                dispatch(apologise(e));
                setUploading(false);
                return;
              });
          }
        })
      ).then(() => {
        setUploading(false);
      });
    }
    if (uploading) {
      uploadToServer();
    }
  }, [uploading]);

  return { triggerRef, dropzoneRef, fileList, removeFile, upload };
}

export default useUpload;
