/*
 Copyright (C) Sesam.io - All Rights Reserved.
 Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import merge from 'lodash/merge';

import './Graph.css';
import { compose } from 'redux';
import { withTheme } from '@material-ui/core';
import isEqual from 'lodash/isEqual';

function getDefaultGraphOptions(theme) {
  return {
    layout: {
      randomSeed: 1,
    },
    interaction: {
      hover: true,
      hoverConnectedEdges: false,
      selectConnectedEdges: false,
    },
    width: '100%',
    height: '100%',
    autoResize: false,
    edges: {
      shadow: false,
      width: 2,
      arrows: {
        to: {
          enabled: true,
        },
      },
      arrowStrikethrough: false,
      color: {
        color: theme.palette.text.primary,
        highlight: theme.palette.background.dark,
        hover: theme.palette.background.dark,
      },
      smooth: {
        type: 'cubicBezier',
        forceDirection: 'horizontal',
      },
      scaling: {
        max: 8,
      },
      font: {
        color: theme.palette.text.primary,
        background: theme.palette.background.light,
      },
    },
    nodes: {
      shadow: false,
      shape: 'circularImage',
      color: {
        border: theme.palette.background.semilight,
        background: theme.palette.inverted,
        highlight: {
          border: theme.palette.background.dark,
          background: theme.palette.background.light,
        },
        hover: {
          border: theme.palette.background.dark,
        },
      },
      size: 30,
      shapeProperties: {
        useBorderWithImage: true,
        interpolation: true,
      },
      font: {
        color: theme.palette.text.primary,
        background: theme.palette.background.light,
      },
    },
    physics: {
      enabled: false,
    },
  };
}

/*
 * Draws a static graph using vis.js
 */
class StaticGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawing: false,
    };

    this.network = null;

    this.containerRef = React.createRef();

    this.makeGraph = () => {
      // Container
      const container = this.containerRef.current;
      this.setState({
        drawing: true,
      });

      import('vis-network/standalone/esm/vis-network').then((visNetworkModule) => {
        // let browser render the drawing dialog
        setTimeout(() => {
          const defaultGraphOptions = getDefaultGraphOptions(this.props.theme);
          const options = merge(defaultGraphOptions, this.props.options);

          this.network = new visNetworkModule.Network(container, this.props.graph, options);

          this.network.on('click', (params) => {
            let item;
            if (params.nodes.length > 0) {
              item = this.props.graph.nodes.find((i) => i.id === params.nodes[0]);
            } else if (params.edges.length > 0) {
              item = this.props.graph.edges.find((i) => i.id === params.edges[0]);
            }
            const id = get(item, 'meta.ownId');
            const type = get(item, 'meta.type');
            if (id && type) this.props.onGotoNode(type, id);
          });

          this.network.on('hoverEdge', (params) => {
            this.props.onHoverEdge(params);
          });

          this.network.on('hoverNode', (params) => {
            this.props.onHoverNode(params);
          });

          this.network.on('selectEdge', (params) => {
            this.props.onSelectEdge(params);
          });

          this.network.on('deselectEdge', (params) => {
            this.props.onDeselectEdge();
          });

          if (this.props.autoSelectMainNode) {
            this.network.selectNodes([this.props.mainNodeId], true);
          }
          this.setState({
            drawing: false,
          });
          if (this.props.fit) {
            this.network.fit();
          } else {
            this.network.focus(this.props.mainNodeId);
          }
        }, 50);
      });
    };

    this.updateGraph = () => {
      if (this.network) {
        this.network.setData(this.props.graph);
        if (this.props.autoSelectMainNode) {
          this.network.selectNodes([this.props.mainNodeId], true);
        }
        if (this.props.fit) {
          this.network.fit();
        } else {
          this.network.focus(this.props.mainNodeId);
        }
      }
    };
  }

  componentDidMount() {
    this.makeGraph();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.graph, prevProps.graph)) {
      this.updateGraph();
    }
    if (this.props.theme.palette.type !== prevProps.theme.palette.type) {
      if (this.network) {
        const defaultGraphOptions = getDefaultGraphOptions(this.props.theme);
        const options = merge(defaultGraphOptions, this.props.options);
        this.network.setOptions(options);
      }
    }
  }

  render() {
    return (
      <div className={`graph ${this.props.theme.palette.type === 'dark' ? 'graph--dark' : ''}`}>
        {this.state.drawing && <div className="update">Rendering graph…</div>}
        <div ref={this.containerRef} className="diagram">
          Loading..
        </div>
        <div className="graph__overlay">{this.props.overlay}</div>
      </div>
    );
  }
}

StaticGraph.propTypes = {
  autoSelectMainNode: PropTypes.bool,
  graph: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
    edges: PropTypes.array.isRequired,
  }).isRequired,
  onGotoNode: PropTypes.func.isRequired,
  onHoverNode: PropTypes.func,
  onHoverEdge: PropTypes.func,
  onBlurEdge: PropTypes.func,
  onSelectEdge: PropTypes.func,
  onDeselectEdge: PropTypes.func,
  mainNodeId: PropTypes.string.isRequired,
  options: PropTypes.object,
  fit: PropTypes.bool,
  imports: PropTypes.shape(),
  overlay: PropTypes.node,
  theme: PropTypes.shape({}).isRequired,
};

StaticGraph.defaultProps = {
  autoSelectMainNode: true,
  fit: false,
  onHoverNode: () => {},
  onHoverEdge: () => {},
  onBlurEdge: () => {},
  onSelectEdge: () => {},
  onDeselectEdge: () => {},
  overlay: null,
};

export default compose(withTheme)(StaticGraph);
