import sfetch, { sfetchJson } from 'Internals/sfetch';
import { SomeObject, SupportedOperation } from 'Types/common.types';

export type UserProfile = {
  email: string;
  email_verified: boolean; // double check if this is not "is_verified"
  name: string;
  nickname: string;
  picture: string;
};

export type UserInfo = {
  user_id: string;
  profile: UserProfile;
  isLoggedIn: true;
  isPortalAdmin: boolean;
  announcements: string[];
  lastCreateTrialRequest: SomeObject | null;
  providerIds: string[];
  providerWhitelist: string[];
  approvedTos: number;
};

export type SupportedOperationsResponse = {
  update_user_profile?: SupportedOperation & {
    'reason-code'?: 'not_logged_in' | 'email_not_verified' | 'portal_is_in_readonly_mode';
  };
  create_subscription?: SupportedOperation & {
    'reason-code'?: 'not_logged_in' | 'email_not_verified' | 'portal_is_in_readonly_mode';
  };
  create_trial_subscription?: SupportedOperation & {
    'reason-code'?:
      | 'not_logged_in'
      | 'too_many_trial_subscriptions'
      | 'email_not_verified'
      | 'user_is_not_portal_admin'
      | 'portal_is_in_readonly_mode';
  };
  request_trial_subscription?: SupportedOperation & {
    'reason-code'?:
      | 'not_logged_in'
      | 'too_many_trial_subscriptions'
      | 'email_not_verified'
      | 'has_pending_create_trial_request'
      | 'portal_is_in_readonly_mode';
  };
  request_email_verification?: SupportedOperation & {
    'reason-code'?:
      | 'not_logged_in'
      | 'email_already_verified'
      | 'has_pending_verification_email'
      | 'portal_is_in_readonly_mode';
  };
  send_reset_password_email?: SupportedOperation & {
    'reason-code'?:
      | 'no_such_user'
      | 'has_pending_reset_password_emails'
      | 'portal_is_in_readonly_mode'
      | 'user_from_jwt';
  };
  create_paymentmethod?: SupportedOperation & {
    'reason-code'?: 'not_logged_in' | 'email_not_verified' | 'portal_is_in_readonly_mode';
  };
  delete_account?: SupportedOperation & {
    'reason-code'?:
      | 'not_logged_in'
      | 'is_last_admin'
      | 'is_paymentmethod_owner'
      | 'portal_is_in_readonly_mode';
  };
  activate_portal_admin_powers?: SupportedOperation & {
    'reason-code'?: 'not_logged_in' | 'user_is_not_portal_admin';
  };
};

export type UserProfileResponse = {
  userInfo: UserInfo;
  'supported-operations': SupportedOperationsResponse;
};

async function get(portalUrl: string): Promise<UserProfileResponse> {
  const options: RequestInit = { credentials: 'include' };
  return await sfetchJson(`${portalUrl}/profile2`, options);
}

type ProfileForm = {
  name: string;
};
async function put(portalUrl: string, profileForm: ProfileForm, userInfo: SomeObject) {
  let data = {
    userInfo: {
      profile: {},
    },
  };

  if (userInfo) {
    data.userInfo = Object.assign(data.userInfo, userInfo);
  }

  if (profileForm) {
    data.userInfo.profile = Object.assign(data.userInfo.profile, {
      name: profileForm.name,
    });
  }

  const options: RequestInit = {
    credentials: 'include',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return await sfetchJson(`${portalUrl}/profile`, options);
}

async function remove(portalUrl: string, userId: string) {
  const options: RequestInit = {
    credentials: 'include',
    method: 'DELETE',
  };

  return await sfetchJson(`${portalUrl}/profile/${userId}`, options);
}

async function putPassword(portalUrl: string, password: string, old_password: string | null) {
  const data = new FormData();
  data.append('password', password);
  if (old_password) data.append('old_password', old_password);
  const options: RequestInit = {
    credentials: 'include',
    method: 'PUT',
    body: data,
  };
  return await sfetch(`${portalUrl}/password`, options);
}

async function requestEmailVerification(portalUrl: string) {
  const options: RequestInit = {
    credentials: 'include',
    method: 'POST',
  };
  return await sfetch(`${portalUrl}/request-email-verification`, options);
}

export default {
  get,
  put,
  remove,
  putPassword,
  requestEmailVerification,
};
