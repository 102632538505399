import { LogChange } from 'Types/common.types';
import { buildChangeLog, buildUnreadChangeLogs } from 'Utils/changelog.utils';

type ChangelogArgs = {
  lastSeen: number;
  showUnreadChanges: boolean;
  changelog: LogChange[];
};

type CurrentChangelogState = {
  allLogs: LogChange[];
  unreadChanges: LogChange[];
  lastSeen: number;
  showUnreadChanges: boolean;
  latestLogTimestamp: number;
};

type ChangelogServiceAPI = {
  getUnreadLogs: () => LogChange[];
  getLogsToDisplay: () => LogChange[];
  isThereUnreadLogs: () => boolean;
  setLastSeen: (lastSeen: number) => void;
  getLastSeen: () => number;
  getLatestLogTimestamp: () => number;
};

export const initChangelogService = (args: ChangelogArgs): ChangelogServiceAPI => {
  const currentState: CurrentChangelogState = {
    allLogs: [],
    unreadChanges: buildUnreadChangeLogs(args.changelog, args.lastSeen),
    lastSeen: args.lastSeen,
    showUnreadChanges: args.showUnreadChanges,
    latestLogTimestamp: args.changelog[args.changelog.length - 1].timestamp,
  };

  currentState.allLogs = buildChangeLog({
    changelog: args.changelog,
    unreadChanges: currentState.unreadChanges,
  });

  const getLogsToDisplay = () => {
    const result =
      currentState.unreadChanges.length > 0 ? currentState.unreadChanges : currentState.allLogs;

    return result.sort((a, b) => b.timestamp - a.timestamp);
  };

  const getUnreadLogs = () => currentState.unreadChanges;

  const isThereUnreadLogs = () => currentState.unreadChanges.length > 0;

  const setLastSeen = (lastSeen: number) => (currentState.lastSeen = lastSeen);

  const getLastSeen = () => currentState.lastSeen;

  const getLatestLogTimestamp = () => currentState.latestLogTimestamp;

  return {
    getUnreadLogs,
    getLogsToDisplay,
    isThereUnreadLogs,
    setLastSeen,
    getLastSeen,
    getLatestLogTimestamp,
  };
};
