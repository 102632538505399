import React, { PropsWithChildren } from 'react';

import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

import { Action } from './ActionPanel';
import { confirmBefore } from 'Common/Confirmation';
import type { Pipe } from 'Types/pipes.types';

type DeleteActionProps = PropsWithChildren<{
  title: string;
  onDelete: () => void;
  selection: Pipe[];
}>;

/*
 * Delete action found on all list pages.
 */
export function DeleteAction({ title, onDelete, selection }: DeleteActionProps) {
  function onClick() {
    confirmBefore(`This will delete ${size(selection)} selected ${title}(s)`, onDelete);
  }

  return (
    <Action name="Delete…" theme="danger" onActionClick={onClick} enabled={!isEmpty(selection)} />
  );
}
