import React from 'react';
import { getIndexDeleted, getIndexWithoutDeleted, getLogDeleted } from 'Internals/datasets';
import { Dataset } from 'Types/dataset.types';

export const useOverviewDatasets = (datasets: Dataset[]) =>
  React.useMemo(() => {
    const sumLatest = datasets.reduce((sum, dataset) => sum + getIndexWithoutDeleted(dataset), 0);
    const sumDeleted = datasets.reduce((sum, dataset) => sum + getIndexDeleted(dataset), 0);
    const sumHistory = datasets.reduce((sum, dataset) => sum + getLogDeleted(dataset), 0);

    return { sumLatest, sumDeleted, sumHistory };
  }, [datasets]);
