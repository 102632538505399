import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import DatasetActions from 'Redux/thunks/datasets';
import Box from 'Common/Box/Box';
import { getSinkDatasetMemoized } from 'Internals/pipes';
import { leafPipesSelector } from 'Redux/selectors';
import { DatasetInspectorContainer } from '../../../components/dataset-inspector/DatasetInspectorContainer';
import PipeStatus from '../../../components/pipe-status/PipeStatus';
import type { AppDispatch, RootState } from 'Types/state.types';
import type { DatasetMap } from 'Types/dataset.types';
import type {Pipe } from 'Types/pipes.types';

const PipeExecutionLog: React.FC<{
  pipe: Pipe;
  datasets: DatasetMap;
  loadDataset: (id: string) => void;
  addCustomActions: () => void;
  registerRefresh: (func: Function) => void;
  unregisterRefresh: (func: Function) => void;
  removeCustomActions: () => void;
  isPipeLeaf: boolean;
  params: {
    pipeID: string;
    subId: string;
  };
}> = ({
  pipe,
  datasets,
  loadDataset,
  registerRefresh,
  unregisterRefresh,
  addCustomActions,
  removeCustomActions,
  isPipeLeaf,
  params,
}) => {
  const refreshSinkDataset = () => {
    const sinkDataset = getSinkDatasetMemoized(pipe, datasets);
    if (sinkDataset) {
      loadDataset(sinkDataset._id);
    }
  };

  useEffect(() => {
    registerRefresh(refreshSinkDataset);
    refreshSinkDataset();

    return unregisterRefresh(refreshSinkDataset);
  }, []);

  const sinkDataset = getSinkDatasetMemoized(pipe, datasets);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%',
      }}
    >
      <div style={{ alignSelf: 'flex-start', marginBottom: '10px', width: '100%' }}>
        <Box>
          <Box.Content compact>
            <PipeStatus
              pipe={pipe}
              sinkDataset={sinkDataset}
              subId={params.subId}
              isPipeLeaf={isPipeLeaf}
            />
          </Box.Content>
        </Box>
      </div>
      <DatasetInspectorContainer
        addCustomActions={addCustomActions}
        datasetID={`system:pump:${params.pipeID}`}
        registerRefresh={registerRefresh}
        removeCustomActions={removeCustomActions}
        unregisterRefresh={unregisterRefresh}
        isPipeExecutionLog={true}
        tab="execution"
      />
    </div>
  );
};

export type OwnProps = {
  params: {
    pipeID: string;
  };
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const leafPipes = leafPipesSelector(state);
  const isPipeLeaf = leafPipes.includes(ownProps.params.pipeID);
  return {
    isPipeLeaf,
    pipe: state.pipes[ownProps.params.pipeID],
    datasets: state.datasets,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadDataset: (id: string) => dispatch(DatasetActions.load(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PipeExecutionLog);
