import sfetch, { sfetchJson, sfetchText } from 'Internals/sfetch';
import type { System } from 'Types/system.types';
import type { ApiConf, SomeObject } from 'Types/common.types';
import { SecretPostResponse, SecretDeleteResponse } from './secrets';

async function add(apiConf: ApiConf, system: System) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/systems?force=true`;
  const options: RequestInit = {
    body: JSON.stringify([system]),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };
  return await sfetchJson(url, options);
}

async function remove(apiConf: ApiConf, id?: string) {
  if (!id) return Promise.reject(new Error('ID for system not supplied'));
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'DELETE',
  };
  return await sfetch(url, options);
}

async function update(apiConf: ApiConf, id: string, updatedSystem: System) {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}/config?force=true`;
  const options: RequestInit = {
    body: JSON.stringify(updatedSystem),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  };
  return await sfetchJson(url, options);
}

async function get(apiConf: ApiConf, id?: string): Promise<System> {
  if (!id) return Promise.reject(new Error('ID for system not supplied'));
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(url, options);
}

async function getAll(apiConf: ApiConf, includeInternal = false) {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/systems`;

  if (includeInternal) {
    url = url + '?include-internal-systems=true';
  }

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(url, options);
}

async function getSinkPrototypes(apiConf: ApiConf, id: string) {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}/sink-prototypes`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(url, options);
}

async function getSourcePrototypes(apiConf: ApiConf, id: string) {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}/source-prototypes`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(url, options);
}

async function getSystemPrototypes(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  const url = `${subUrl}/system-prototypes`;
  return await sfetchJson(url, options);
}

async function getSecrets(apiConf: ApiConf, id: string): Promise<string[]> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}/secrets`;
  const options: RequestInit = {
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(url, options);
}

async function addSecrets(
  apiConf: ApiConf,
  id: string,
  secrets: SomeObject
): Promise<SecretPostResponse> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}/secrets`;
  const options: RequestInit = {
    body: JSON.stringify(secrets),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };
  return await sfetchJson(url, options);
}

async function removeSecret(
  apiConf: ApiConf,
  id: string,
  secretName: string
): Promise<SecretDeleteResponse> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/systems/${idUrl}/secrets/${secretName}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'DELETE',
  };
  return await sfetch(url, options);
}

async function reload(apiConf: ApiConf, id: string) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/systems/${id}/reload`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'post',
  };

  return await sfetch(url, options);
}

async function getStatus(apiConf: ApiConf, systemId: string) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(`${subUrl}/systems/${systemId}/status`, options);
}

async function getLogs(apiConf: ApiConf, systemId: string, since?: string) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  let url = `${subUrl}/systems/${systemId}/logs`;
  if (since !== undefined && since !== null) {
    url = url + `?since=${since}`;
  }

  return await sfetchText(url, options);
}

export default {
  add,
  remove,
  update,
  get,
  getAll,
  getSinkPrototypes,
  getSourcePrototypes,
  getSystemPrototypes,
  getSecrets,
  addSecrets,
  removeSecret,
  reload,
  getStatus,
  getLogs,
};
