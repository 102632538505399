import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import isNull from 'lodash/isNull';

import { ActionPanel, Action, LinkAction } from '../../action/ActionPanel';
import { DatasetInspectorContext } from '../DatasetInspectorContext';
import { TestID } from 'src/testID';

function DatasetInspectorViewerEntityActions(props) {
  const [state, actions] = useContext(DatasetInspectorContext);
  const hasNextEntity = state.nextEntities.length > 0;

  const repostButtonText = props.displayRepostAll ? 'Repost upstream' : 'Repost...';
  const repostButtonAction = props.displayRepostAll ? props.repostDeadEntities : actions.repost;
  const repostButtonTestId = props.displayRepostAll
    ? TestID.RepostSelectedDeadEntityButton
    : TestID.RepostSelectedEntityButton;

  return (
    <nav className="dataset-inspector-viewer__entity-actions">
      <ActionPanel>
        <Action
          name="Back to latest"
          enabled={hasNextEntity}
          onActionClick={props.onBackToLatest}
        />
        <Action
          name="Previous"
          enabled={state.hasPrevious}
          onActionClick={actions.loadPreviousEntity}
          tooltip={
            state.hasPrevious
              ? 'Go to previous entity'
              : state.previousEntity && isNull(state.previousEntity._previous)
              ? 'This is the first version of entity'
              : 'Previous version unavailable due to compaction'
          }
        />
        <Action name="Next" enabled={hasNextEntity} onActionClick={actions.loadNextEntity} />
        <Action
          name={props.compare ? 'Hide compare' : 'Compare'}
          enabled={
            state.selectedEntity &&
            state.previousEntity &&
            state.selectedEntity._updated !== state.previousEntity._updated
          }
          onActionClick={props.onToggleCompare}
        />
        <Action
          name={repostButtonText}
          enabled={state.selectedEntity !== null}
          onActionClick={repostButtonAction}
          testId={repostButtonTestId}
        />
        {props.isDeadLetterDataset && (
          <Action
            name="Repost to original dataset"
            enabled={state.selectedEntity !== null}
            onActionClick={actions.postDeadEntity}
          />
        )}
        {!!props.sourceEntityPath && (
          <LinkAction
            enabled={!!props.sourceEntityPath}
            name={props.sourceEntityPath ? 'Go to source entity' : "Can't find source entity"}
            to={props.sourceEntityPath}
          />
        )}
      </ActionPanel>
    </nav>
  );
}

DatasetInspectorViewerEntityActions.propTypes = {
  onBackToLatest: PropTypes.func.isRequired,
  sourceEntityPath: PropTypes.string,
  onToggleCompare: PropTypes.func.isRequired,
  compare: PropTypes.bool.isRequired,
  isDeadLetterDataset: PropTypes.bool,
};

export default DatasetInspectorViewerEntityActions;
