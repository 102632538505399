import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@material-ui/core/styles';
import { darkTheme, theme } from '../../themes';
import Apologies from '../apologies';
import { Toaster } from '../toaster/Toaster';
import faviconPath from 'url:../../images/favicon.ico';

const AppRoot = ({ children, darkModeActive }) => (
  <ThemeProvider theme={darkModeActive ? darkTheme : theme}>
    <div className="app-root">
      <Helmet link={[{ rel: 'icon', href: faviconPath }]} />
      {children}
      <Apologies />
      <Toaster />

      <div id="busyIndicator" style={{ display: 'none' }}>
        Loading...
      </div>
      <div id="busyIndicatorShowCounter" style={{ display: 'none' }} />
    </div>
  </ThemeProvider>
);

AppRoot.propTypes = {
  children: PropTypes.node.isRequired,
  darkModeActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    darkModeActive: state.theme.dark,
  };
}

const Connected = connect(mapStateToProps)(AppRoot);

export { Connected as AppRoot };
