import { LogChange } from 'Types/common.types';

export type BuildChangeLogArgs = {
  changelog: LogChange[];
  unreadChanges: LogChange[];
};

export const buildChangeLog = (args: BuildChangeLogArgs) => {
  const { changelog, unreadChanges } = args;
  const changes: LogChange[] = [...changelog];

  if (unreadChanges.length > 0) {
    return [...unreadChanges];
  }

  return changes;
};

export const buildUnreadChangeLogs = (changelog: LogChange[], lastSeen?: number) => {
  if (lastSeen === undefined) {
    return [];
  }

  return changelog.filter((change: LogChange) => {
    return change.timestamp > lastSeen;
  });
};

export enum ChangelogDialogAction {
  open = 'open',
  close = 'close',
}

type ChangeLogDialogArgs = {
  open?: boolean;
  openOnUnreadChanges?: boolean;
  isThereUnreadLogs?: boolean;
};

export const calculateChangelogDialogAction = (
  args: ChangeLogDialogArgs
): ChangelogDialogAction => {
  if (args?.open === true) {
    return ChangelogDialogAction.open;
  }

  if (args?.openOnUnreadChanges === true && args.isThereUnreadLogs === true) {
    return ChangelogDialogAction.open;
  }

  return ChangelogDialogAction.close;
};
