import React from 'react';
import PropTypes from 'prop-types';
import stringify from 'json-stable-stringify';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { compose } from 'redux';
import { withTheme } from '@material-ui/core/styles';
import { withDynamicImports } from 'Components/withDynamicImports.hoc';
import './JsonCompare.css';

const CODESTYLE_KEY = 'sesam-editor-codestyle';

const getFontSize = () => {
  const codeStyleOverrides = JSON.parse(localStorage?.getItem(CODESTYLE_KEY) || '{}');
  return codeStyleOverrides?.font_size ?? 12;
};

/*
 * Panel that displays diff between two JSON files.
 */
class JsonCompare extends React.Component {
  constructor() {
    super();

    const filter = (value) => pickBy(value, (val, key) => !key.startsWith('_'));

    const format = (value) =>
      stringify(value, {
        space: '  ',
      });

    this.getCurrentAceTheme = () => {
      if (this.props.theme.palette.type === 'dark') {
        return 'ace/theme/dracula';
      } else {
        return 'ace/theme/textmate';
      }
    };

    this.createDiffer = () => {
      const filterValue = (value) => (this.props.filter ? filter(value) : value);
      this.differ = new this.props.imports.AceDiff({
        mode: 'ace/mode/json',
        theme: this.getCurrentAceTheme(),
        left: {
          element: this.leftEditor,
          content: format(filterValue(this.props.value.data)),
          editable: false,
          copyLinkEnabled: false,
        },
        right: {
          element: this.rightEditor,
          content: format(filterValue(this.props.original.data)),
          editable: false,
          copyLinkEnabled: false,
        },
        gutterEl: this.gutter,
      });
      this.differ.editors.left.ace.setOptions({
        showPrintMargin: false,
        fontSize: getFontSize(),
      });
      this.differ.editors.right.ace.setOptions({
        showPrintMargin: false,
        fontSize: getFontSize(),
      });
    };
  }

  componentDidMount() {
    this.createDiffer();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.differ.destroy();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      this.createDiffer();
    }
  }

  componentWillUnmount() {
    this.differ.destroy();
  }

  render() {
    // need a key so that we create new dom elements for each AceDiff
    // TODO: This is pretty stupid way of doing things
    const key = `${this.props.value.key}-${this.props.original.key}-${this.props.filter}-${this.props.theme.palette.type}`;
    return (
      <div key={key} className="json-compare">
        <div
          className="acediff-left-editor"
          ref={(e) => {
            this.leftEditor = e;
          }}
        />
        <div
          className="acediff-gutter"
          ref={(e) => {
            this.gutter = e;
          }}
        />
        <div
          className="acediff-right-editor"
          ref={(e) => {
            this.rightEditor = e;
          }}
        />
      </div>
    );
  }
}

JsonCompare.propTypes = {
  value: PropTypes.shape({
    data: PropTypes.shape({}).isRequired,
    key: PropTypes.number.isRequired,
  }).isRequired,
  original: PropTypes.shape({
    data: PropTypes.shape({}).isRequired,
    key: PropTypes.number.isRequired,
  }).isRequired,
  filter: PropTypes.bool.isRequired,
};

const lazyImports = {
  braceThemeDracula: () => import('brace/theme/dracula'),
  AceDiff: () => import('brace-diff'),
};

export default compose(
  withTheme,
  withDynamicImports(lazyImports),
)(JsonCompare);
