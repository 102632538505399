import each from 'lodash/each';
import isArray from 'lodash/isArray';
import lodashKeys from 'lodash/keys';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { SomeObject } from 'Types/common.types';

export function sortObject(object: { [key: string]: any }) {
  if (object === null) {
    return null;
  }
  // sorts object recursively
  const sortedObj: { [key: string]: any } = {};
  let keys = lodashKeys(object);

  keys = sortBy(keys, (key) => key);

  each(keys, (key) => {
    if (object[key] === null) {
      sortedObj[key] = null;
    } else if (typeof object[key] === 'object' && !(object[key] instanceof Array)) {
      sortedObj[key] = sortObject(object[key]);
    } else {
      sortedObj[key] = object[key];
    }
  });

  return sortedObj;
}

export function sortArray(array?: (SomeObject | SomeObject[])[]) {
  if (!array) {
    return null;
  }
  const sortedOutput = [];

  for (const objectOrArray of array) {
    if (isArray(objectOrArray)) {
      // sort each entry in the list
      sortedOutput.push(map(objectOrArray, (s) => sortObject(s)));
    } else {
      // sort this one object
      sortedOutput.push(sortObject(objectOrArray));
    }
  }
  return sortedOutput;
}

export function sortWithSortable(
  data: any[],
  sortableObj: { sorter: any },
  sortAscending: boolean
) {
  if (!sortableObj || !sortableObj.sorter) {
    return data;
  }

  const sorter = sortableObj.sorter.bind(null, sortableObj);
  const sortedData = [...data].sort(sorter);

  return sortAscending ? sortedData : sortedData.reverse();
}

/**
 * Regular sort function that also handles null and undefined values (i.e '-' in table)
 * @param {number, null} a
 * @param {number, null} b
 */
export const sortWithNullOrUndefinedValues = (a: any, b: any) => {
  if (!isNullOrUndefined(a) && !isNullOrUndefined(b)) {
    if (typeof a === 'number' && typeof b === 'number') {
      return b - a;
    } else if (a instanceof Date && b instanceof Date) {
      if (!isNaN(a.getDate()) && isNaN(b.getDate())) {
        return -1;
      } else if (isNaN(a.getDate()) && !isNaN(b.getDate())) {
        return 1;
      } else if (isNaN(a.getDate()) && isNaN(b.getDate())) {
        return 0;
      }
      return Number(b) - Number(a);
    } else if (typeof a === 'string' && typeof b === 'string') {
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    } else {
      return a < b ? -1 : 1;
    }
  } else if (!isNullOrUndefined(a) && isNullOrUndefined(b)) {
    return -1;
  } else if (isNullOrUndefined(a) && !isNullOrUndefined(b)) {
    return 1;
  } else {
    return -1;
  }
};

export const sortBooleanWithNullOrUndefinedValues = (a: boolean, b: boolean) => {
  if (!isNullOrUndefined(a) && !isNullOrUndefined(b)) {
    if (a === true && b === false) {
      return -1;
    }
    if (a === false && b === true) {
      return 1;
    }
    return 0;
  } else if (!isNullOrUndefined(a) && isNullOrUndefined(b)) {
    return -1;
  } else if (isNullOrUndefined(a) && !isNullOrUndefined(b)) {
    return 1;
  } else {
    return 0;
  }
};

function isNullOrUndefined(val: any) {
  return val === null || val === undefined;
}
