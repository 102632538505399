import { createReducer } from '@reduxjs/toolkit';

import { getSinkSystemId } from 'Internals/pipes';
import { OutboundMap } from 'Types/common.types';
import type { Pipe, PipeResponse } from 'Types/pipes.types';
import { disconnected } from '../thunks/subscriptions';
import {
  pipeAdded,
  pipeUpdated,
  pipeLoaded,
  pipesLoadAllFinished,
  pipeRemoved,
} from '../thunks/pipes';
import { systemRemoved } from '../thunks/systems';

type OutboundsState = OutboundMap;

export const initialState: OutboundsState = {};

function setOutbound(pipe: Pipe | PipeResponse, state: OutboundsState) {
  const sinkSystemId = getSinkSystemId(pipe);
  if (sinkSystemId) {
    if (!state[sinkSystemId]) {
      state[sinkSystemId] = { [pipe._id]: true };
    } else {
      state[sinkSystemId][pipe._id] = true;
    }
  }
}

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(pipeAdded, (state, action) => {
      setOutbound(action.payload, state);
    })
    .addCase(pipeUpdated, (state, action) => {
      setOutbound(action.payload.pipe, state);
    })
    .addCase(pipeLoaded, (state, action) => {
      setOutbound(action.payload.pipe, state);
    })
    .addCase(pipesLoadAllFinished, (state, action) => {
      const newState = {};
      for (let i = 0; i < action.payload.length; i++) {
        setOutbound(action.payload[i], newState);
      }
      return newState;
    })
    .addCase(pipeRemoved, (state, action) => {
      if (action.payload.sinkSystemId && state[action.payload.sinkSystemId]) {
        delete state[action.payload.sinkSystemId][action.payload.id];
      }
    })
    .addCase(systemRemoved, (state, action) => {
      delete state[action.payload];
    })
);

export default reducer;
