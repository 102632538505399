import { apologise } from './apology';
import { getApiConf } from './subscriptions';
import GdprAPI from '../../api/gdpr';
import type { AppThunk } from 'Types/state.types';
import { SomeObject } from 'Types/common.types';
import { createAction } from '@reduxjs/toolkit';

export const datatypeAdded = createAction<SomeObject>('gdpr/datatypeAdded');
export const datatypeDeleted = createAction<string>('gdpr/datatypeDeleted');
export const datatypeUpdated = createAction<SomeObject>('gdpr/datatypeUpdated');
export const datatypeUpdatedOrAdded = createAction<SomeObject>('gdpr/datatypeUpdatedOrAdded');
export const datatypesUpdated = createAction<SomeObject[]>('gdpr/datatypesUpdated');
export const datatypesSetSince = createAction<number>('gdpr/datatypesSetSince');
export const datatypesLoaded = createAction<SomeObject[]>('gdpr/datatypesLoaded');

export const policyAdded = createAction<SomeObject>('gdpr/policyAdded');
export const policyDeleted = createAction<string>('gdpr/policyDeleted');
export const policyUpdatedOrAdded = createAction<SomeObject>('gdpr/policyUpdatedOrAdded');
export const policyUpdated = createAction<SomeObject>('gdpr/policyUpdated');
export const policiesSetSince = createAction<number>('gdpr/policiesSetSince');
export const policiesLoaded = createAction<SomeObject[]>('gdpr/policiesLoaded');

export const purposeAdded = createAction<SomeObject>('gdpr/purposeAdded');
export const purposeDeleted = createAction<string>('gdpr/purposeDeleted');
export const purposeUpdatedOrAdded = createAction<SomeObject>('gdpr/purposeUpdatedOrAdded');
export const purposeUpdated = createAction<SomeObject>('gdpr/purposeUpdated');
export const purposesSetSince = createAction<number>('gdpr/purposesSetSince');
export const purposesLoaded = createAction<SomeObject[]>('gdpr/purposesLoaded');

function addDatatype(datatype: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.addDatatype(getApiConf(getState), datatype)
      .then(() => dispatch(datatypeAdded(datatype)))
      .catch((error) => dispatch(apologise(error)));
  };
}

function addPolicy(policy: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.addPolicy(getApiConf(getState), policy)
      .then(() => dispatch(policyAdded(policy)))
      .catch((error) => dispatch(apologise(error)));
  };
}

function addPurpose(purpose: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.addPurpose(getApiConf(getState), purpose)
      .then(() => dispatch(purposeAdded(purpose)))
      .catch((error) => dispatch(apologise(error)));
  };
}

function deleteDatatype(datatype: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.deleteDatatype(getApiConf(getState), datatype)
      .then(() => dispatch(datatypeDeleted(datatype['data-type-id'])))
      .catch((error) => dispatch(apologise(error)));
  };
}

function deletePolicy(policy: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.deletePolicy(getApiConf(getState), policy)
      .then(() => dispatch(policyDeleted(policy['policy-id'])))
      .catch((error) => dispatch(apologise(error)));
  };
}

function deletePurpose(purpose: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.deletePurpose(getApiConf(getState), purpose)
      .then(() => dispatch(purposeDeleted(purpose['purpose-id'])))
      .catch((error) => dispatch(apologise(error)));
  };
}

function loadDatatypes(): AppThunk {
  return function (dispatch, getState) {
    const since = getState().gdpr.datatypes.since;
    const apiConf = getApiConf(getState);

    if (since === null) {
      return GdprAPI.getDatatypes(apiConf)
        .then((datatypes) => {
          const filteredDatatypes = datatypes.filter((p) => !p['_deleted']);
          dispatch(datatypesLoaded(filteredDatatypes));

          if (filteredDatatypes.length > 0) {
            const lastSince = filteredDatatypes[filteredDatatypes.length - 1]['_updated'];
            dispatch(datatypesSetSince(lastSince));
          }
        })
        .catch((error) => dispatch(apologise(error)));
    } else {
      return GdprAPI.getDatatypes(apiConf, since).then((datatypes) => {
        if (datatypes.length > 0) {
          datatypes.forEach((datatype) => {
            if (datatype['_deleted']) {
              dispatch(datatypeDeleted(datatype['data-type-id']));
              dispatch(datatypesSetSince(datatype['_updated']));
            } else {
              dispatch(datatypeUpdatedOrAdded(datatype));
              dispatch(datatypesSetSince(datatype['_updated']));
            }
          });
        }
      });
    }
  };
}

function loadPolicies(): AppThunk {
  return function (dispatch, getState) {
    const since = getState().gdpr.policies.since;
    const apiConf = getApiConf(getState);

    if (since === null) {
      return GdprAPI.getPolicies(apiConf)
        .then((policies) => {
          const filteredPolicies = policies.filter((p) => !p['_deleted']);
          dispatch(policiesLoaded(filteredPolicies));

          if (filteredPolicies.length > 0) {
            const lastSince = filteredPolicies[filteredPolicies.length - 1]['_updated'];
            dispatch(policiesSetSince(lastSince));
          }
        })
        .catch((error) => dispatch(apologise(error)));
    } else {
      return GdprAPI.getPolicies(apiConf, since).then((policies) => {
        if (policies.length > 0) {
          policies.forEach((policy) => {
            if (policy['_deleted']) {
              dispatch(policyDeleted(policy['policy-id']));
              dispatch(policiesSetSince(policy['_updated']));
            } else {
              dispatch(policyUpdatedOrAdded(policy));
              dispatch(policiesSetSince(policy['_updated']));
            }
          });
        }
      });
    }
  };
}

function loadPurposes(): AppThunk {
  return function (dispatch, getState) {
    const since = getState().gdpr.purposes.since;
    const apiConf = getApiConf(getState);
    if (since === null) {
      return GdprAPI.getPurposes(apiConf)
        .then((purposes) => {
          const filteredPurposes = purposes.filter((p) => !p['_deleted']);
          dispatch(purposesLoaded(filteredPurposes));

          if (filteredPurposes.length > 0) {
            const lastSince = filteredPurposes[filteredPurposes.length - 1]['_updated'];
            dispatch(purposesSetSince(lastSince));
          }
        })
        .catch((error) => dispatch(apologise(error)));
    } else {
      return GdprAPI.getPurposes(apiConf, since).then((purposes) => {
        if (purposes.length > 0) {
          purposes.forEach((purpose) => {
            if (purpose['_deleted']) {
              dispatch(purposeDeleted(purpose['purpose-id']));
              dispatch(purposesSetSince(purpose['_updated']));
            } else {
              dispatch(purposeUpdatedOrAdded(purpose));
              dispatch(purposesSetSince(purpose['_updated']));
            }
          });
        }
      });
    }
  };
}

function removePurposeFromDatatypes(deletedPurposeId: string): AppThunk {
  return function (dispatch, getState) {
    const existingDatatypes = getState().gdpr.datatypes.data;
    const apiConf = getApiConf(getState);
    const promises = existingDatatypes.map((datatype: SomeObject) => {
      if (datatype['purpose-id'] && datatype['purpose-id'].includes(deletedPurposeId)) {
        datatype['purpose-id'] = datatype['purpose-id'].filter((purposeId: string) => {
          return purposeId !== deletedPurposeId;
        });

        return GdprAPI.updateDatatype(apiConf, datatype).then(() => datatype);
      }
    });

    return Promise.all(promises.filter((promise: Promise<SomeObject>) => promise))
      .then((datatypes) => dispatch(datatypesUpdated(datatypes)))
      .catch((error) => dispatch(apologise(error)));
  };
}

function updateDatatype(datatype: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.updateDatatype(getApiConf(getState), datatype)
      .then(() => {
        dispatch(datatypeUpdated(datatype));
      })
      .catch((error) => dispatch(apologise(error)));
  };
}

function updatePolicy(policy: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.updatePolicy(getApiConf(getState), policy)
      .then(() => dispatch(policyUpdated(policy)))
      .catch((error) => dispatch(apologise(error)));
  };
}

function updatePurpose(purpose: SomeObject): AppThunk {
  return function (dispatch, getState) {
    return GdprAPI.updatePurpose(getApiConf(getState), purpose)
      .then(() => dispatch(purposeUpdated(purpose)))
      .catch((error) => dispatch(apologise(error)));
  };
}

export default {
  addDatatype,
  addPolicy,
  addPurpose,
  deletePurpose,
  deleteDatatype,
  deletePolicy,
  loadDatatypes,
  loadPolicies,
  loadPurposes,
  removePurposeFromDatatypes,
  updateDatatype,
  updatePolicy,
  updatePurpose,
};
