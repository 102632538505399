import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import * as yup from 'yup';

import { datasetsSelector } from 'Redux/thunks/datasets';

import DatasetActions from 'Redux/thunks/datasets';

import SesamWizard from 'Common/SesamWizard/SesamWizard';
import HopsWizardStep1 from './steps/HopsWizardStep1';
import HopsWizardStep2 from './steps/HopsWizardStep2';
import HopsWizardSummary from './steps/HopsWizardSummary';

const initialValues = {
  dataset: '',
  sourceProp: '',
  targetProp: '',
  hop: [],
};

const HopsWizard = (props) => {
  const { datasets, loadAllDatasets, onClose, onFinish, sourceId } = props;

  const datasetIds = useMemo(() => datasets.map((ds) => ds['_id']), [datasets]);

  const validationSchema = yup.object().shape({
    dataset: yup.mixed().oneOf(datasetIds, 'This is not a valid dataset'),
    sourceProp: yup.string().required('Please select a source property'),
    targetProp: yup.string().required('Please select a target property'),
    hop: yup.string().required(),
  });

  useEffect(() => {
    loadAllDatasets();
  }, [loadAllDatasets]);

  return (
    <SesamWizard
      title="Add data"
      open={true}
      steps={[
        {
          label: 'Select dataset',
          render: HopsWizardStep1,
          isError: (errors) => Object.keys(errors).includes('dataset'),
          isCompleted: (state) => {
            return state.dataset !== '';
          },
        },
        {
          label: 'Match properties',
          render: HopsWizardStep2,
          isError: (errors) =>
            Object.keys(errors).includes('sourceProp') ||
            Object.keys(errors).includes('targetProp'),
        },
        {
          label: 'Summary',
          render: HopsWizardSummary,
        },
      ]}
      bag={{ datasetIds, sourceId }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onClose={onClose}
      onFinish={(state) => onFinish(state.hop)}
    />
  );
};

HopsWizard.defaultProps = {
  datasets: [],
  sourceId: '',
};

HopsWizard.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.shape({})),
  loadAllDatasets: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  sourceId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  datasets: datasetsSelector(state).filter((dataset) => {
    return dataset.origin !== 'system';
  }),
});

const mapDispatchToProps = (dispatch) => ({
  loadAllDatasets: () => dispatch(DatasetActions.loadAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HopsWizard);
