import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import PerfectScrollbar from 'react-perfect-scrollbar';
import TableColSelector from '../table-col-selector/table-col-selector';
import FacetList from '../facet-list/FacetList';

import './style.css';
import { ColumnInstance } from 'react-table';
import { SomeObject } from 'Types/common.types';

type TableListHeaderProps = {
  cols: ColumnInstance<any>[];
  resetHiddenColumns: () => void;
  filter: SomeObject;
  filterLabels: SomeObject;
  itemsToFilter: SomeObject[];
  onClearSearchFilters: (id: string) => void;
  onClearSelectedFilters: (id: string) => void;
  onFacetSearchChange: (id: string, value: string) => void;
  onFacetSelectedChange: (id: string, value: string) => void;
  onRemoveSearch: (id: string, value: string) => void;
  onResetFilters: () => void;
  onSavedFilterClick: (filterQueryString: string) => void;
  onSavedFilterDeleteClick: (key: string) => void;
  onSaveFilter: (name: string) => void;
  onToggleFacetVisibility: (facetId: string) => void;
  savedFilters: SomeObject;
  visibleFacets: string[];
  curl: string;
};

const TableListHeader: React.FC<TableListHeaderProps> = (props) => {
  return (
    <PerfectScrollbar className="table-list-header">
      <FacetList
        filter={props.filter}
        filterLabels={props.filterLabels}
        itemsToFilter={props.itemsToFilter}
        onClearSearchFilters={props.onClearSearchFilters}
        onClearSelectedFilters={props.onClearSelectedFilters}
        onFacetSearchChange={props.onFacetSearchChange}
        onFacetSelectedChange={props.onFacetSelectedChange}
        onRemoveSearch={props.onRemoveSearch}
        onResetFilters={props.onResetFilters}
        onSavedFilterClick={props.onSavedFilterClick}
        onSavedFilterDeleteClick={props.onSavedFilterDeleteClick}
        onSaveFilter={props.onSaveFilter}
        onToggleFacetVisibility={props.onToggleFacetVisibility}
        savedFilters={props.savedFilters}
        visibleFacets={props.visibleFacets}
        curl={props.curl}
      />
      <TableColSelector cols={props.cols} resetHiddenColumns={props.resetHiddenColumns} />
    </PerfectScrollbar>
  );
};

export default TableListHeader;
