import { createReducer } from '@reduxjs/toolkit';

import { getSourceSystemId } from 'Internals/pipes';
import { disconnected } from '../thunks/subscriptions';
import {
  pipeAdded,
  pipeUpdated,
  pipeLoaded,
  pipesLoadAllFinished,
  pipeRemoved,
} from '../thunks/pipes';
import { systemRemoved } from '../thunks/systems';
import { InboundMap } from 'Types/common.types';
import type { PipeResponse } from 'Types/pipes.types';

type InboundsState = InboundMap;

export const initialState: InboundsState = {};

function setInbound(pipe: PipeResponse, state: InboundsState) {
  const sourceSystemId = getSourceSystemId(pipe);
  if (sourceSystemId) {
    if (!state[sourceSystemId]) {
      state[sourceSystemId] = { [pipe._id]: true };
    } else {
      state[sourceSystemId][pipe._id] = true;
    }
  }
}

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(pipeAdded, (state, action) => {
      setInbound(action.payload, state);
    })
    .addCase(pipeUpdated, (state, action) => {
      setInbound(action.payload.pipe, state);
    })
    .addCase(pipeLoaded, (state, action) => {
      setInbound(action.payload.pipe, state);
    })
    .addCase(pipesLoadAllFinished, (state, action) => {
      const newState = {};
      for (let i = 0; i < action.payload.length; i++) {
        setInbound(action.payload[i], newState);
      }
      return newState;
    })
    .addCase(pipeRemoved, (state, action) => {
      if (action.payload.sourceSystemId && state[action.payload.sourceSystemId]) {
        delete state[action.payload.sourceSystemId][action.payload.id];
      }
    })
    .addCase(systemRemoved, (state, action) => {
      delete state[action.payload];
    })
);

export default reducer;
