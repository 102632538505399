import React, { useEffect, useState } from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import EntitiesAPI from '../../api/entities';
import JsonPanel from 'Common/JsonPanel';
import PipesAPI from '../../api/pipes';
import SesamIconButtonPopover from 'Common/SesamIconButtonPopover/SesamIconButtonPopover';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import usePipeEntitiesIndependent from '../../hooks/usePipeEntitiesIndependent';
import { apiConfSelector } from 'Redux/selectors';
import { apologise } from 'Redux/thunks/apology';
import type { DatasetID } from 'Types/dataset.types';
import type { PipeConfig } from 'Types/pipes.types';
import { SomeObject } from 'Types/common.types';

interface MergeSourceNavigatorProps {
  onEntityFetched: (entity: SomeObject) => void;
  pipeConfig: PipeConfig;
  pipeId: string;
  sourceDatasetIds: DatasetID[];
  sinkDatasetId: string;
}

const useStyle = makeStyles((theme) => {
  return {
    button: {
      marginLeft: '7.5px',
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonGroup: {
      height: '24px',
      marginLeft: '7.5px',
      marginRight: '7.5px',
      '& .MuiButtonGroup-grouped': {
        minWidth: '34px',
      },
      '& .MuiButton-label': {
        height: '22px',
      },
      '& .MuiButton-outlinedSizeSmall': {
        padding: '0px 7px',
      },
      '& svg': {
        height: '16px',
        width: '16px',
      },
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
    },
    explanationPanel: {
      maxHeight: '600px',
      minWidth: '600px',
      overflow: 'auto',
    },
    form: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
    },
    infoButton: {
      marginLeft: '7.5px',
    },
    infoIcon: {
      color: theme.palette.info.main,
    },
  };
});

const MergeSourceNavigator: React.FC<MergeSourceNavigatorProps> = (props) => {
  const { onEntityFetched, pipeConfig, pipeId, sourceDatasetIds, sinkDatasetId } = props;

  const classes = useStyle();

  const dispatch = useDispatch();

  const apiConf = useSelector(apiConfSelector);

  const { entities, selectNextEntity, selectPreviousEntity, selectedEntity, selectedEntityIndex } =
    usePipeEntitiesIndependent({
      pipeId,
      pipeConfig,
      limit: 50,
      sinkDatasetId,
      stage: 'source',
      subUrl: apiConf.subUrl,
      token: apiConf.token,
    });

  useEffect(() => {
    if (selectedEntity !== undefined) {
      onEntityFetched(selectedEntity);
    }
  }, [onEntityFetched, selectedEntity]);

  const [searchResultsLen, setSearchResultsLen] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showingSearchResults, setShowingSearchResults] = useState<boolean>(false);

  const search = async () => {
    if (searchValue.trim() === '') {
      return;
    }

    try {
      // plan A
      const explainedResults = await PipesAPI.explainMerge(apiConf, searchValue, pipeId).then(
        (result) => (Array.isArray(result) && result.length > 0 ? result[0] : null)
      );
      setShowingSearchResults(true);
      if (explainedResults) {
        setSearchResultsLen(explainedResults.length);
        setExplainedMerge(explainedResults);
        const mergedEntity = explainedResults.merged_entity;
        if (mergedEntity) {
          onEntityFetched(mergedEntity);
        }
      } else {
        // fallback-plan B
        const promiseArray = sourceDatasetIds.map((datasetId: string) => {
          return EntitiesAPI.lookupEntity({ ...apiConf, dataset: datasetId }, searchValue)
            .then((result) => ({
              datasetId,
              result: Array.isArray(result) && result.length > 0 ? result[0] : null,
            }))
            .catch(() => null);
        });

        const fallbackResults = await Promise.all(promiseArray);
        const positiveResults = fallbackResults.filter(
          (result) => result !== null && result.result !== null
        );
        setSearchResultsLen(positiveResults.length);
        if (positiveResults && positiveResults.length > 0) {
          onEntityFetched(positiveResults[0].result);
        }
      }
    } catch (e) {
      dispatch(apologise(e));
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResultsLen(0);
    setExplainedMerge({});
    setShowingSearchResults(false);

    onEntityFetched(selectedEntity);
  };

  const [explainedMerge, setExplainedMerge] = useState<SomeObject>({});

  const noEntitiesFoundTooltip = (
    <Tooltip title="No entities with this id was found">
      <ErrorOutline color="error" />
    </Tooltip>
  );

  const oneEntityFoundTooltip = (
    <Tooltip title="The id was found in one entity">
      <InfoOutlined className={classes.infoIcon} />
    </Tooltip>
  );

  const multipleEntitiesFoundTooltip = (
    <Tooltip title="The id was found in multiple entities">
      <InfoOutlined className={classes.infoIcon} />
    </Tooltip>
  );

  let searchFieldAdornment;
  if (showingSearchResults && searchResultsLen === 0) {
    searchFieldAdornment = (
      <InputAdornment position="start">{noEntitiesFoundTooltip}</InputAdornment>
    );
  } else if (showingSearchResults && searchResultsLen === 1) {
    searchFieldAdornment = (
      <InputAdornment position="start">{oneEntityFoundTooltip}</InputAdornment>
    );
  } else if (showingSearchResults && searchResultsLen > 1) {
    searchFieldAdornment = (
      <InputAdornment position="start">{multipleEntitiesFoundTooltip}</InputAdornment>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.navigation}>
        <ButtonGroup className={classes.buttonGroup} size="small">
          <Button
            disabled={showingSearchResults || entities.length < 2 || selectedEntityIndex === 0}
            onClick={selectPreviousEntity}
          >
            <ArrowBack />
          </Button>
          <Button
            disabled={
              showingSearchResults ||
              entities.length < 2 ||
              selectedEntityIndex === entities.length - 1
            }
            onClick={selectNextEntity}
          >
            <ArrowForward />
          </Button>
        </ButtonGroup>
      </div>
      <form
        className={classes.form}
        onSubmit={(ev: React.ChangeEvent) => {
          ev.preventDefault();
          search();
        }}
      >
        <SesamTextField
          error={showingSearchResults && searchResultsLen === 0}
          inputProps={{ autoFocus: true }}
          InputProps={{
            className: clsx([showingSearchResults && 'Mui-focused']),
            startAdornment: searchFieldAdornment,
          }}
          onChange={(ev: React.ChangeEvent) => setSearchValue(ev.target.value)}
          placeholder="Returns exact match on ID"
          value={searchValue}
        />

        <IconButton className={classes.button} size="small" type="submit">
          <Search />
        </IconButton>
        <IconButton
          className={classes.button}
          onClick={() => clearSearch()}
          size="small"
          disabled={!showingSearchResults}
        >
          <Tooltip title="Clear search">
            <Close />
          </Tooltip>
        </IconButton>
      </form>
      <SesamIconButtonPopover
        className={classes.infoButton}
        IconButtonProps={{
          disabled: Object.keys(explainedMerge).length === 0,
          size: 'small',
        }}
        icon={<InfoOutlined />}
        render={(toggle) => (
          <div className={classes.explanationPanel}>
            <JsonPanel rawJson={explainedMerge} fitToContent />
          </div>
        )}
        title="Merge info"
      />
    </div>
  );
};

export default MergeSourceNavigator;
