import { createReducer } from '@reduxjs/toolkit';
import {
  subLoaded,
  subCreated,
  memberUpdated,
  memberRemoved,
  subUpdated,
  subRemoved,
  allSubsLoaded,
} from '../thunks/subscriptions';
import { SomeObject } from 'Types/common.types';
import { mutableDelete, mutableUpdate } from 'Internals/utils';

export type SubscriptionsState = SomeObject[];

const initialState: SubscriptionsState = [];
export default createReducer(initialState, (builder) =>
  builder
    .addCase(allSubsLoaded, (_state, action) => {
      return action.payload;
    })
    .addCase(subLoaded, (state, action) => {
      mutableUpdate(state, action.payload, (s) => s.id === action.payload.id);
    })
    .addCase(subCreated, (state, action) => {
      state.push(action.payload);
    })
    .addCase(memberUpdated, (state, action) => {
      if (action.payload.isCurrentUser) {
        // This is always false, so I'm not sure what it's doing here
        // if (!action.payload.member.roles === []) {
        //   return state.filter((sub) => sub.id !== action.payload.subId);
        // }
        const index = state.findIndex((s) => s.id === action.payload.subId);
        if (index !== -1) {
          state[index]['current-users-member'] = action.payload.member;
        }
      }
    })
    .addCase(memberRemoved, (state, action) => {
      if (action.payload.isCurrentUser) {
        mutableDelete(state, (s) => s.id === action.payload.subId);
      }
    })
    .addCase(subUpdated, (state, action) => {
      mutableUpdate(state, action.payload, (s) => s.id === action.payload.id);
    })
    .addCase(subRemoved, (state, action) => {
      mutableDelete(state, (s) => s.id === action.payload);
    })
);
