import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SesamLink from 'Common/Links/SesamLink';
import { makeStyles } from '@material-ui/core/styles';

import { SearchResult as SearchResultType } from 'Types/common.types';

interface SearchResultProps {
  className?: string;
  onClickResult: (id: string) => void;
  result: SearchResultType;
  selectedEntityInfo: SearchResultType;
  setSelectedEntityInfo: (entityInfo: SearchResultType) => void;
  subId: string;
}

const useStyle = makeStyles({
  idList: {
    listStyle: 'none',
    paddingLeft: 0,
    marginTop: '7px',
  },
  idListItem: {
    marginBottom: '7px',
  },
});

const SearchResult: React.FC<SearchResultProps> = ({
  result,
  selectedEntityInfo,
  setSelectedEntityInfo,
  subId,
}) => {
  const classes = useStyle();

  const isSelected =
    result.dataset === selectedEntityInfo.dataset && result.id === selectedEntityInfo.id;

  return (
    <ListItem
      divider
      button
      onClick={() => setSelectedEntityInfo({ id: result.id, dataset: result.dataset })}
      selected={isSelected}
    >
      <div>
        <ul className={classes.idList}>
          {result?.ids?.sort().map((id: string) => {
            const idUrl = encodeURIComponent(id);

            return (
              <li key={id} className={classes.idListItem}>
                <SesamLink to={`/subscription/${subId}/browse/ni/${idUrl}`}>{id}</SesamLink>
              </li>
            );
          })}
        </ul>
        <ListItemText secondary={result.dataset} />
      </div>
    </ListItem>
  );
};

export default SearchResult;
