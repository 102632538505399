import { useEffect, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import type { RootState } from 'Types/state.types';
import StripeAPI from 'Api/stripe';

/*
  Hook for loading the stripe promise to be used in the Elements components.
  Docs: https://stripe.com/docs/stripe-js/react#elements-provider
  This works because we can initially pass null as the stripePromise (once)
*/
export const useStripePromise = () => {
  const portalUrl = useSelector((state: RootState) => state.globals.portalUrl);
  const [stripeKey, setStripeKey] = useState<string>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  useEffect(() => {
    import('@stripe/stripe-js').then((stripe) => {
      const { loadStripe } = stripe;

      StripeAPI.loadKey(portalUrl).then((key) => {
        setStripeKey(key);
      });

      if (stripeKey && !stripePromise) {
        setStripePromise(loadStripe(stripeKey));
      }
    });
  }, [stripeKey, stripePromise]);

  return stripePromise;
};
