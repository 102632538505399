import { sfetchJson, sfetchText } from 'Internals/sfetch';

async function getVersion() {
  return await sfetchJson(`${window.location.origin}/build.json`, undefined, 'version');
}

async function getStatus(portalUrl: string) {
  return await sfetchJson(`${portalUrl}`);
}

async function getStacktrace(portalUrl: string, token: string) {
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchText(`${portalUrl}/status/stacktrace`, options);
}

export default {
  getVersion,
  getStatus,
  getStacktrace,
};
