import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const generateRolesMarkup = (roles) =>
  roles.reduce((acc, c) => {
    if (!c['is-internal-role']) {
      acc.push({
        value: c.id,
        display: c.name,
      });
    }
    return acc;
  }, []);

const InnerForm = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
}) => (
  <div className="material-form">
    <Form onSubmit={handleSubmit}>
      <SesamTextField
        margin="normal"
        id="email"
        type="email"
        InputProps={{
          inputProps: { 'data-selenium': 'invite-user-email-input' },
        }}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Email"
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <SesamTextField
        select
        margin="normal"
        data-selenium="invite-user-role-input"
        id="role"
        name="role"
        value={values.role}
        onChange={handleChange}
        onBlur={handleBlur}
        options={generateRolesMarkup(values.props.availableRoles)}
        error={touched.role && Boolean(errors.role)}
        helperText={touched.role && errors.role}
        label="Role"
      >
        {generateRolesMarkup(values.props.availableRoles).map((c) => (
          <SesamMenuItem key={c.value} value={c.value} data-selenium={`role-select-${c.display}`}>
            {c.display}
          </SesamMenuItem>
        ))}
      </SesamTextField>
      <FormActions>
        <Button
          data-selenium="invite-user-submit-button"
          disabled={isSubmitting || !isValid}
          type="submit"
          color="primary"
          text={isSubmitting ? 'Inviting...' : 'Invite'}
        />
      </FormActions>
    </Form>
  </div>
);

InnerForm.propTypes = {
  values: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const InviteForm = withFormik({
  mapPropsToValues: (props) => {
    return {
      email: '',
      role: '',
      props,
    };
  },
  validationSchema: yup.object().shape({
    email: yup.string().required('Please enter your email').email('Please enter a valid email'),
    role: yup
      .string()
      .required('Please select a role')
      .notOneOf(['selected'], 'Please select a role'),
  }),
  handleSubmit: (values, { props, resetForm }) => {
    const email = values.email;
    const subId = props.subId;
    const role = values.role;

    props
      .onInvite(subId, email, role)
      .then(() => resetForm())
      .catch(() => resetForm());
  },
})(InnerForm);

InviteForm.propTypes = {
  availableRoles: PropTypes.arrayOf(PropTypes.shape({})),
  onInvite: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
};

export default InviteForm;
