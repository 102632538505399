import { apologise } from './apology';
import SecretsAPI from '../../api/secrets';
import { getApiConf } from './subscriptions';
import { createAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'Types/state.types';
import { toastAdded } from './global';

// TODO: Use a safe URL builder
// TODO: Review the need of "CORS" mode

export const secretAdded = createAction<{ name: string; value: string }>('secrets/added');
export const secretRemoved = createAction<string>('secrets/removed');

export const secretLoadedAll = createAction<string[]>('secrets/loadedAll');

export default {
  secretUpdate(name: string, value: string): AppThunk {
    return function (dispatch, getState) {
      if (getState().secrets.indexOf(name) !== -1) {
        return SecretsAPI.post(getApiConf(getState), name, value)
          .then(() =>
            dispatch(
              toastAdded({
                type: 'success',
                message: `Secret \"${name}\" successfully updated`,
              })
            )
          )
          .catch((error) => dispatch(apologise(error)));
      } else {
        dispatch(apologise('Cannot update a non-existing secret.'));
      }
    };
  },
  secretAdd(name: string, value: string): AppThunk {
    return function (dispatch, getState) {
      if (getState().secrets.indexOf(name) !== -1) {
        dispatch(apologise(`"${name}" is already defined`));
        return Promise.reject();
      }

      return SecretsAPI.post(getApiConf(getState), name, value)
        .then(() => dispatch(secretAdded({ name, value })))
        .then(() =>
          dispatch(
            toastAdded({
              type: 'success',
              message: `Secret \"${name}\" successfully added`,
            })
          )
        )
        .catch((error) => dispatch(apologise(error)));
    };
  },

  secretDelete(name: string): AppThunk {
    return function (dispatch, getState) {
      return SecretsAPI.remove(getApiConf(getState), name)
        .then(() => dispatch(secretRemoved(name)))
        .then(() =>
          dispatch(
            toastAdded({
              type: 'success',
              message: `Secret \"${name}\" deleted`,
            })
          )
        )
        .catch((error) => dispatch(apologise(error)));
    };
  },

  secretLoadAll(): AppThunk {
    return function (dispatch, getState) {
      return SecretsAPI.getAll(getApiConf(getState))
        .then((secrets) => dispatch(secretLoadedAll(secrets)))
        .catch((error) => dispatch(apologise(error)));
    };
  },
};
