import { createReducer } from '@reduxjs/toolkit';

import { getSinkDatasetId } from 'Internals/pipes';
import { Optional } from 'Types/common.types';
import type { UpstreamMap, PipeResponse } from 'Types/pipes.types';
import { disconnected } from '../thunks/subscriptions';
import {
  pipeAdded,
  pipeUpdated,
  pipeLoaded,
  pipesLoadAllFinished,
  pipeRemoved,
} from '../thunks/pipes';

type UpstreamsState = UpstreamMap;

export const initialState: UpstreamsState = {};

function _setUpstream(datasetID: Optional<string>, pipe: PipeResponse, state: UpstreamsState) {
  if (datasetID) {
    state[datasetID] = pipe._id;
  }
}

function _clearUpstream(datasetID: Optional<string>, state: UpstreamsState) {
  // Beware, modifies in place
  if (datasetID && state[datasetID]) {
    delete state[datasetID];
  }
}

function setUpstream(pipe: PipeResponse, state: UpstreamsState) {
  // Beware, modifies in place
  const datasetID = getSinkDatasetId(pipe);
  _setUpstream(datasetID, pipe, state);
}

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(pipeAdded, (state, action) => {
      setUpstream(action.payload, state);
    })
    .addCase(pipeUpdated, (state, action) => {
      const newSinkDatasetId = getSinkDatasetId(action.payload.pipe);
      const oldSinkDatasetId = getSinkDatasetId(action.payload.oldPipe);
      if (newSinkDatasetId !== oldSinkDatasetId) {
        _setUpstream(newSinkDatasetId, action.payload.pipe, state);
        _clearUpstream(oldSinkDatasetId, state);
      }
    })
    .addCase(pipeLoaded, (state, action) => {
      setUpstream(action.payload.pipe, state);
    })
    .addCase(pipesLoadAllFinished, (state, action) => {
      const newState = {};
      for (let i = 0; i < action.payload.length; i++) {
        setUpstream(action.payload[i], newState);
      }
      return newState;
    })
    .addCase(pipeRemoved, (state, action) => {
      if (action.payload.sinkDatasetId) {
        delete state[action.payload.sinkDatasetId];
      }
    })
);

export default reducer;
