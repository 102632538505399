export const Links = {
  BuildingBlocksDatasetsDocumentation:
    'https://docs.sesam.io/hub/documentation/building-blocks/datasets.html',

  BuildingBlocksPipesDocumentation:
    'https://docs.sesam.io/hub/documentation/building-blocks/pipes.html',

  BuildingBlocksSystemsDocumentation:
    'https://docs.sesam.io/hub/documentation/building-blocks/systems.html',

  TermsOfServicePDF:
    'https://docs.sesam.io/_downloads/2145e3633a91bd9e637d934af5616453/sesam-cloud-service-contract.pdf',

  TermsOfService: 'https://docs.sesam.io/terms.html',

  PrivacyPolicy: 'https://docs.sesam.io/privacy-policy.html',

  IntegratedSearchDocumentation: 'https://docs.sesam.io/hub/features/integrated-search.html',

  HopsDTLReferenceGuideDocumentation: 'https://docs.sesam.io/hub/dtl/dtl-functions-hops.html',

  SchemaInferencingDocumentation: 'https://docs.sesam.io/hub/features/schema-inference.html',

  NetWorkPolicyDocumentation: 'https://docs.sesam.io/hub/features/network-policy.html',

  PumpUpdateLastSeenDocumentation:
    'https://docs.sesam.io/api.html#api-reference-pump-update-last-seen',

  QuickReferenceDocumentation: 'https://docs.sesam.io/quick-reference.html',

  SesamSupport: 'https://support.sesam.io/hc',

  SesamDocumentations: 'https://docs.sesam.io',

  MailToInfoAtSesamDotIO: 'mailto:info@sesam.io',

  FallbackPortalUrl: 'https://portal.sesam.in',

  SesamPricing: 'https://docs.sesam.io/pricing.html',

  MailToSupportAtSesamDotIO: 'mailto:support@sesam.io',

  PortalDomainNameDocumentation:
    'https://docs.sesam.io/hub/gdpr-platform-configuration.html#gdpr-access-portal-domain-name',

  GdprDataTypesPurposeConfiguration:
    'https://docs.sesam.io/hub/gdpr-data-types-purposes-configuration.html',

  GdprPlatformConfiguration:
    'https://docs.sesam.io/hub/gdpr-platform-configuration.html#setup-file-url',

  GdprPlatformConfigurationDefaultLanguage:
    'https://docs.sesam.io/hub/gdpr-platform-configuration.html#default-language-settings',

  HttpWikipedia: 'https://en.wikipedia.org/wiki/Hypertext_Transfer_Protocol#HTTP_session_state',

  SesamChangeLog: 'https://docs.sesam.io/changelog.html',

  
};
