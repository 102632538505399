import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import { getSourceDatasetIds } from 'Internals/pipes';
import { getUpstreamPipeFromDatasetId } from 'Internals/pipes';
import PipeTypesAPI from '../../../../../api/pipe-types';

import HopsWizardFilteredList from './HopsWizardFilteredList';
import { getEntities } from '../../../../../api/entities';
import type { PipeMap, Pipe, UpstreamMap } from 'Types/pipes.types';
import type { RootState } from 'Types/state.types';

const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flex: 1,
    },
    description: {
      flex: 1,
      paddingRight: '10px',
    },
    action: {
      flex: 2,
      display: 'flex',
      overflow: 'hidden',
      paddingLeft: '10px',
    },
    sourceProp: {
      marginRight: '10px',
      display: 'flex',
      flex: 1,
    },
    targetProp: {
      marginLeft: '10px',
      display: 'flex',
      flex: 1,
    },
    blueText: {
      color: theme.palette.primary.main,
    },
  };
});

async function getPipeEntityTypes(
  pipeId: string,
  subUrl: string,
  token: string
): Promise<string[]> {
  try {
    const entityTypes = await PipeTypesAPI.getSourceEntityType({ subUrl, token }, pipeId);
    return Object.keys(entityTypes.properties);
  } catch (e) {
    return [];
  }
}

async function getPropsFromDataset(
  datasetId: string,
  subUrl: string,
  token: string
): Promise<string[]> {
  const entities = await getEntities({ subUrl, token }, { datasetId, limit: 50 });
  if (entities && entities.length > 0) {
    return entities.reduce((acc: string[], entity) => {
      Object.keys(entity).forEach((key) => {
        if (!acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []);
  } else {
    return [];
  }
}

type Step2Props = {
  bag: {
    sourceId: string;
  };
  pipe: Pipe;
  pipes: PipeMap;
  setValue: Function;
  state: {
    dataset: string;
    sourceProp: string;
    targetProp: string;
  };
  subUrl: string;
  token: string;
  upstreams: UpstreamMap;
};

const HopsWizardStep2 = (props: Step2Props) => {
  const classes = useStyle();

  const { bag, pipe, pipes, setValue, state, subUrl, token, upstreams } = props;
  const { sourceId } = bag;
  const { dataset, sourceProp, targetProp } = state;

  const [sourceProps, setSourceProps] = useState<string[]>([]);
  const [sourcePropsLoading, setSourcePropsLoading] = useState(true);
  const [targetProps, setTargetProps] = useState<string[]>([]);
  const [targetPropsLoading, setTargetPropsLoading] = useState(true);

  useEffect(() => {
    if (dataset) {
      const upstreamPipe = getUpstreamPipeFromDatasetId(dataset, pipes, upstreams);

      setTargetPropsLoading(true);
      if (upstreamPipe) {
        getPipeEntityTypes(upstreamPipe['_id'], subUrl, token).then((entityTypes) => {
          if (entityTypes.length > 0) {
            setTargetProps(entityTypes.sort());
            setTargetPropsLoading(false);
          } else {
            getPropsFromDataset(dataset, subUrl, token).then((props) => {
              setTargetProps(props);
              setTargetPropsLoading(false);
            });
          }
        });
      }
    }
  }, [dataset, pipes, subUrl, token, upstreams]);

  useEffect(() => {
    setSourcePropsLoading(true);

    getPipeEntityTypes(sourceId, subUrl, token).then((entityTypes) => {
      if (entityTypes.length > 0) {
        setSourceProps(entityTypes.sort());
        setSourcePropsLoading(false);
      } else {
        const sourceDatasetIds = getSourceDatasetIds(pipe);
        const sourceDatasetId = sourceDatasetIds[0];
        getPropsFromDataset(sourceDatasetId, subUrl, token).then((props) => {
          setSourceProps(props);
          setSourcePropsLoading(false);
        });
      }
    });
  }, [pipe, sourceId, subUrl, token]);

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <p>Now we need to know what data to fetch, and where to put it.</p>
        <p>
          Select a property from <span className={classes.blueText}>{sourceId}</span>, and a
          property from <span className={classes.blueText}>{dataset}</span>
        </p>
        <p>
          Where these properties match, we will add data from{' '}
          <span className={classes.blueText}>{dataset}</span> to{' '}
          <span className={classes.blueText}>{sourceId}</span>
        </p>
      </div>
      <div className={classes.action}>
        <div className={classes.sourceProp}>
          <HopsWizardFilteredList
            editable
            placeholderEmptySearch="No properties found"
            placeholderEmptyValue={`Pick a property from "${sourceId}"`}
            placeholderSearch={`Search "${sourceId}"`}
            filterInputProps={{
              tabIndex: 1,
              autoFocus: true,
            }}
            items={sourceProps}
            isLoading={sourcePropsLoading}
            value={sourceProp}
            onSelectItem={(val) => {
              const value = val ? `_S.${val}` : val;
              setValue('sourceProp', value);
            }}
            onChange={(val) => setValue('sourceProp', val)}
          />
        </div>
        <div className={classes.targetProp}>
          <HopsWizardFilteredList
            editable
            placeholderEmptySearch="No properties found"
            placeholderEmptyValue={`Pick a property from "${dataset}"`}
            placeholderSearch={`Search "${dataset}"`}
            items={targetProps}
            isLoading={targetPropsLoading}
            filterInputProps={{
              tabIndex: 2,
              autoFocus: false,
            }}
            value={targetProp}
            onSelectItem={(val) => {
              const value = val ? `t.${val}` : val;
              setValue('targetProp', value);
            }}
            onChange={(val) => setValue('targetProp', val)}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: Step2Props) {
  return {
    pipe: state.pipes[ownProps.bag.sourceId],
    pipes: state.pipes,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    upstreams: state.upstreams,
  };
}

export default connect(mapStateToProps)(HopsWizardStep2);
