import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import { withTheme } from '@material-ui/core';

import { ensureArray } from 'Internals/utils';
import './style.css';
import { TestID } from '../../testID';

function KeyValue({ list, theme }) {
  function printDD(dd) {
    const ddArray = ensureArray(dd);
    return ddArray.map((item, i) => (
      <dd
        className="key-value__val"
        key={isObject(item) ? i : item}
        data-testid={TestID.KeyValuePairValue}
      >
        {item}
      </dd>
    ));
  }

  if (list.length === 0) {
    return null;
  }

  return (
    <dl className="key-value">
      {Object.keys(list).map((key) => [
        <dt
          className="key-value__key"
          key={key}
          style={{ color: theme.palette.text.primary }}
          data-testid={TestID.KeyValuePairKey}
        >
          {key}
        </dt>,
        list[key] && printDD(list[key]),
      ])}
    </dl>
  );
}

KeyValue.propTypes = {
  list: PropTypes.object.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      text: PropTypes.shape({
        primary: PropTypes.string,
      }),
    }),
  }).isRequired,
};
export default withTheme(KeyValue);
