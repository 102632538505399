import clsx from 'clsx';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MUIFormControl } from '@material-ui/core/FormControl';
import { FormControlProps as MUIFormControlProps } from '@material-ui/core/FormControl';
import { FormContext } from './Form';
import { SomeObject } from 'Types/common.types';

export interface FormControlProps extends MUIFormControlProps {}

const useStyle = makeStyles({
  formControlHorizontal: {
    paddingRight: '10px',
  },
});

const FormControl: React.FC<FormControlProps> = (props) => {
  const { className, ...restProps } = props;
  const classes: SomeObject = useStyle();
  const formContext: SomeObject = useContext(FormContext);

  return (
    <MUIFormControl
      className={clsx([className, formContext.horizontal && classes.formControlHorizontal])}
      {...restProps}
    />
  );
};

export default FormControl;
