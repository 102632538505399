import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Info from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { TestID } from 'src/testID';

const getPlaceholder = (searchType) => {
  switch (searchType) {
    case 'id':
      return `Returns exact match on ID`;
    case 'freetext':
      return `Search for text inside an entity`;
  }
};

export const DatasetInspectorFilterSearch = (props) => {
  const { datasetIndexes, searchValue, searchType } = props;

  const presets = ['"_deleted":true', '"_filtered":true'];

  const value = isUndefined(searchValue) ? '' : searchValue;

  const idsAreIndexed = useMemo(() => {
    for (const index of datasetIndexes) {
      if (index.expression === '"_S.$ids"') {
        return true;
      }
    }
  }, [datasetIndexes]);

  return (
    <div className="dataset-inspector-filter__search-field">
      {searchType === 'freetext' && (
        <SesamAutocomplete
          disableClearable
          freeSolo
          renderInput={(params) => (
            <SesamTextField
              {...params}
              margin="none"
              placeholder={getPlaceholder(searchType)}
              fullWidth={true}
              InputProps={{
                endAdornment:
                  value.length > 0 ? (
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small" onClick={props.onSearchReset}>
                        <Clear fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
              inputTestid={TestID.SearchEntitiesTextField}
            />
          )}
          inputValue={value}
          options={presets}
          getOptionLabel={(o) => o}
          onChange={(ev, val) => {
            if (val) {
              props.onSearchValueChanged({
                target: { value: val },
              });
            }
          }}
          onInputChange={(ev, val) => {
            if (ev) {
              props.onSearchValueChanged({ target: { value: val } });
            }
          }}
        />
      )}
      {searchType !== 'freetext' && (
        <SesamTextField
          value={value}
          placeholder={getPlaceholder(searchType)}
          onChange={props.onSearchValueChanged}
          margin="none"
          InputProps={{
            endAdornment:
              searchValue.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={props.onSearchReset}>
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            startAdornment: !idsAreIndexed ? (
              <InputAdornment position="start">
                <Tooltip title="Only the _id property will be searched since this dataset does not index the $ids property.">
                  <Info className="dataset-inspector-filter__search-field-info" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
        />
      )}
    </div>
  );
};

DatasetInspectorFilterSearch.propTypes = {
  datasetIndexes: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchReset: PropTypes.func.isRequired,
  onSearchValueChanged: PropTypes.func.isRequired,
  searchType: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
};
