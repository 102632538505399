import { Model } from 'Types/common.types';
import { createReducer } from '@reduxjs/toolkit';
import { subSwitched, disconnected } from '../thunks/subscriptions';
import { modelsLoaded } from '../thunks/models';

type ModelsState = Model[];

const initialState: ModelsState = [];

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(subSwitched, () => {
      return initialState;
    })
    .addCase(disconnected, () => initialState)
    .addCase(modelsLoaded, (state, action) => {
      return action.payload;
    })
);
