// @ts-ignore no types for this one
import debounceAction from 'debounce-action';

import ModelsAPI from '../../api/models';
import { Model } from 'Types/common.types';
import { getApiConf } from './subscriptions';
import type { AppThunk } from 'Types/state.types';
import { createAction } from '@reduxjs/toolkit';

export const modelsLoaded = createAction<Model[]>('models/loadAll');

const loadAll = debounceAction(
  (): AppThunk => (dispatch, getState) => {
    return ModelsAPI.getAll(getApiConf(getState), true, false)
      .then((models) => dispatch(modelsLoaded(models)))
      .catch(() => dispatch(modelsLoaded([])));
  },
  1000,
  { leading: true, trailing: false }
);

export default {
  loadAll,
};
