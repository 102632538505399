import { ApiConf } from 'Types/common.types';
import { sfetchJson } from 'Internals/sfetch';
import { Json } from '../types/json';

export type EnvVarsDict = {
  [key: string]: Json;
};

async function getAll(apiConf: ApiConf): Promise<EnvVarsDict> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(subUrl + '/env', options);
}
async function post(apiConf: ApiConf, name: string, value: Json): Promise<Json> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({ [name]: value }),
  };
  return await sfetchJson(`${subUrl}/env`, options);
}

async function put(apiConf: ApiConf, env: Json): Promise<Json> {
  const { subUrl, token } = apiConf;

  const options: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(env),
  };
  return await sfetchJson(`${subUrl}/env`, options);
}

async function remove(apiConf: ApiConf, name: string): Promise<Json> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  };
  return await sfetchJson(`${subUrl}/env/${name}`, options);
}

export default {
  getAll,
  put,
  post,
  remove,
};
