import PropTypes from 'prop-types';
import transform from 'lodash/transform';
import { SomeObject } from 'Types/common.types';

const dataAttrRegExp = /^data-/;

export function dataAttrsFromProps(props: SomeObject) {
  return transform(props, (result: SomeObject, val, key) => {
    if (key.match(dataAttrRegExp)) {
      result[key] = val;
    }
    return result;
  });
}

type ClassNames = (string | ClassNames)[];

export const ClassNamePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

export function buildClassName(...classNames: ClassNames) {
  const allClasses: string = classNames
    .reduce((className: string, name: string | ClassNames): string => {
      if (Array.isArray(name)) {
        const names = buildClassName(...name);
        if (names) return `${className} ${names}`;
        else return className;
      }
      if (typeof name === 'string') {
        return `${className} ${name}`;
      }
      return className;
    }, '')
    .trim();

  return allClasses === '' ? undefined : allClasses;
}

export function scrollToEl(el: HTMLElement) {
  if (el) {
    try {
      el.scrollIntoView({
        block: 'nearest',
        inline: 'end',
      });
    } catch (e1) {
      // FF bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1389274
      // @brano: this has been fixed a year ago so we will probably be able to remove it
      try {
        el.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } catch (e2) {
        // Just scroll any way possible
        el.scrollIntoView();
      }
    }
  }
}
