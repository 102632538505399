import { Theme as MUITheme } from '@material-ui/core';
import { ChangeLogLevel, ChangeLogTags } from './enums';

export type _ID = string;

export type NodeComponent = {
  _id: _ID;
};

export type ApiConf = {
  subUrl: string;
  token: string;
};

/**
 * Represents a ui-operations that the user can potentially perform. If the user is allowed to perform the operations, the 'enabled'-property will be true. If the user is not allowed to do the operation, the 'enabled'-property will be false, and the 'reason-code' property will explain why the operation is not allowed.
 */
export type SupportedOperation = {
  enabled: boolean;
  id: string;
  name: string;
  'reason-code'?: string;
  'reason-msg'?: string;
};

export type Theme = {
  palette: {
    primary: {
      main: '';
    };
    error: {
      main: '';
      dark: '';
    };
  };
};

export type CustomTheme = MUITheme & {
  palette: {
    background: {
      light: string;
    };
  };
};

export type SomeObject = {
  [key: string]: any;
};

export type DownstreamMap = {
  [datasetId: string]: {
    [pipeId: string]: true;
  };
};

export type LookupMap = {
  [datasetId: string]: {
    [pipeId: string]: true;
  };
};

export type InboundMap = {
  [sourceSystemId: string]: {
    [pipeId: string]: true;
  };
};

export type OutboundMap = {
  [sinkSystemId: string]: {
    [pipeId: string]: true;
  };
};

export type TransformMap = {
  [tranformSystemId: string]: {
    [pipeId: string]: true;
  };
};

export type ConfigError = {
  elements: string;
  msg: string;
  level: string;
};

export type ValueOf<T> = T[keyof T];

export type Optional<T> = T | null | undefined;

export type CachedResponseInfo = {
  /**
   * The date and time the information was cached.
   */
  timestamp: string;
  /**
   * The age (in seconds) of the cached information. This information can be derived from the "cache_time" property, but is included as a debug-aid.
   */
  age: number;
};

export type Model = SomeObject;

/* Auditing information about an entity. */
export type AuditInfo = {
  created_ts?: string;
  created_by?: {
    email?: string;
    user_id?: string;
    picture?: string;
    name?: string;
  };
  last_modified_ts?: string;
  last_modified_by?: {
    email?: string;
    user_id?: string;
    picture?: string;
    name?: string;
  };
};

export type RoleInfo = {
  name?: string;
  description?: string;
  id?: string;
  ['child-roles']?: string[];
};

export type SearchFacet = {
  name: string;
  count?: number;
};

export type SearchResult = {
  id: string;
  dataset: string;
  refs?: string[];
  ids?: string[];
};

export type SearchAggregationResults = {
  results: SearchFacet[];
};

export type SearchResults = {
  total_hits: number | null;
  results: SearchResult[];
};

export enum CustomURLConnectionStatus {
  NotConnected = 'not-connected',
  Connected = 'connected',
  Failed = 'failed',
}

export type Member = {
  _updated: number[];
  roles: string[];
  user: {
    email: string;
    name: string;
    picture: string;
    user_id: string;
  };
};

export type Members = Member[];

export type LogChange = {
  id: string;
  timestamp: number;
  title: string;
  lvl: ChangeLogLevel;
  tags: ChangeLogTags[];
  content: string;
};

export type ImportMap = {
  [key: string]: () => Promise<any>;
};

export type WithDynamicImportsProps = {
  [key: string]: any;
};

export type ZxcvbnFn = (password: string, user_inputs?: []) => { score: number };
